import { FormattedMessage } from "react-intl";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { useEffect, useMemo, useState } from "react";
import { VehicleEntry } from "../VehiclesTable";
import VehicleIcon from "../../../icons/VehicleIcon";
import VehiclePhotoPlaceholder from "../../../assets/VehiclePhotoPlaceholder.svg";
import { Color } from "../../../constants";
import useDriver from "../../../hooks/useDriver";
import ModalSecondaryButton from "../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import FormModal from "../../form/FormModal/FormModal";
import { EMPTY_ARR } from "../../../util/shared.util";
import StatusIcon from "../../../icons/StatusIcon";
import styles from "./VehicleCardModal.module.scss";
import DriverVehicleTable from "./DriverVehicleTable";
import { ReactComponent as AddIcon } from "../../../assets/icons/Add.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/TrashBig.svg";
import { useGetDevice } from "../../../hooks/useQueryDevices";
import { useQueryAvailableDeviceDrivers } from "../../../hooks/useQueryDrivers";
import { Driver } from "../../../api/data-contracts";
import { useDriversQueryParamsContext } from "../../../context/QueryParamsContext/DriversQueryParamsContextProvider";
import Pagination from "../../Table/Pagination";
import { SearchBox } from "../../form/SearchBox/SearchBox";

const formModalClasses = {
  header: "modal-form-header",
  footer: "modal-form-footer",
  dialog: "big-modal-form-dialog",
  body: "big-modal-form-body",
};

type VehicleCardModalProps = {
  show: boolean;
  onClickClose: () => void;
  vehicleInitialData?: VehicleEntry;
};

function VheicleCardModal({ show, onClickClose, vehicleInitialData }: VehicleCardModalProps) {
  const [driverFilter, setDriverFilter] = useState<string>();

  const {
    data: deviceData,
    isError: isErrorDrivers,
    isLoading: isLoadingDrivers,
    isFetching: isFetchingDrivers,
  } = useGetDevice({ deviceId: vehicleInitialData?.deviceId!, includeDrivers: true }, !!vehicleInitialData?.deviceId);

  const {
    data: drivers,
    isError,
    isLoading,
    isFetching,
  } = useQueryAvailableDeviceDrivers(
    deviceData?.drivers!,
    deviceData?.tspId,
    deviceData?.fleetId,
    !!deviceData?.drivers
  );

  const { addDriverToDevice, removeDriverFromDevice } = useDriver();
  const hasDrivers = useMemo(() => !!deviceData?.drivers?.length, [deviceData]);

  const { queryParams, setPageIndex, upsertFilter, removeFilter } = useDriversQueryParamsContext();

  const onClickAddDriver = async (driver: Driver) => {
    if (driver && deviceData?.id) {
      await addDriverToDevice({
        deviceId: deviceData.id,
        driverId: driver.driverId!,
        fleetId: deviceData.fleetId!,
        tspId: deviceData.tspId!,
      });
    }
  };
  const onClickRemoveDriver = async (driver: Driver) => {
    if (driver && deviceData?.id) {
      await removeDriverFromDevice({
        deviceId: deviceData.id,
        driverId: driver.driverId!,
        fleetId: deviceData.fleetId!,
        tspId: deviceData.tspId!,
      });
    }
  };
  useEffect(() => {
    if (driverFilter) {
      upsertFilter("(name|driverId)", "@=", [driverFilter]);
    } else {
      removeFilter("(name|driverId)", "@=");
    }
  }, [driverFilter, removeFilter, upsertFilter]);
  return (
    <FormModal
      show={show}
      id="vehicleCardModal"
      handleClose={onClickClose}
      classes={formModalClasses}
      header={
        <>
          <>
            <EditIcon fill="black" />
            <div className="p-2">
              <FormattedMessage id="VEHICLE_CARD" defaultMessage="Vhicle card" />
            </div>
          </>
        </>
      }
      footer={
        <div className="d-flex align-items-right gap-2">
          <ModalSecondaryButton type="button" onClick={onClickClose}>
            <FormattedMessage id="CLOSE" defaultMessage="Close" />
          </ModalSecondaryButton>
        </div>
      }
    >
      <div className="d-flex justify-content-between align-items-stretch gap-4 pb-4">
        <div className="d-flex flex-column">
          <div className="p-2" style={{ height: "12rem", width: "14rem" }}>
            <img
              alt="driverPhoto"
              style={{ width: "100%", maxWidth: "100%", maxHeight: "100%" }}
              src={vehicleInitialData?.photo ?? VehiclePhotoPlaceholder}
            />
          </div>

          <div className="d-flex p-2" style={{ borderBottom: `1px solid ${Color.GRAY_5}` }}>
            <div className={styles.text}>
              <FormattedMessage id="VEHICLE_TYPE" defaultMessage={"Vehicle type"} />
            </div>
            <div>
              <VehicleIcon name={vehicleInitialData?.vehicleType} size="standard" />
            </div>
            <div>{vehicleInitialData?.vehicleType}</div>
          </div>
          <div className="d-flex p-2" style={{ borderBottom: `1px solid ${Color.GRAY_5}` }}>
            <div className={styles.text}>
              <FormattedMessage id="MODEL" defaultMessage={"Model"} />
            </div>
            <div>{vehicleInitialData?.modelName}</div>
          </div>
          <div className="d-flex p-2" style={{ borderBottom: `1px solid ${Color.GRAY_5}` }}>
            <div className={styles.text}>
              <FormattedMessage id="MANUFACTURE_YEAR" defaultMessage={"Manufacture year"} />
            </div>
            <div>{vehicleInitialData?.manufYear}</div>
          </div>
          <div className="d-flex p-2" style={{ borderBottom: `1px solid ${Color.GRAY_5}` }}>
            <div className={styles.text}>
              <FormattedMessage id="INSTALLED_DEVICE_ID" defaultMessage={"Installed device ID"} />
            </div>
            <div>{vehicleInitialData?.deviceId}</div>
          </div>
          <div className="d-flex p-2" style={{ borderBottom: `1px solid ${Color.GRAY_5}` }}>
            <div className={styles.text}>
              <FormattedMessage id="FLEET" defaultMessage={"Fleet"} />
            </div>
            <div>{vehicleInitialData?.fleetName}</div>
          </div>
          <div className="d-flex p-2" style={{ borderBottom: `1px solid ${Color.GRAY_5}` }}>
            <div className={styles.text}>
              <FormattedMessage id="STATUS" defaultMessage={"Status"} />
            </div>
            {vehicleInitialData?.status && (
              <>
                <div>
                  <StatusIcon type={vehicleInitialData?.status} />
                  <FormattedMessage id={vehicleInitialData?.status.toUpperCase()} defaultMessage="Active" />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="d-flex flex-fill">
          {
            <>
              <div className="w-50 p-2">
                <div className="p-2 d-flex gap-2" style={{ borderBottom: `2px solid ${Color.GRAY_5}` }}>
                  <FormattedMessage id="ASSIGNED_TO_DRIVERS" defaultMessage="Assigned driver" />
                  <div>({deviceData?.drivers?.length})</div>
                </div>
                <div className="w-100">
                  {hasDrivers && (
                    <DriverVehicleTable
                      buttonIcon={<RemoveIcon style={{ maxWidth: "24px", maxHeight: "24px" }} />}
                      buttonLabel={<FormattedMessage id="REMOVE" defaultMessage="Remove" />}
                      onClick={onClickRemoveDriver}
                      data={deviceData?.drivers ?? (EMPTY_ARR as [])}
                      isError={isErrorDrivers}
                      isLoading={isLoadingDrivers}
                      isFetching={isFetchingDrivers}
                    />
                  )}
                </div>
                {!hasDrivers && (
                  <div className="p-2" style={{ color: Color.GRAY_5 }}>
                    <FormattedMessage
                      id="LIST_OF_DRIVER"
                      defaultMessage="A list of drivers that this vehicle is assigned to"
                    />
                  </div>
                )}
              </div>
              <div className="w-50 p-2" style={{ borderLeft: `2px solid ${Color.GRAY_5}` }}>
                <FormattedMessage
                  id="TO_ASSIGN_THE_DRIVER"
                  defaultMessage="To assign the driver to more vehicles, add drivers' profile to a vehicle"
                />
                <div>
                  <SearchBox
                    placeHolderId="DRIVER_NAME_OR_ID_SEARCH"
                    defaultPlaceHolderMessage="Type driver name or ID"
                    onChange={(e) => {
                      setDriverFilter(e.target.value);
                    }}
                  />
                </div>
                {
                  <>
                    <DriverVehicleTable
                      buttonIcon={<AddIcon />}
                      buttonLabel={<FormattedMessage id="ADD" defaultMessage="Add" />}
                      onClick={onClickAddDriver}
                      data={drivers?.data ?? (EMPTY_ARR as [])}
                      isError={isError}
                      isLoading={isLoading}
                      isFetching={isFetching}
                    />
                    <Pagination
                      className="mt-auto"
                      debouncedPageIndex={queryParams.paging.pageIndex}
                      onChangePageIndex={setPageIndex}
                      pageCount={drivers?.pageCount}
                      allDisabled={isFetching}
                      totalEntries={drivers?.totalCount}
                    />
                  </>
                }
              </div>
            </>
          }
        </div>
      </div>
    </FormModal>
  );
}

export default VheicleCardModal;
