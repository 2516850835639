import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { DeleteTspParams, PutProfileParams, UpdateTspParams, tspService } from "../services/tsp.service";
import { AddSP, SP, UserProfile } from "../api/data-contracts";

type AddTspMutationOptions = UseMutationOptions<AxiosResponse<SP>, AxiosError, AddSP>;
type DeleteTspMutationOptions = UseMutationOptions<unknown, AxiosError, DeleteTspParams>;
type EditTspMutationOptions = UseMutationOptions<AxiosResponse<SP>, AxiosError, UpdateTspParams>;
type EditTspProfileMutationOptions = UseMutationOptions<AxiosResponse<UserProfile>, AxiosError, PutProfileParams>;

export const addTspMutation = (useMutationProps: Omit<AddTspMutationOptions, "mutationFn">): AddTspMutationOptions => ({
  mutationKey: ["tsp"],
  mutationFn: async (addTspData: AddSP) => await tspService.addTsp(addTspData),
  ...useMutationProps,
});

export const deleteTspMutation = (
  useMutationProps: Omit<DeleteTspMutationOptions, "mutationFn">
): DeleteTspMutationOptions => ({
  mutationKey: ["tsp"],
  mutationFn: async (params: DeleteTspParams) => await tspService.deleteTsp(params),
  ...useMutationProps,
});

export const updateTspMutation = (
  useMutationProps: Omit<EditTspMutationOptions, "mutationFn">
): EditTspMutationOptions => ({
  mutationKey: ["tsp"],
  mutationFn: async (params: UpdateTspParams) => await tspService.updateTsp(params),
  ...useMutationProps,
});

export const updateTspProfileMutation = (
  useMutationProps: Omit<EditTspProfileMutationOptions, "mutationFn">
): EditTspProfileMutationOptions => ({
  mutationKey: ["tsp"],
  mutationFn: async (params: PutProfileParams) => await tspService.updateTspProfile(params),
  ...useMutationProps,
});
