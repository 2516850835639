interface PassOptions {
  length?: number;
  numbers?: boolean;
  symbols?: boolean | string;
  exclude?: string;
  uppercase?: boolean;
  lowercase?: boolean;
  excludeSimilarCharacters?: boolean;
  strict?: boolean;
}

const lowercase = "abcdefghijklmnopqrstuvwxyz",
  uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  numbers = "0123456789",
  symbols = "\\!@#$%^&*()+_-=}{[]|:;\"/?.><,`~'",
  similarCharacters = /[ilLI|`oO0]/g,
  strictRules = [
    { name: "lowercase", rule: /[a-z]/ },
    { name: "uppercase", rule: /[A-Z]/ },
    { name: "numbers", rule: /[0-9]/ },
    { name: "symbols", rule: /[\\!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~']/ },
  ];

//max is exclusive, max <= 128
function randomNumber(max: number) {
  let randVal;
  let randArr;
  do {
    randArr = crypto.getRandomValues(new Int8Array(1));
    randVal = Math.abs(randArr[0]);
  } while (randVal >= max);

  return randVal;
}

const generateLocal = (options: PassOptions, pool: string): string => {
  let password = "";
  const optionsLength = options.length,
    poolLength = pool.length;

  for (let i = 0; i < (optionsLength as number); i++) {
    password += pool[randomNumber(poolLength)];
  }

  if (options.strict) {
    // Iterate over each rule, checking to see if the password works.
    const fitsRules = strictRules.every((rule) => {
      // If the option is not checked, ignore it.
      if (
        options[
          (rule.name as "lowercase") || "uppercase" || "numbers" || "symbols"
        ] === false
      )
        return true;

      // Treat symbol differently if explicit string is provided
      if (rule.name === "symbols" && typeof options[rule.name] === "string") {
        // Create a regular expression from the provided symbols
        const re = new RegExp(`[${options[rule.name]}]`);
        return re.test(password);
      }

      // Run the regex on the password and return whether
      // or not it matches.
      return rule.rule.test(password);
    });

    // If it doesn't fit the rules, generate a new one (recursion).
    if (!fitsRules) return generateLocal(options, pool);
  }

  return password;
};

// Generate a random password.
const generate = (options: PassOptions): string => {
  // Set defaults.
  options = options || {};
  if (!Object.prototype.hasOwnProperty.call(options, "length"))
    options.length = 10;
  if (!Object.prototype.hasOwnProperty.call(options, "numbers"))
    options.numbers = false;
  if (!Object.prototype.hasOwnProperty.call(options, "symbols"))
    options.symbols = false;
  if (!Object.prototype.hasOwnProperty.call(options, "exclude"))
    options.exclude = "";
  if (!Object.prototype.hasOwnProperty.call(options, "uppercase"))
    options.uppercase = true;
  if (!Object.prototype.hasOwnProperty.call(options, "lowercase"))
    options.lowercase = true;
  if (
    !Object.prototype.hasOwnProperty.call(options, "excludeSimilarCharacters")
  )
    options.excludeSimilarCharacters = false;
  if (!Object.prototype.hasOwnProperty.call(options, "strict"))
    options.strict = false;

  if (options.strict) {
    const minStrictLength =
      1 +
      (options.numbers ? 1 : 0) +
      (options.symbols ? 1 : 0) +
      (options.uppercase ? 1 : 0);
    if (minStrictLength > (options.length as number)) {
      throw new TypeError("Length must correlate with strict guidelines");
    }
  }

  // Generate character pool
  let pool = "";

  // lowercase
  if (options.lowercase) {
    pool += lowercase;
  }

  // uppercase
  if (options.uppercase) {
    pool += uppercase;
  }
  // numbers
  if (options.numbers) {
    pool += numbers;
  }
  // symbols
  if (options.symbols) {
    if (typeof options.symbols === "string") {
      pool += options.symbols;
    } else {
      pool += symbols;
    }
  }

  // Throw error if pool is empty.
  if (!pool) {
    throw new TypeError("At least one rule for pools must be true");
  }

  // similar characters
  if (options.excludeSimilarCharacters) {
    pool = pool.replace(similarCharacters, "");
  }

  // excludes characters from the pool
  let i = (options.exclude as string).length;
  while (i--) {
    pool = pool.replace((options.exclude as string)[i], "");
  }

  return generateLocal(options, pool);
};

export function generatePassword() {
  return generate({
    length: 10,
    numbers: true,
    symbols: true,
    exclude: "<>",
    strict: true,
  });
}

export function isPasswordValid(password: string, popupOnFail:boolean=false) {
  const fitsRules = strictRules.every((rule) => {
    return rule.rule.test(password);
  });
  if (!fitsRules && popupOnFail)
  {
    alert(`Password does not match policy:
    • At least 8 characters - the more characters, the better.
    • A mixture of both uppercase and lowercase letter.
    • A mixture of letters and numbers.
    • Inclusion of at least one special character, e.g., ! @ # ? ].
    • do not use < or > in your password`);
  }
  return fitsRules;
}
