import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import useAuthentication from "../../hooks/useAuthentication";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";
import { Color } from "../../constants";
import ModalSecondaryButton from "../Buttons/ModalSecondaryButton/ModalSeconaryButton";
//import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
//import { useState } from "react";

type LoginFormInputs = { username: string; password: string };

const ErrorMsg = ({
  visible,
  messageId,
  defaultMessage,
}: {
  visible: boolean;
  messageId: string;
  defaultMessage: string;
}) => (
  <div style={{ color: visible ? Color.RED : "transparent", position: "relative", top: "1rem" }}>
    <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
  </div>
);

function LoginForm() {
  const { control, handleSubmit } = useForm<LoginFormInputs>();

  //const [showPrivacyWarning, setShowPrivacyWarning] = useState(false);

  const { loading, login, badCredentials, loggedUser } = useAuthentication();

  const privacyRequired = Boolean(loggedUser);

  const { formatMessage } = useIntl();

  const onSubmit = async ({ username, password }: LoginFormInputs) => {
    if (loading) return;
    await login(username, password);
  };

  return (
    <div className="d-flex flex-column" style={{ padding: "3rem 5rem" }}>
      <div style={{ color: Color.DARK_GRAY_1, fontSize: "1.875rem", fontWeight: 500 }}>
        <FormattedMessage id="LOGIN" defaultMessage="Login" />
      </div>
      <ErrorMsg
        visible={badCredentials}
        messageId="LOGIN.BAD_CRED_MSG"
        defaultMessage="Please Contact your administrator"
      />
      {/* <ErrorMsg
        visible={showPrivacyWarning}
        messageId="LOGIN.ACCEPT_PRIVACY_POLICY_MSG"
        defaultMessage="Accept the Privacy Policy to continue.​"
      /> */}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <FormGroup
            input={
              <FormInput
                type="text"
                name="username"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  disabled: loading || privacyRequired,
                  inputLabel: formatMessage({ id: "LOGIN.USERNAME", defaultMessage: "User Name" }),
                }}
              />
            }
          />
          <FormGroup
            input={
              <FormInput
                type="text"
                name="password"
                control={control}
                rules={{ required: true }}
                input={{
                  type: "password",
                  disabled: loading || privacyRequired,
                  inputLabel: formatMessage({ id: "LOGIN.PASSWORD", defaultMessage: "Password" }),
                }}
              />
            }
          />
        </div>

        <div className="d-flex align-items-center">
          {/* <PrivacyPolicy
            required={privacyRequired}
            setShowPrivacyWarning={setShowPrivacyWarning}
            showPrivacyWarning={showPrivacyWarning}
          /> */}
          <ModalSecondaryButton
            type="submit"
            style={{ marginLeft: "auto", paddingLeft: "3rem", paddingRight: "3rem" }}
            disabled={loading || privacyRequired}
          >
            <FormattedMessage id={"GO"} defaultMessage="Go" />
          </ModalSecondaryButton>
        </div>
      </Form>
    </div>
  );
}

export default LoginForm;
