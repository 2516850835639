import { FormattedMessage } from "react-intl";
import { ReactComponent as TrashBigIcon } from "../../../assets/icons/TrashBig.svg";

import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { UserTableEntry } from "../UsersTable";
import useUser from "../../../hooks/useUser";

type DeleteModalProps = {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  usersData: UserTableEntry[];
};

 

function DeleteUserModal({ show, onClickNo, onClickYes, usersData }: DeleteModalProps) {
  const { deleteUsers } = useUser();
  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={async () => {
        const emails = usersData!.map((userData) => userData.email!);
        await deleteUsers(emails);
        onClickYes();
      }}
      onHide={onClickNo}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <TrashBigIcon />
          <FormattedMessage id="User.DELETE_QUESTION" defaultMessage="Are you sure you want to delete this user?" />
        </div>
      }
    />
  );
}

export default DeleteUserModal;
