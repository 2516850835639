import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Upload } from "../../../assets/icons/Upload.svg";
import { ReactComponent as VIcon } from "../../../assets/icons/VIcon.svg";
import { AddDeviceConfig, Configurations } from "../../../api/data-contracts";
import FormModal from "../../form/FormModal/FormModal";
import FormInput from "../../form/FormInput/FormInput";
import { FormGroup } from "../../form/FormGroup/FormGroup";
import ModalSecondaryButton from "../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import { useTspFleetContext } from "../../../context/TspFleetContext/TspFleetContext";
import useConfiguration from "../../../hooks/useConfiguration";
import { useRef } from "react";


const formModalClasses = {
  header: "modal-form-header",
  footer: "modal-form-footer",
  dialog: "modal-form-dialog",
  body: "modal-form-body",
};

export interface UploadDeviceConfugInputs {
  name: string;
  description?: string;
  data: Configurations
  version: string,
  tspId: number
}

interface Props {
  show: boolean;
  handleClose: () => void;
  isLoading?: boolean;
}

function UploadConfigModal({ show, handleClose }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  var { activeTsp } = useTspFleetContext();
  const { control, handleSubmit, formState, setValue } = useForm<UploadDeviceConfugInputs>();
  var { addDeviceConfig } = useConfiguration({ onSuccessAddConfig: handleClose, onSuccessDeleteConfig: handleClose })

  const onSubmit = async ({ name, data, description }: AddDeviceConfig) => {
    await addDeviceConfig({ name: name, tspId: activeTsp!.id!, data: data, description: description, enabled: true })
  }
  const handleFileClick = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        const fileNameWithoutExtension = fileObj.name.replace(/\.[^/.]+$/, '');
        setValue("name", fileNameWithoutExtension);
        setValue("data", JSON.parse(e.target!.result!.toString()));
      }
    };
  };

  const onBrowseClick = () => {
    inputRef?.current?.click();
  }
  const renderBody = () => (
    <>
      <div className="d-flex align-items-end gap-2">
        <div className="flex-grow-1">
          <FormGroup

            label={<FormattedMessage id="CONFIGURATION" defaultMessage="Configuration" />}
            input={
              <FormInput
                type="text"
                name="name"
                control={control}
                rules={{ required: true }}
                input={{ disabled: true }}

              />
            }
          />
        </div>
        <div >

          <ModalSecondaryButton type="button" onClick={onBrowseClick} style={{ height: "2rem" }}>
            <FormattedMessage id="BROWSE" defaultMessage="BROWSE" />
          </ModalSecondaryButton>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            accept="application/json"
            onChange={e => handleFileClick(e)}
          />
        </div>
      </div>
      <FormGroup
        label={<FormattedMessage id="CONFIG_DESCRIPTION" defaultMessage="Description" />}
        input={
          <FormInput
            type="text"
            input={{
              type: "textarea",
              textareaRows: 6,
            }}
            name="description"
            control={control}
          />
        }
      />
    </>
  );



  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      id="upload-modal"
      classes={formModalClasses}
      header={
        <>
          <div className="d-flex flex-row gap-2 align-items-center">
            <Upload />
            <FormattedMessage id="UPLOAD_DEVICE_CONFIG" defaultMessage="Upload configuration" />
          </div>
        </>
      }
      show={show}
      handleClose={() => handleClose()}
      footer={
        <div className="d-flex align-items-center gap-2">
          <ModalSecondaryButton type="button" onClick={handleClose}>
            <FormattedMessage id="CLOSE" defaultMessage="Close" />
          </ModalSecondaryButton>
          <ModalMainButton
            type="submit"
            disabled={!formState.isValid}
          >
            <>
              <VIcon />
              <FormattedMessage id="UPLOAD" defaultMessage="Upload" />
            </>
          </ModalMainButton>
        </div>
      }
    >
      {renderBody()}
    </FormModal>
  );
}

export default UploadConfigModal;
