import { Trips as TripsApi } from "../api/Trips";
import { Trip } from "../api/data-contracts";

import { FilterParam, ServiceGetResult } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

export type TripsSortFilterParamName = "tripId" | "vehicleId" | "drivers" | "driverId" ;

const tripsApi = new TripsApi();
addAuthHandling(tripsApi);

type PagingParams = { pageIndex: number; pageSize: number };
export type TripsSortParam = {
  param: TripsSortFilterParamName;
  descending?: boolean;
};

export type GetTripsParams = {
  paging: PagingParams;
  sorts: TripsSortParam[];
  filters: FilterParam<TripsSortFilterParamName>[];
};

export type GetTripsResult = ServiceGetResult<Trip[]>;

export const tripsService = {
  getTrips: async ({ paging, sorts, filters }: GetTripsParams): Promise<GetTripsResult> => {
    const result = await tripsApi.getTrips({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
       filters: filters.length ? filterParamsToString(filters) : undefined,
       page: paging.pageIndex,
       pageSize: paging.pageSize,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }
    return {
      pageCount,
      data: result.data.data,
      totalCount: header?.totalCount,
    };
  },
};
