export const uniqueId = (function (): (prefix: string) => string {
  let num = 0;
  return function (prefix: string = ""): string {
    num += 1;
    return prefix + num;
  };
})();

export function meterSecToKmHour(speed: number) {
  return (speed * 60 * 60) / 1000;
}

export function meterSecToMph(speed: number) {
  return (speed * 60 * 60) / 1609.344;
}

export function identity<T>(x: T): T {
  return x;
}

export const EMPTY_ARR: readonly [] = [];

export const toBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const stripBase64Prefix = (base64: string) => base64.split(",")[1];
export const addBase64Prefix = (base64: string) => `data:image/png;base64,${base64}`;

export function downloadJsonFile(json: object, fileName: string) {
  downloadFile(JSON.stringify(json),fileName,"application/json" );
}
export function downloadCsvFile(data: string, fileName: string) {
  downloadFile(data,fileName,"text/csv" );
}

export function downloadFile(data: string, fileName: string,type:string) {
  const file = new Blob([data], { type: type });
  const href = URL.createObjectURL(file);

  const a = document.createElement("a");
  a.download = fileName;
  a.href = href;
  a.click();

  URL.revokeObjectURL(href);
}

export function jsonToCsv<T extends {} >(data:T[]) {
  var json = data;
  
  var csv = json.map(function (item) {
    return Object.keys(item)
      .map(function (fieldName) {
        return item[fieldName as keyof T];
      })
      .join(",");
  });

  return csv.join("\r\n");
}
