import Bus from "../assets/vehicles/Bus.svg";
import Excavator from "../assets/vehicles/Excavator.svg";
import Forklift from "../assets/vehicles/Forklift.svg";
import LCV from "../assets/vehicles/LCV.svg";
import MCV from "../assets/vehicles/MCV.svg";
import Pickup from "../assets/vehicles/Pickup.svg";
import Private from "../assets/vehicles/Private.svg";
import Truck from "../assets/vehicles/Truck.svg";
import BusSelected from "../assets/vehicles/BusSelected.svg";
import ExcavatorSelected from "../assets/vehicles/ExcavatorSelected.svg";
import ForkliftSelected from "../assets/vehicles/ForkliftSelected.svg";
import LCVSelected from "../assets/vehicles/LCVSelected.svg";
import MCVSelected from "../assets/vehicles/MCVSelected.svg";
import PickupSelected from "../assets/vehicles/PickupSelected.svg";
import PrivateSelected from "../assets/vehicles/PrivateSelected.svg";
import TruckSelected from "../assets/vehicles/TruckSelected.svg";

const vehicleNameToIcon: Map<string | undefined, string> = new Map([
  ["Private", Private],
  ["LCV", LCV],
  ["MCV", MCV],
  ["HCV", Truck],
  ["BUS", Bus],
  ["Forklift", Forklift],
  ["Machine", Excavator],
  ["Pickup", Pickup],
]);

const vehicleNameToIconSelected: Map<string | undefined, string> = new Map([
  ["Private", PrivateSelected],
  ["LCV", LCVSelected],
  ["MCV", MCVSelected],
  ["HCV", TruckSelected],
  ["BUS", BusSelected],
  ["Forklift", ForkliftSelected],
  ["Machine", ExcavatorSelected],
  ["Pickup", PickupSelected],
]);

function renderIconByName(variant: Variant, size: Size, name?: string) {
  let src = variant === "selected" ? vehicleNameToIconSelected.get(name) : vehicleNameToIcon.get(name);
  if (!src) {
    src = variant === "selected" ? PrivateSelected : Private;
  }
  return <img src={src} alt="vehicle" style={{ transform: size === "large" ? "scale(1.5)" : undefined }} />;
}

type Variant = "standard" | "selected";
type Size = "standard" | "large";

type EventIconProps = {
  name?: string;
  variant?: Variant;
  size?: Size;
};

function VehicleIcon({ name, variant = "standard", size = "standard" }: EventIconProps) {
  return name ? renderIconByName(variant, size, name) : <div>--</div>;
}

export default VehicleIcon;
