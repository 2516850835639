import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren } from "react";
import { AuthContextProvider } from "../context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContextProvider } from "../context/ToastContext";
import { IntlContextProvider } from "../context/IntlContext";
import { TspFleetContextProvider } from "../context/TspFleetContext/TspFleetContext";
import { LastUpdateContextProvider } from "../context/LastUpdateContext";
import { Constants } from "../constants";

export const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Constants.DEFAULT_STALETIME } } });

function RootProviders({ children }: PropsWithChildren) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <TspFleetContextProvider>
            <IntlContextProvider>
              <ToastContextProvider>
                <LastUpdateContextProvider>
                  {children}
                </LastUpdateContextProvider>
                <ReactQueryDevtools initialIsOpen={false} position="top-right" panelPosition="right" />
              </ToastContextProvider>
            </IntlContextProvider>
          </TspFleetContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default RootProviders;
