import Pagination from "../Table/Pagination";
import Table from "../Table/Table";
import styles from "./BillingTable.module.scss";
import { Status } from "../../icons/StatusIcon";
import { useMemo } from "react";
import { generateBillingColumns, getCurrencySign, queryResultToTableData } from "./billing-util";
import { useBillingQueryParamsContext } from "../../context/QueryParamsContext/BillingQueryParamsContextProvider";
import useQueryBillingReport from "../../hooks/useQueryBillingReport";
import { FormattedMessage, useIntl } from "react-intl";
import { LocaleType } from "../../context/IntlContext";
import BillingTableControl from "./BillingTableControl";
import { numberFormatter } from "../../util/text.util";
import { dateUtils, getFrontDtFormat } from "../../util/date.util";
import { extractFromAndTo } from "../../services/billing.service";
import { useProfile } from "../../hooks/useProfile";
import { Constants } from "../../constants";


export const currencySigns: { [key: string]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  ILS: '₪',
};
export interface BillingTableEntry {
  deviceId: string;
  deviceStatus: Status;
  assignFleetDate?: string;
  lastEventDateTime?: string;
  firstEventDate?: string;
  costs?: number;
  fleetAssigned?: string;
  eventsCount: number
  tspAssigned?: string
}

export const BilingTable = () => {
  const { locale } = useIntl();
  const { dtFormat } = useProfile();

  const { data: billingData, isLoading, isError,isFetching } = useQueryBillingReport(queryResultToTableData);
  const { queryParams, setPageIndex } = useBillingQueryParamsContext();
  const columns = useMemo(() => generateBillingColumns(billingData?.currencyISO!, locale as LocaleType), [billingData, locale])

  const { from, to } = useMemo(() => extractFromAndTo(queryParams?.filters), [queryParams])
  const format = getFrontDtFormat(dtFormat)?.date ?? Constants.DEFAULT_FRONT_DT_FORMAT.date;

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        <BillingTableControl />
        <div className="d-flex justify-content-between" style={{ marginLeft: "15px", fontSize: "20px", fontWeight: "500" }}>
          <div className="d-flex gap-2">
            <FormattedMessage id="Time.FROM" defaultMessage="From" />:
            <div>{dateUtils.getFormattedLocalDate(from,format)}</div>
            {to && (
              <>
                <FormattedMessage id="Time.TO" defaultMessage="To" />:
                <div>{dateUtils.getFormattedLocalDate(to,format)}</div>
              </>)
            }
          </div>
          <div className="d-flex gap-2">
            <FormattedMessage id="TOTAL_COST" defaultMessage="Total cost" />:
            <>
              {billingData?.currencyISO && (
                <div>{getCurrencySign(billingData.currencyISO)}{numberFormatter(Number(billingData?.totalCost), locale as LocaleType)}</div>
              )}
            </>
          </div>
        </div>
        <Table
          data={billingData?.data || []}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${row.isSelected ? "selected-row-bg" : ""} ${styles.tr}`,
            td: (row) => `${styles.td} ${row.isExpanded ? "border-bottom-0" : ""}`,
          }}
          isLoading={isLoading}
          isError={isError}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        allDisabled={isFetching}
        onChangePageIndex={setPageIndex}
        totalEntries={billingData?.totalCount}
      />
    </div>
  );
};

export default BilingTable;
