import { useCallback, useEffect, useState } from "react";
import { Constants } from "../constants";
import LocalStorage, {
  UpdateLocalStorageEvent,
} from "../util/local-storage.util";

export default function useLocalStorage<T>(
  key: string
): [T | undefined, (value: T | undefined) => void, () => void] {
  const [storedValue, setStoredValue] = useState<T | undefined>(
    LocalStorage.get<T>(key)
  );

  // Listen to storage change event
  const onStorageChange = useCallback(
    ({ key: eventKey, updatedValue }: UpdateLocalStorageEvent<T>) => {
      if (eventKey !== key) return;
      setStoredValue(updatedValue as T);
    },
    [key]
  );

  // Register to storage change event
  useEffect(() => {
    document.addEventListener(
      Constants.UpdateLocalStorageEventKey as keyof WindowEventMap,
      onStorageChange as (ev: Event) => void
    );

    return () => {
      document.removeEventListener(
        Constants.UpdateLocalStorageEventKey as keyof WindowEventMap,
        onStorageChange as (ev: Event) => void
      );
    };
  }, [onStorageChange]);

  const setValue = useCallback(
    (value?: T) => LocalStorage.set(key, value),
    [key]
  );

  const removeKey = useCallback(() => {
    LocalStorage.remove([key]);
  }, [key]);

  return [storedValue, setValue, removeKey];
}
