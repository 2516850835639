import ExpandingTreeSelectInput from "../form/ExpandingTreeSelectInput/ExpandingTreeSelectInput";
import { FilterOperator } from "../../models/requests/shared.request";
import { Node as TNode } from "react-checkbox-tree";
import useFilter from "../../hooks/useFilter";
import { ReactNode, useEffect, useState, useCallback } from "react";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";

interface ExpandingTreeFilterProps<ParamName extends string> {
  context : React.Context<IQueryParamsContext<any, any>>,
  nodes: TNode[];
  getLabel: (checkedValue: string) => ReactNode;
  placeHolder: string;
  paramName: ParamName;
  operator: FilterOperator;
  width?: string;
  inputLabel?: string;
  includeInUrl?: boolean;
}

function ExpandingTreeFilter<ParamName extends string>({
  context,
  nodes,
  paramName,
  operator,
  placeHolder,
  inputLabel,
  width,
  getLabel,
  includeInUrl,
}: ExpandingTreeFilterProps<ParamName>) {
  const onForcedClear = useCallback(() => {
    setChecked([]);
  }, []);

  const { upsertOrRemoveFilter, contextValues } = useFilter<ParamName>(
    context,
    paramName,
    operator,
    onForcedClear,
    includeInUrl
  );
  const [checked, setChecked] = useState<readonly string[]>(contextValues);
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => setChecked(contextValues), [contextValues]);

  return (
    <ExpandingTreeSelectInput
      nodes={nodes}
      checked={checked}
      onCheck={(values) => {
        const sortedValues = [...values].sort();
        setChecked(sortedValues);
        upsertOrRemoveFilter(sortedValues);
      }}
      expanded={expanded}
      onExpand={setExpanded}
      placeholder={placeHolder}
      inputLabel={inputLabel}
      width={width}
      getLabel={getLabel}
    />
  );
}

export default ExpandingTreeFilter;
