/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddDriver,
  DriverWithMoreData,
  EnrolledDriver,
  Error,
  FleetSenseDriver,
  GetListResponseFleetSenseDrivers,
  ImageData,
  MessageId,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class DriverManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get list of device's enrolled driver for specific device. Device must be online
   *
   * @tags Driver Management
   * @name GetDeviceEnrolledDrivers
   * @summary Get list of all device's enrolled driver for specific device
   * @request GET:/device/{deviceId}/drivers
   * @secure
   */
  getDeviceEnrolledDrivers = (deviceId: string, params: RequestParams = {}) =>
    this.request<EnrolledDriver[], void>({
      path: `/device/${deviceId}/drivers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name TakeDriverFacePhoto
   * @summary Enroll driver to device, by instructing device to capture a photo of the driver in front of the camera
   * @request POST:/device/{deviceId}/driver/{driverId}/photo/capture
   * @secure
   */
  takeDriverFacePhoto = (
    deviceId: string,
    driverId: string,
    query?: {
      /** whether the driver has permission to use this device */
      permission?: boolean | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<ImageData, void>({
      path: `/device/${deviceId}/driver/${driverId}/photo/capture`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name GiveDriverFacePhoto
   * @summary Enroll driver to device by providing vector image to device
   * @request POST:/device/{deviceId}/driver/{driverId}/photo/upload
   * @secure
   */
  giveDriverFacePhoto = (deviceId: string, driverId: string, data: FleetSenseDriver, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/device/${deviceId}/driver/${driverId}/photo/upload`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name AddDriverToDevice
   * @request POST:/driver/{tspId}/{fleetId}/{driverId}/{deviceId}
   * @secure
   */
  addDriverToDevice = (
    deviceId: string,
    driverId: string,
    fleetId: number,
    tspId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/driver/${tspId}/${fleetId}/${driverId}/${deviceId}`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name RemoveDriverFromDevice
   * @summary Delete driver from device
   * @request DELETE:/driver/{tspId}/{fleetId}/{driverId}/{deviceId}
   * @secure
   */
  removeDriverFromDevice = (
    deviceId: string,
    driverId: string,
    fleetId: number,
    tspId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/driver/${tspId}/${fleetId}/${driverId}/${deviceId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name AddDriver
   * @summary Add driver
   * @request POST:/driver/{driverId}/{tspId}/{fleetId}
   * @secure
   */
  addDriver = (driverId: string, tspId: number, fleetId: number, data: AddDriver, params: RequestParams = {}) =>
    this.request<MessageId, void>({
      path: `/driver/${driverId}/${tspId}/${fleetId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name DeleteDriver
   * @summary delete driver
   * @request DELETE:/driver/{driverId}/{tspId}/{fleetId}
   * @secure
   */
  deleteDriver = (driverId: string, tspId: number, fleetId: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/driver/${driverId}/${tspId}/${fleetId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name UpdateDriver
   * @summary Update driver
   * @request PUT:/driver/{driverId}/{tspId}/{fleetId}
   * @secure
   */
  updateDriver = (driverId: string, tspId: number, fleetId: number, data: AddDriver, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/driver/${driverId}/${tspId}/${fleetId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Driver Management
   * @name GetDriver
   * @summary get Driver
   * @request GET:/driver/{driverId}/{tspId}/{fleetId}
   * @secure
   */
  getDriver = (
    driverId: string,
    tspId: number,
    fleetId: number,
    query?: {
      includeDevices?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<DriverWithMoreData, void>({
      path: `/driver/${driverId}/${tspId}/${fleetId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description get list of drivers
   *
   * @tags Driver Management
   * @name GetDrivers
   * @summary Get list of all drivers
   * @request GET:/driver
   * @secure
   */
  getDrivers = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseFleetSenseDrivers, Error>({
      path: `/driver`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
