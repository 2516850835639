import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTspMutation, deleteTspMutation, updateTspMutation, updateTspProfileMutation } from "../mutations/tsp.mutation";
import { useToastContext } from "../context/ToastContext";
import { AxiosResponse } from "axios";
import { SP, UserProfile } from "../api/data-contracts";

interface UseTspParams {
  onSuccessAdd?: (response: AxiosResponse<SP>) => void;
  onSuccessDelete?: () => void;
  onSuccessUpdate?: (response: AxiosResponse<SP>) => void;
  onSuccessUpdateProfile?: (response: AxiosResponse<UserProfile>) => void;

}

function useTsp({ onSuccessAdd, onSuccessDelete, onSuccessUpdate,onSuccessUpdateProfile }: UseTspParams = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();

  const {
    mutate: addTsp,
    isLoading: isLoadingAddTsp,
    isError: isErrorAddTsp,
  } = useMutation(
    addTspMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["tsp"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        addToast({ type: "POSITIVE", content: "Added TSP successfully" });
        onSuccessAdd?.(response);
      },
      onError: (error) => {
        let { response } = error;
        let data = response?.data as any
        let message = data?.Exception?.Message
        addToast({ type: "NEGATIVE", content: `Failed adding TSP ${ (message ? message : "" )}` });
      },
    })
  );

  const {
    mutate: deleteTsp,
    isLoading: isLoadingDeleteTsp,
    isError: isErrorDeleteTsp,
  } = useMutation(
    deleteTspMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tsp"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        addToast({ type: "POSITIVE", content: "Deleted TSP successfully" });
        onSuccessDelete?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed deleting TSP" });
      },
    })
  );

  const {
    mutate: updateTsp,
    isLoading: isLoadingUpdateTsp,
    isError: isErrorUpdateTsp,
  } = useMutation(
    updateTspMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["tsp"] });
        addToast({ type: "POSITIVE", content: "Updated TSP successfully" });
        onSuccessUpdate?.(response);
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed updating TSP" });
      },
    })
  );

  const {
    mutate: updateTspProfile,
    isLoading: isLoadingUpdateTspProfile,
    isError: isErrorUpdateTspProfile,
  } = useMutation(
    updateTspProfileMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["tsp"] });
        addToast({ type: "POSITIVE", content: "Updated TSP successfully" });
        onSuccessUpdateProfile?.(response);
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed updating TSP" });
      },
    })
  );

  return {
    addTsp,
    isLoadingAddTsp,
    isErrorAddTsp,
    deleteTsp,
    isLoadingDeleteTsp,
    isErrorDeleteTsp,
    updateTsp,
    isLoadingUpdateTsp,
    isErrorUpdateTsp,
    updateTspProfile,
    isLoadingUpdateTspProfile,
    isErrorUpdateTspProfile,
  };
}

export default useTsp;
