import { useCallback, useMemo } from "react";
import { IQueryParamsContext, useQueryParamsContext } from "../context/QueryParamsContext/QueryParamsContext";
import { SortStatus } from "../util/sorting.util";
/* 
Hook that performs sorting logic
Gets sorting param name, Returns sort status(inactive, descending, ascending) and setSort function
Requires QueryParamsContext provider
*/

function useSort<ParamName extends string>(paramName: ParamName, context: React.Context<IQueryParamsContext<any, any>>) {
  const {
    queryParams: { sorts },
    setSort,
  } = useQueryParamsContext<ParamName, string>(context);

  const status: SortStatus = useMemo((): SortStatus => {
    if (!sorts.length || sorts[0].param !== paramName) return "INACTIVE";
    if (sorts[0].descending) return "DESCENDING";
    return "ASCENDING";
  }, [paramName, sorts]);

  const partialSetSort = useCallback(() => {
    setSort(paramName, status === "ASCENDING");
  }, [status, setSort, paramName]);

  return {
    setSort: partialSetSort,
    status,
  };
}

export default useSort;
