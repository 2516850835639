import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { Constants } from "../constants";

type ToastType = "POSITIVE" | "NEGATIVE";
type ToastId = number;

interface Toast {
  type: ToastType;
  content: ReactNode;
  onUndo?: () => void;
}

interface ToastWithId extends Toast {
  id: ToastId;
}

interface IToastContext {
  toasts: ToastWithId[];
  add: (toast: Toast) => ToastId;
  remove: (id: ToastId) => void;
}

const ToastContext = createContext<IToastContext>({
  toasts: [],
  add: () => 0,
  remove: () => {},
});

/* Context for toast messages. Allows adding and removing toasts. A toast is removed automatically after Constants.TOAST_AUTOHIDE_DELAY ms */
export function ToastContextProvider({ children }: PropsWithChildren<{}>) {
  const [toasts, setToasts] = useState<ToastWithId[]>([]);

  const remove = useCallback((idToRemove: number) => {
    setToasts((curr) => curr.filter((toast) => toast.id !== idToRemove));
  }, []);

  const add = useCallback(
    (toast: Toast) => {
      const id = new Date().getTime();
      setToasts((prev) => [...prev, { ...toast, id }]);
      setTimeout(() => remove(id), Constants.TOAST_AUTOHIDE_DELAY);
      return id;
    },
    [remove]
  );

  return (
    <ToastContext.Provider value={{ toasts, add, remove }}>
      {children}
    </ToastContext.Provider>
  );
}

export const useToastContext = () => useContext(ToastContext);
