import {
  CSSProperties,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { ReactPlayerProps } from "react-player";
import { OnProgressProps } from "react-player/base";
import { ReactComponent as OpenIcon } from "../../assets/icons/Open.svg";

const isCloseTo = (t1: number, t2: number) => Math.abs(t1 - t2) < 0.1;

interface FrameOptions {
  wrapper: {
    onClick?: MouseEventHandler<HTMLDivElement>;
    style: CSSProperties;
  };
  player: ReactPlayerProps;
}

interface DualVideoPlayerProps {
  mainVideoUrl: string;
  secondaryVideoUrl?: string;
  setVideoModalOpened?: (open: boolean) => void;
  videoModalOpened: boolean;
  onError: () => void;
  style?: CSSProperties;
  secondaryVideoBorderColor?: CSSProperties["borderColor"];
}
/* Renders 2 video elements, one within another, and plays them in sync. On clicking the secondary, they swap roles */
const DualVideoPlayer = ({
  mainVideoUrl,
  secondaryVideoUrl,
  setVideoModalOpened,
  videoModalOpened,
  onError,
  style,
  secondaryVideoBorderColor,
}: DualVideoPlayerProps) => {
  const [showSecondaryPlayer, setShowSecondaryPlayer] = useState(true);
  const secondaryPlayerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(false);

  const swapFrameOptions: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFramesOptions((prev) => ({
      main: prev.secondary,
      secondary: prev.main,
    }));
  };

  function onProgress({ playedSeconds }: OnProgressProps) {
    const secondaryPlayer = secondaryPlayerRef.current;
    if (
      secondaryPlayer &&
      !isCloseTo(playedSeconds, secondaryPlayer.getCurrentTime())
    ) {
      secondaryPlayer.seekTo(playedSeconds, "seconds");
    }
  }

  function onSeek(seconds: number) {
    const secondaryPlayer = secondaryPlayerRef.current;
    if (secondaryPlayer && secondaryPlayer.getDuration() >= seconds) {
      secondaryPlayer.seekTo(seconds, "seconds");
    }
  }

  const initalFramesOptions: {
    main: FrameOptions;
    secondary: FrameOptions;
  } = useMemo(() => {
    return {
      main: {
        wrapper: { style: { width: "100%" } },
        player: {
          controls: true,
          width: "100%",
          height: "fit-content",
          style: { display: "flex" },
          progressInterval: 500,
          onProgress,
          onSeek,
          onError,
        },
      },
      secondary: {
        wrapper: { onClick: swapFrameOptions, style: { width: "100%" } },
        player: {
          ref: secondaryPlayerRef,
          width: "33.3%",
          height: "fit-content",
          style: {
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
            cursor: "pointer",
            border: `2px solid ${secondaryVideoBorderColor}`,
            display: "flex",
          },
          muted: true,
          onError: () => setShowSecondaryPlayer(false),
        },
      },
    };
  }, [onError, secondaryVideoBorderColor]);
  const [framesOptions, setFramesOptions] = useState(initalFramesOptions);

  useEffect(() => {
    setShowSecondaryPlayer(true);
    setPlaying(false);
    setFramesOptions(initalFramesOptions);
  }, [mainVideoUrl, secondaryPlayerRef, initalFramesOptions]);

  return (
    <div className="position-relative" style={style}>
      <>
        <div {...framesOptions.main.wrapper}>
          <ReactPlayer
            {...framesOptions.main.player}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            playing={playing}
            url={mainVideoUrl}
          />
        </div>
        {secondaryVideoUrl && showSecondaryPlayer && (
          <div {...framesOptions.secondary.wrapper}>
            <ReactPlayer
              {...framesOptions.secondary.player}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              playing={playing}
              url={secondaryVideoUrl}
            />
          </div>
        )}
        {!videoModalOpened && (
          <OpenIcon
            fill={"white"}
            style={{ position: "absolute", bottom: "41px", right: "33px" }} //TODO: refine it for all cases (FF/Edge/Safari ?)
            onClick={() => setVideoModalOpened?.(true)}
          />
        )}
      </>
    </div>
  );
};

export default DualVideoPlayer;
