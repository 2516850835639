import { AxiosResponse } from "axios";
import { UserManagement as UserApi } from "../api/UserManagement";
import { PostUser, User, UserProfile, UserWithMoreData } from "../api/data-contracts";

import { FilterParam, ServiceGetResult } from "../models/requests/shared.request";
import { ChangePasswordInt, SetPasswordInt } from "../mutations/user.mutation";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

export type UsersSortFilterParamName =
  | "userName"
  | "email"
  | "role"
  | "phoneNumber"
  | "company"
  | "lastAccessed"
  | "tspId"
  | "fleetId"
  | "fullName";

const userApi = new UserApi();
addAuthHandling(userApi);

type PagingParams = { pageIndex: number; pageSize: number };
export type UsersSortParam = {
  param: UsersSortFilterParamName;
  descending?: boolean;
};

export type GetUsersParams = {
  paging: PagingParams;
  sorts: UsersSortParam[];
  filters: FilterParam<UsersSortFilterParamName>[];
};

export type GetUsersResult = ServiceGetResult<User[]>;

export type PutProfileParams = UserProfile & { userEmail: string };

export const userService = {
  getUsers: async ({ paging, sorts, filters }: GetUsersParams): Promise<GetUsersResult> => {
    const result = await userApi.getUsers({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging.pageIndex,
      pageSize: paging.pageSize,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }
    return {
      pageCount,
      data: result.data.data,
      totalCount: header?.totalCount,
    };
  },

  addUser: async (user: PostUser, email: string): Promise<AxiosResponse> => {
    return await userApi.addFmUser(email, user);
  },

  updateUser: async (user: PostUser, email: string): Promise<AxiosResponse<User>> => {
    return await userApi.updateFmUser(email, user);
  },

  setPassword: async ({ userEmail, password }: SetPasswordInt): Promise<AxiosResponse> => {
    return await userApi.resetPassword(userEmail, { password });
  },

  changePassword: async ({ oldPassword, newPassword }: ChangePasswordInt): Promise<AxiosResponse> => {
    return await userApi.changePassword({ oldPassword: oldPassword, password: newPassword });
  },

  deleteUser: async (userEmail: string): Promise<AxiosResponse> => {
    return await userApi.deleteUser(userEmail);
  },

  getUser: async (email: string): Promise<AxiosResponse<UserWithMoreData, any>> => {
    return await userApi.getUser(email);
  },

  approveTerms: async (email: string): Promise<AxiosResponse<void, any>> => {
    return await userApi.approveTerms(email);
  },

  putProfile: async ({ userEmail, ...profile }: PutProfileParams) => await userApi.updateProfile(userEmail, profile),
};
