import { Icon, PointTuple } from "leaflet";
import { useCallback, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
import { Color, RouteConstants } from "../../constants";
import EventIcon from "../../icons/EventIcon";
import { filterForEventsDate, filterForEventsOfDevice } from "../FleetView/fleet-utils";
import MarkerEnabled from "../../assets/MapMarkers/MarkerEnabled.svg";
import MarkerEnabledYellow from "../../assets/MapMarkers/MarkerEnabledYellow.svg";
import MarkerEnabledRed from "../../assets/MapMarkers/MarkerEnabledRed.svg";
import MarkerHover from "../../assets/MapMarkers/MarkerHover.svg";
import MarkerSelected from "../../assets/MapMarkers/MarkerSelected.svg";
import MarkerShadow from "../../assets/MapMarkers/MarkerShadow.svg";
import { ReactComponent as DevicesIcon } from "../../assets/icons/Device.svg";
import { ReactComponent as DriverIcon } from "../../assets/icons/Driver.svg";
import { ReactComponent as EventsIcon } from "../../assets/icons/event.svg";
import { ReactComponent as TripIcon } from "../../assets/icons/Trip.svg";
import { useDevicesQueryParamsContext } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import VehicleIcon from "../../icons/VehicleIcon";
import { MapEvent } from "../../models/map-event.model";
import { getEventMetadata } from "../../util/events.util";

const iconAnchor: PointTuple = [11, 34];
const markerIcons = {
  Low: new Icon({
    iconUrl: MarkerEnabled,
    shadowUrl: MarkerShadow,
    iconAnchor: iconAnchor,
  }),
  Medium: new Icon({
    iconUrl: MarkerEnabledYellow,
    shadowUrl: MarkerShadow,
    iconAnchor: iconAnchor,
  }),

  High: new Icon({
    iconUrl: MarkerEnabledRed,
    shadowUrl: MarkerShadow,
    iconAnchor: iconAnchor,
  }),
};

const selectedMarkerIcon = new Icon({
  iconUrl: MarkerSelected,
  shadowUrl: MarkerShadow,
  iconAnchor: iconAnchor,
  className: "selected-icon",
});
const hoveredMarkerIcon = new Icon({
  iconUrl: MarkerHover,
  shadowUrl: MarkerShadow,
  iconAnchor: iconAnchor,
});

type EventMarkersProps = {
  data: MapEvent;
  selectedId?: number;
  index: number;
};

const EventMarker = ({ data, selectedId, index }: EventMarkersProps) => {
  const [hoveredId, setHoveredId] = useState<number>(-1);
  const {
    queryParams: { filters: devicesFilters },
  } = useDevicesQueryParamsContext();
  const { getFormattedLocalDate } = useFormattedLocalDate();

  const eventHandlers = useCallback(
    (index: number) => ({
      mouseover() {
        setHoveredId(index);
      },
      mouseout() {
        setHoveredId(-1);
      },
    }),
    []
  );

  const severity = getEventMetadata(data.type)!.severity;
  return (
    <>
      {data && data.location && data.deviceId && (
        <Marker
          eventHandlers={eventHandlers(index)}
          key={`marker-${data.deviceId}-{index}`}
          position={[data.location!.lat!, data.location!.lng!]}
          icon={
            selectedId === index ? selectedMarkerIcon : hoveredId === index ? hoveredMarkerIcon : markerIcons[severity]
          }
          riseOnHover={true}
          zIndexOffset={selectedId === index ? 500 : undefined}
        >
          <Popup>
            <ListGroup>
              <ListGroupItem className="border-end-0 border-top-0 border-start-0">
                <VehicleIcon name={data.vehicleType} />
                <span className="m-2">{data.vehicleType}</span>
              </ListGroupItem>
              <ListGroupItem className="border-end-0 border-start-0">
                <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />:
                <span className="m-2">{data.licensePlate}</span>
              </ListGroupItem>
              <ListGroupItem className="border-end-0 border-start-0">
	      	<FormattedMessage id="ADDRESS" defaultMessage="Address" />:
	      	{data?.location.address}
	      </ListGroupItem>
              <ListGroupItem className="border-end-0 border-start-0">
                <DevicesIcon fill="black" />
                <span className="m-2">
                  <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />:
                </span>
                {data.deviceId}
              </ListGroupItem>
              <ListGroupItem className="border-end-0 border-start-0">
                <DriverIcon fill={Color.DARK_GRAY_1} />
                <span className="m-2">
                  <FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />:
                </span>
                {data.driverId}
              </ListGroupItem>
              <ListGroupItem className="d-flex border-end-0 border-start-0">
                <div className="d-flex align-items-center">
                  <EventIcon serverName={data.type} />
                  <span className="m-2">{getEventMetadata(data.type)?.displayName}</span>
                </div>
                <div className="m-2 text-nowrap align-self-center">
                  {getFormattedLocalDate(getEventMetadata(data.time)?.displayName ?? "")}
                </div>
              </ListGroupItem>
              <ListGroupItem className="border-end-0 border-start-0 border-bottom-0">
                <Link
                  className="text-decoration-underline"
                  to={`/${RouteConstants.EVENTS_ON_MAP}/${RouteConstants.TRIPS}`}
                  state={
                    {
                      // filters: [filterForEventsOfDevice(data.), ...filterForEventsDate(devicesFilters)],
                    }
                  }
                >
                  <TripIcon style={{ margin: "-0.25rem 0.4rem 0 0" }} />
                  <FormattedMessage id="TRIP_VIEW" defaultMessage="Trip View" />
                </Link>
              </ListGroupItem>
              <ListGroupItem className="border-end-0 border-start-0 border-bottom-0">
                <Link
                  className="text-decoration-underline"
                  to={`../${RouteConstants.EVENTS_LIST}`}
                  state={{
                    filters: [filterForEventsOfDevice(data.deviceId), ...filterForEventsDate(devicesFilters)],
                  }}
                >
                  <EventsIcon fill="#3587EB" style={{ margin: "-0.25rem 0.4rem 0 0" }} />
                  <FormattedMessage id="ALL_EVENTS_FOR_THIS_VEHICLE" defaultMessage="All events for this vehicle" />
                </Link>
              </ListGroupItem>
            </ListGroup>
          </Popup>
        </Marker>
      )}
    </>
  );
};
export default EventMarker;
