import { createContext } from "react";
import { Constants } from "../../constants";
import {
  FilterParam,
  QueryParams,
  SortParam,
} from "../../models/requests/shared.request";
import { IQueryParamsContext } from "./QueryParamsContext";

export const commonInitialParams: QueryParams<SortParam, FilterParam> = {
  paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  sorts: [],
  filters: [],
};


export function createQueryParamContext() {
  return  createContext<IQueryParamsContext<any, any>>({
    queryParams: {
      filters: [],
      paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
      sorts: [],
      geocoding: true,
    },
    setPageIndex: () => { },
    upsertFilter: () => { },
    removeFilter: () => { },
    setSort: () => { },
    clearFilters: () => { },
    registerFilter: () => "",
    unregisterFilter: () => { },
  });
}