import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import useFilter from "../../hooks/useFilter";
import { FilterOperator } from "../../models/requests/shared.request";
import { GroupBase, MultiValue, OptionsOrGroups } from "react-select";
import MultiSelectInput, { isGrouped } from "../form/MultiSelectInput/MultiSelectInput";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";

type BaseOption = {
  value: string;
  label: ReactNode;
};

type MultiSelectFilterProps<TParamName extends string, Option extends BaseOption> = {
  context : React.Context<IQueryParamsContext<any, any>>,
  placeHolder: string;
  paramName: TParamName;
  operator: FilterOperator;
  options: OptionsOrGroups<Option, GroupBase<Option>>;
  isLoading?: boolean;
  width?: string;
  minWdith?: string,
  maxWdith?: string,
  inputLabel?: string;
  includeInUrl?: boolean;
};

const MultiSelectFilter = <TParamName extends string, Option extends BaseOption = BaseOption>({
  context,
  placeHolder,
  paramName,
  options,
  isLoading,
  operator,
  width,
  minWdith,
  maxWdith,
  inputLabel,
  includeInUrl,
}: MultiSelectFilterProps<TParamName, Option>) => {
  const flattenedOptions = useMemo(() => {
    if (isGrouped(options)) {
      return options.flatMap((groupObj) => groupObj.options);
    } else {
      return options as Option[];
    }
  }, [options]);

  const onForcedClear = useCallback(() => {
    setSelectedOptions([]);
  }, []);

  const { upsertOrRemoveFilter, contextValues } = useFilter<TParamName>(
    context,
    paramName,
    operator,
    onForcedClear,
    includeInUrl
  );
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<BaseOption>>(() => {
    const initialValSet = new Set(contextValues);
    return flattenedOptions.filter((option) => initialValSet.has(option.value));
  });

  useEffect(() => {
    const valSet = new Set(contextValues);
    setSelectedOptions(flattenedOptions.filter((option) => valSet.has(option.value)));
  }, [contextValues, flattenedOptions]);

  return (
    <MultiSelectInput
      value={selectedOptions}
      isLoading={isLoading}
      placeholder={placeHolder}
      options={options}
      onChange={(options) => {
        setSelectedOptions(options);
        upsertOrRemoveFilter(options.map((option) => option.value));
      }}
      controlWidth={width}
      controlMaxWidth={maxWdith}
      controlMinWidth={minWdith}
      inputLabel={inputLabel}
    />
  );
};

export default MultiSelectFilter;
