import { CanceledError, InternalAxiosRequestConfig } from "axios";
import { decodeToken, isExpired } from "react-jwt";
import LocalStorage from "../util/local-storage.util";
import { Constants } from "../constants";
import { AuthState } from "../context/AuthContext";
import { HttpClient } from "../api/http-client";

function ignoreCanceledError(error: any) {
  if (error instanceof CanceledError) {
    return;
  }
  throw error;
}

/* Handles authentication for a request */
function tokenHandlerInterceptor(config: InternalAxiosRequestConfig) {
  const authData = LocalStorage.get<AuthState>(Constants.AuthStorageKey);

  if (!authData || isExpired(authData.token)) {
    const controller = new AbortController();
    controller.abort();
    config.signal = controller.signal;
    LocalStorage.remove([Constants.AuthStorageKey]);
  } else {
    config.headers.Authorization = "Bearer " + authData.token;
  }
  return config;
}

export function decodeUserName(token: string): string {
  const decodedData: any = decodeToken(token);

  let username: string = "";
  for (const keyValArr of Object.entries(decodedData)) {
    if (keyValArr[0].split("/").pop() === "name") {
      username = keyValArr[1] as string;
      break;
    }
  }

  return username;
}

/* Registers an api client with authentication handling interceptors */
export function addAuthHandling(api: HttpClient<unknown>): void {
  api.instance.interceptors.request.use(tokenHandlerInterceptor);
  api.instance.interceptors.response.use(
    (response) => response,
    ignoreCanceledError
  );
}
