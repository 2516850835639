import { FormattedMessage, useIntl } from "react-intl";
import { useBillingQueryParamsContext } from "../../context/QueryParamsContext/BillingQueryParamsContextProvider";
import { useEffect, useMemo, useRef, useState } from "react";
import MultiSelectFilter from "../Filters/MultiSelectFilter";
import { BillingSortAndFilterParamNames, billingService } from "../../services/billing.service";
import { useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { InputUi } from "../../constants";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import { CSVLink } from "react-csv";
import { FleetSenseDeviceBilling } from "../../api/data-contracts";
import { billingExportSchema } from "./export-schema";
import { BILLING_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
import DatePickerFilter from "../Filters/DatePickerFilter";

const BillingTableControl = () => {
  const [exportData, setExportData] = useState<FleetSenseDeviceBilling[]>([]);
  const { allTsps, activeFleets } = useTspFleetContext();
  const { formatMessage } = useIntl();
  const { queryParams, clearFilters } = useBillingQueryParamsContext();
  const [exportDataReady, setExportDataReady] = useState(false);

  const allString = formatMessage({
    id: "ALL",
    defaultMessage: "All",
  });

  const csvLink = useRef<any>(null);

  const doExport = async () => {
    var billingReport = await billingService
      .getBillingReport({ ...queryParams, paging: undefined });
       
    if (billingReport.data) {
      setExportData(billingReport.data);
      setExportDataReady(true);
    }
  };
  const tspOptions = useMemo(() => {
    return allTsps.map(tsp => ({ value: tsp.id!.toString(), label: tsp.companyFriendlyName! }))
  }, [allTsps]);

  const fleetsOptions = useMemo(() => {
    return activeFleets.map(fleet => ({ value: fleet.id!.toString(), label: fleet.companyFriendlyName! }))
  }, [activeFleets]);


  useEffect(() => {
    if (exportDataReady) {
      csvLink.current.link.click();
      setExportDataReady(false);
    }
  }, [exportData, exportDataReady]);

  return (
    <div className="d-flex justify-content-between">
      <div className="align-self-end" style={{ fontSize: "24px", fontWeight: "500" }}>
        <FormattedMessage id="BILLING_REPORT" defaultMessage="Billing Report" />
      </div>
      <div className="d-flex gap-2 align-items-end py-2 mb-2 flex-wrap">
        <MultiSelectFilter<BillingSortAndFilterParamNames>
          context={BILLING_CONTEXT}
          paramName="tspId"
          operator="=="
          placeHolder={allString}
          inputLabel={formatMessage({
            id: "TSP",
            defaultMessage: "TSP",
          })}
          options={tspOptions}
          width={InputUi.MULTI_STD_WIDTH}
          includeInUrl
        />
        <MultiSelectFilter<BillingSortAndFilterParamNames>
          context={BILLING_CONTEXT}
          paramName="fleetId"
          operator="=="
          placeHolder={allString}
          inputLabel={formatMessage({
            id: "FLEET",
            defaultMessage: "Fleet",
          })}
          options={fleetsOptions}
          width={InputUi.MULTI_STD_WIDTH}
        />

        <DatePickerFilter
          label="From"
          context={BILLING_CONTEXT}
          paramName="from"
          operator=">="
          width={InputUi.SINGLE_STD_WIDTH}
        />
        <DatePickerFilter
          label="To"
          context={BILLING_CONTEXT}
          paramName="to"
          operator="<="
          width={InputUi.SINGLE_STD_WIDTH}
        />
        <div className="ms-auto d-flex gap-2">
          <ActionButton
            className="action-btn position-relative"
            onClick={() =>
              clearFilters([])
            }
          >
            <FormattedMessage id="CLEAR_FILTER" defaultMessage="Clear Filter" />
          </ActionButton>
          <ActionButton onClick={doExport} className="action-btn position-relative">
            <FormattedMessage id="EXPORT" defaultMessage="Export" />
          </ActionButton>

          <CSVLink
            ref={csvLink}
            data={exportData}
            enclosingCharacter={""}
            headers={billingExportSchema}
            filename={`billingReport.csv`}
          ></CSVLink>
        </div>
      </div>
    </div>
  );
};

export default BillingTableControl;
