interface ModalState<ModalName, ModalData> {
  shownModal: ModalName | null;
  data?: ModalData;
}
type ModalAction<ModalName, ModalData> =
  | {
      type: "SET_SHOW";
      payload: { modalName: ModalName; data: ModalData };
    }
  | { type: "CLEAR" };

export const modalInitialState = {
  shownModal: null,
};

export function modalReducer<ModalName, ModalData>(
  state: ModalState<ModalName, ModalData>,
  action: ModalAction<ModalName, ModalData>
): ModalState<ModalName, ModalData> {
  switch (action.type) {
    case "SET_SHOW":
      return {
        ...state,
        shownModal: action.payload.modalName,
        data: action.payload.data,
      };
    case "CLEAR":
      return modalInitialState;
  }
}
