import { Authentication } from "../api/Authentication";
import { Login } from "../api/data-contracts";
import Logger from "../util/logger.util";

const logger = new Logger({ source: "Authentication Service", off: true });
const authApi = new Authentication();

export const authService = {
  login: async (loginParams: Login) => {
    logger.info(`login ${loginParams}...`);
    return await authApi.loginCreate(loginParams);
  },
};
