import { ButtonHTMLAttributes, PropsWithChildren, ReactNode } from "react";

export type BaseButtonProps = PropsWithChildren<
  {
    icon?: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
  } & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className" | "disabled" | "onClick">
>;

function BaseButton({ className, icon, disabled, children, onClick, ...rest }: BaseButtonProps) {
  return (
    <button
      className={`d-flex align-items-center gap-2 text-nowrap ${className ?? ""}`}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {icon}
      {children}
    </button>
  );
}

export default BaseButton;
