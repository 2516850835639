import { Row, TableInstance } from "react-table";
import { DriverTableEntry } from "./DriversTable";
import { FormattedMessage } from "react-intl";
import useQueryDriverDevices from "../../hooks/useQueryDriverDevices";
import PhotoWithOverlay from "../VehiclesTable/PhotoOverlay";
import { addBase64Prefix } from "../../util/shared.util";
import VehiclePhotoPlaceholder from "../../assets/VehiclePhotoPlaceholder.svg";
import { Link } from "react-router-dom";
import { Color, RouteConstants } from "../../constants";
import { FilterParam } from "../../models/requests/shared.request";
import { DevicesSortFilterParamName } from "../../services/devices.service";

interface ExpandedRowProps {
  row: Row<DriverTableEntry>;
  table: TableInstance<DriverTableEntry>;
}

const ExpandedRow = ({ row, table }: ExpandedRowProps) => {
  const { data } = useQueryDriverDevices({ driverId: row.original.driverId!, fleetId: row.original.fleetId, tspId: row.original.tspId, includeDevices: true })

  const filter: FilterParam<DevicesSortFilterParamName> =
  {
    paramName: "driverID",
    operator: "==",
    values: [row.original.driverId!],
  };

  return (
    <tr style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} className="selected-row-bg">
      <td colSpan={table.visibleColumns.length}>
        {!!data?.length && (
          <>
            <div className="d-flex gap-1 ms-4 align-center">
              <FormattedMessage id="ASSIGN_TO_VEHICLE" defaultMessage={"Assign to Vehicle"} />
              <div>({data?.length})</div>
              <Link
                to={`../${RouteConstants.VEHICLES}`}
                state={{ filters: [filter] }}
                style={{ color: Color.CIPIA_BLUE, textDecoration: "underline" }}
              >
                <FormattedMessage id="MORE_VEHICLES" defaultMessage="More vehicles" />
              </Link>
            </div>
            <div className="d-flex gap-4 ms-4 align-center">
              {data?.map(d =>
              (
                <div key={d.id} className="d-flex justify-content-center">
                  <PhotoWithOverlay src={(d.installationData?.Image && addBase64Prefix(d.installationData.Image)) || VehiclePhotoPlaceholder} />
                  <div className="align-self-center">
                    {d.installationData?.LicensePlateNumber}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

      </td>
    </tr>
  );
};

export default ExpandedRow;
