import { SVGProps } from "react";

interface DoubleArrowProps {
  color?: string;
  direction?: "RIGHT" | "LEFT";
}

function DoubleArrow({
  color = "black",
  direction = "RIGHT",
  ...props
}: DoubleArrowProps & SVGProps<SVGSVGElement>) {
  const roatation = direction === "RIGHT" ? 0 : 180;

  return (
    <svg
      transform={`rotate(${roatation})`}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.86981 14.1257L5.62085 12.8768L11.3719 7.12573L5.62085 1.3747L6.86981 0.125732L13.8698 7.12573L6.86981 14.1257Z"
        fill={color}
      />
      <path
        d="M1.6416 14.1257L0.392639 12.8768L6.14368 7.12573L0.392639 1.3747L1.6416 0.125732L8.6416 7.12573L1.6416 14.1257Z"
        fill={color}
      />
    </svg>
  );
}

export default DoubleArrow;
