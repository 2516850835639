import { useQuery } from "@tanstack/react-query";
import { GetFleetsParams } from "../services/fleet.service";
import { fleetsQuery } from "../queries/fleets.query";
import { useMemo } from "react";

interface UseQueryFleets {
  enabled: boolean;
  tspIds?: number[];
}

function useQueryFleets({ enabled, tspIds }: UseQueryFleets) {
  const queryParams: GetFleetsParams = useMemo(() => {
    return {
      filters: tspIds?.length ? [{ paramName: "tspId", operator: "==", values: tspIds.map((id) => String(id)) }] : [],
      sorts: [],
      paging: { pageIndex: 1, pageSize: 1000 },
    };
  }, [tspIds]);

  const { data, isLoading, isError, isFetching } = useQuery(fleetsQuery(queryParams, enabled));

  return { data, isLoading, isError, isFetching };
}

export default useQueryFleets;
