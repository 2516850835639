import Table from "../Table/Table";
import { Row } from "react-table";
import styles from "./ConfigTable.module.scss";
import Pagination from "../Table/Pagination";
import { GetDeviceConfig, Role } from "../../api/data-contracts";
import { useCallback, useMemo, useReducer, useState } from "react";
import { EMPTY_ARR } from "../../util/shared.util";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import DeleteConfigurationModal from "./modals/DeleteConfigModal";
import { useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { useAuthContext } from "../../context/AuthContext";
import { useConfigQueryParamsContext } from "../../context/QueryParamsContext/ConfigQueryParamsContextProvider";
import ConfigurationTableController from "./ConfigTableController";
import useQueryConfiguration from "../../hooks/useQueryConfiguration";
import { generateConfigColumns, queryResultToTableData } from "./config-table.util";
import UploadConfigModal from "./modals/UploadConfigModal";
import useConfiguration from "../../hooks/useConfiguration";

export interface ConfigTableEntry {
  name?: string;
  version?: string;
  description?: string;
  enabled?: boolean;
  addedOn?: string;
  tspId?: number
}
type ConfigModalName = "DELETE" | "UPLOAD" | "TOOL";
export interface ConfigModalData {
  configuration?: ConfigTableEntry[];
  tspId?: number;
}

function ConfigurationTable() {
  const { queryParams, setPageIndex } = useConfigQueryParamsContext();
  const { data, isError, isLoading, isFetching } = useQueryConfiguration(queryResultToTableData);
  const { activeTsp } = useTspFleetContext();
  const { muteEnableDisableConfig: enableOrDisable } = useConfiguration();
  const [selectedRows, setSelectedRows] = useState<Row<ConfigTableEntry>[]>([]);

  const selectedConfiguration = useMemo(() => selectedRows.map((row) => row.original), [selectedRows]);
  const onStatusChanged = useCallback((row: GetDeviceConfig, isActive: boolean) => {
    enableOrDisable({ name: row.name!, version: row.version!, tspId: row.tspId!, enabled: isActive })

  }, [enableOrDisable]);
  const columns = useMemo(() => generateConfigColumns(onStatusChanged), [onStatusChanged]);

  const [modalState, dispatch] = useReducer(modalReducer<ConfigModalName, ConfigModalData>, modalInitialState);
  const userRole = useAuthContext().loggedUser?.role;

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        {userRole !== Role.FleetUser && (
          <ConfigurationTableController
            configCount={data?.totalCount}
            disable={{
              upload: !activeTsp?.id,
              delete: selectedConfiguration.length === 0 || !selectedConfiguration[0].tspId,
              tool: true,
            }}
            onClickDelete={() =>
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "DELETE", data: { configuration: selectedConfiguration } },
              })
            }
            onClickUpload={() => {
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "UPLOAD", data: { tspId: activeTsp!.id! } },
              });
            }}
            onClickConfigurator={() =>
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "TOOL", data: { configuration: selectedConfiguration, tspId: activeTsp!.id! } },
              })
            }
          />
        )}
        <Table<ConfigTableEntry>
          data={data?.data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${styles.tr} ${row.isSelected ? styles["selected-row"] : ""}`,
            td: () => styles.td,
          }}
          isLoading={isLoading}
          isError={isError}
          onChangeSelectedRows={setSelectedRows}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      />
      <DeleteConfigurationModal
        show={modalState.shownModal === "DELETE"}
        onClickNo={() => dispatch({ type: "CLEAR" })}
        onClickYes={() => dispatch({ type: "CLEAR" })}
        configData={modalState.data?.configuration!}
      />
      <UploadConfigModal
        show={modalState.shownModal === "UPLOAD"}
        handleClose={() => dispatch({ type: "CLEAR" })}
      />
    </div>
  );
}

export default ConfigurationTable;
