/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AddSP, GetListResponseSP, SP, SPWithoutId, UserProfile } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class SpManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Add a new SP  the middleware.
   *
   * @tags SP Management
   * @name AddSp
   * @summary Add a new SP
   * @request POST:/sp
   * @secure
   */
  addSp = (data: AddSP, params: RequestParams = {}) =>
    this.request<SP, void>({
      path: `/sp`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description The API retrieves a list of SPs provisioned on the middleware. Each item in the list includes details about the SP , such as the SP  id, last event, etc.
   *
   * @tags SP Management
   * @name GetSpList
   * @summary Get list of registered SPs
   * @request GET:/sp
   * @secure
   */
  getSpList = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseSP, void>({
      path: `/sp`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update the data of the SP .
   *
   * @tags SP Management
   * @name UpdateSp
   * @summary Update a SP 's data
   * @request PUT:/sp/{tspId}
   * @secure
   */
  updateSp = (tspId: number, data: SPWithoutId, params: RequestParams = {}) =>
    this.request<SP, void>({
      path: `/sp/${tspId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Remove a SP  from the middleware registry.
   *
   * @tags SP Management
   * @name DeleteSp
   * @summary Delete a SP
   * @request DELETE:/sp/{tspId}
   * @secure
   */
  deleteSp = (tspId: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/sp/${tspId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve basic information about the SP
   *
   * @tags SP Management
   * @name GetSp
   * @summary Retrieve SP's information
   * @request GET:/sp/{tspId}
   * @secure
   */
  getSp = (tspId: number, params: RequestParams = {}) =>
    this.request<SP, void>({
      path: `/sp/${tspId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update the data of the SP .
   *
   * @tags SP Management
   * @name UpdateProfile
   * @summary Update a SP 's data
   * @request POST:/sp/{tspId}/profile
   * @secure
   */
  updateProfile = (tspId: number, data: UserProfile, params: RequestParams = {}) =>
    this.request<UserProfile, void>({
      path: `/sp/${tspId}/profile`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
