import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PostUser } from "../api/data-contracts";
import { useToastContext } from "../context/ToastContext";
import {
  addUserMutation,
  deleteUserMutation,
  putProfileMutation,
  setPasswordMutation,
  changePasswordMutation,
  updateUserMutation,
} from "../mutations/user.mutation";

interface UseUserProps {
  onSuccessAddUser?: () => void;
  onSuccessUpdateUser?: () => void;
  onSuccessSetPassword?: () => void;
  onSuccessChangePassword?: () => void;
  onSuccessPutProfile?: () => void;
}

function useUser({
  onSuccessSetPassword,
  onSuccessChangePassword,
  onSuccessAddUser,
  onSuccessPutProfile,
  onSuccessUpdateUser,
}: UseUserProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();
  const { mutate: mutateAddUser, isLoading, isError } = useMutation(addUserMutation());
  const { mutate: mutateUpdateUser } = useMutation(updateUserMutation());
  const { mutate: mutateDeleteUser } = useMutation(
    deleteUserMutation({
      // https://tanstack.com/query/v4/docs/react/guides/mutations#consecutive-mutations
      onSuccess: (_, { email }) => {
        addToast({ type: "POSITIVE", content: `User ${email} deleted Successfully` });
      },
      onError: (_, { email }) => {
        addToast({
          type: "NEGATIVE",
          content: `Failed deleting user ${email}`,
        });
      },
    })
  );

  async function addUser(newUser: PostUser, email: string): Promise<void> {
    mutateAddUser(
      { user: newUser, email },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["users"] });
          addToast({ type: "POSITIVE", content: `User add Success` });
          onSuccessAddUser?.();
        },
        onError: (error) => {
          let { response } = error;
          let data = response?.data as any;
          let message = data?.Exception?.Message;
          addToast({ type: "NEGATIVE", content: `User add Failed ${message ? message : ""}` });
        },
      }
    );
  }

  async function updateUser(user: PostUser, email: string): Promise<void> {
    mutateUpdateUser(
      { user, email },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["users"] });
          addToast({ type: "POSITIVE", content: `User edit Success` });
          onSuccessUpdateUser?.();
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `User edit Failed` });
        },
      }
    );
  }

  const { mutate: setPassword, isLoading: isLoadingSetPassword } = useMutation(
    setPasswordMutation({
      onSuccess: (response) => {
        addToast({ type: "POSITIVE", content: "Set password successfully" });
        onSuccessSetPassword?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed setting password" });
      },
    })
  );

  const { mutate: changePassword, isLoading: isLoadingChangePassword } = useMutation(
    changePasswordMutation({
      onSuccess: (response) => {
        addToast({ type: "POSITIVE", content: "Changed password successfully" });
        onSuccessChangePassword?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed changing password" });
      },
    })
  );

  async function deleteUsers(emails: string[]): Promise<void> {
    for (let index = 0; index < emails.length; index++) {
      await mutateDeleteUser(
        { email: emails[index] },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            //refresh tsp and fleet for case of deleeting a manager
            queryClient.invalidateQueries({ queryKey: ["tsp"] });
            queryClient.invalidateQueries({ queryKey: ["fleet"] });
          },
        }
      );
    }
  }

  const { mutate: putProfile, isLoading: isLoadingPutProfile } = useMutation(
    putProfileMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["user"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        addToast({ type: "POSITIVE", content: "Applied settings successfully" });
        onSuccessPutProfile?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed applying settings" });
      },
    })
  );

  return {
    addUser,
    updateUser,
    setPassword,
    changePassword,
    isLoadingChangePassword,
    isLoadingSetPassword,
    deleteUsers,
    putProfile,
    isLoadingPutProfile,
    isLoading,
    isError,
  };
}

export default useUser;
