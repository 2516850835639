import { Button, Stack } from "react-bootstrap";
import { useFormattedLocalDate } from "../../../hooks/useFormattedLocalDate";
import { FormattedMessage } from "react-intl";
import { ReactComponent as TripsIcon } from "../../../assets/icons/Trip.svg";
import { Color } from "../../../constants";

export type TripItem = {
  tripId?: number;
  date?: string;
  driverName?: string;
  from?: string;
  to?: string;
  totalSafetyEvents?: number;
};

type TripsListProps = {
  items?: TripItem[];
  onClickOnTripView: (tripId: number)=> void
};

export default function TripsList({ items = [],onClickOnTripView }: TripsListProps) {
  const { getFormattedLocalDate } = useFormattedLocalDate();
   
  return (
    <div className="overflow-auto">
      {items.map(({ tripId, date, driverName, from, to, totalSafetyEvents }, index) => (
        <>
          <p className="d-flex justify-content-between m-0 w-100">
            <strong>{getFormattedLocalDate(date ?? "") ?? "--"}</strong>
            {/* <Link
                to={`../${RouteConstants.TRIPS}`}
                state={{ tripId: tripId }}
                style={{ color: Color.CIPIA_BLUE, textDecoration: "underline" }}
              >
                <TripsIcon />
                <FormattedMessage id="TRIP_" defaultMessage="All events for this vehicle" />
              </Link>
             */}
            <Button variant="link" onClick={()=> onClickOnTripView(tripId!)} className="bg-transparent border-0 text-dark" key={`driver-${tripId}-${index}`}>
              <TripsIcon />
              <span style={{ color: Color.CIPIA_BLUE, marginLeft: "0.25rem", textDecoration: "underline" }}>
                Trip view
              </span>
            </Button>

          </p>
          <Stack direction="horizontal" className="align-items-center border-bottom px-1 py-2 ">
            <Stack className="w-50">
              <div className="d-flex">
                <p className="m-0 d-flex w-50">
                  <FormattedMessage id={index === 0 && !to ? "CURRENT_TRIP" : "TRIP"} />:
                </p>
                <p> {tripId ?? "--"}</p>
              </div>
              <div className="d-flex">
                <p className="m-0 d-flex w-50">
                  <FormattedMessage id="DRIVER" />:
                </p>
                <p> {driverName ?? "--"}</p>
              </div>
              <div className="d-flex">
                <p className="m-0 d-flex w-50">
                  <FormattedMessage id="SAFETY_EVENTS" />:
                </p>
                <p> {totalSafetyEvents ?? "--"}</p>
              </div>
            </Stack>
            <Stack className="w-50 justify-content-center">
              <div className="d-flex">
                <p className="m-0 d-flex w-25">
                  <FormattedMessage id="FROM" />:
                </p>
                <p> {from ?? "--"}</p>
              </div>
              <div className="d-flex">
                <p className="m-0 d-flex w-25">
                  <FormattedMessage id="TO" />:
                </p>
                <p> {to ?? "--"}</p>
              </div>
            </Stack>
          </Stack>
        </>
      ))}
    </div>
  );
}
