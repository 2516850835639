import React, { PropsWithChildren, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { Constants } from "../constants";
import { UserWithMoreData } from "../api/data-contracts";
import { useQuery } from "@tanstack/react-query";
import { userQuery } from "../queries/user.query";

export type AuthState = {
  token: string;
  email: string;
  username: string;
  termsApproveDate: string | null | undefined;
};

export type LoggedUser = UserWithMoreData;

interface IAuthContext {
  authState: AuthState | undefined;
  loggedUser: LoggedUser | undefined;
  set: (state: AuthState) => void;
  remove: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
  authState: undefined,
  set: () => {},
  remove: () => {},
  loggedUser: undefined,
});

export function AuthContextProvider({ children }: PropsWithChildren<{}>) {
  const [authState, set, remove] = useLocalStorage<AuthState>(Constants.AuthStorageKey);
  const { data } = useQuery(userQuery(authState?.email ?? "", !!authState?.email));

  return (
    <AuthContext.Provider value={{ authState, loggedUser: data?.data, set, remove }}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
