import { useQuery } from "@tanstack/react-query";
import { fleetQuery } from "../queries/fleets.query";
import { useAuthContext } from "../context/AuthContext";
import { getIsFleetRole } from "../util/roles.util";

function useQueryOwnFleet() {
  const { loggedUser } = useAuthContext();
  const isFleetRole = !!loggedUser?.role && getIsFleetRole(loggedUser.role);
  const fleetId = loggedUser?.fleetId;

  const { data, isLoading, isError, isFetching } = useQuery(fleetQuery(fleetId!, isFleetRole && !!fleetId));

  return { data, isLoading, isError, isFetching };
}

export default useQueryOwnFleet;
