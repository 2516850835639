import { Navigate, RouteObject } from "react-router-dom";
import EventsTable from "../../components/EventsTable/EventsTable";
import FleetView from "../../components/FleetView/FleetView";
import { RouteConstants } from "../../constants";
// import UsersView from "../../components/UsersView/UsersView";
import DevicesTable from "../../components/DevicesTable/DevicesTable";
import ManagementNavbar from "../../components/ManagementNavbar/ManagementNavbar";
import RefreshLastUpdate from "../../components/RefreshLastUpdate/RefreshLastUpdate";
import SettingsView from "../../components/SettingsView/SettingsView";
import TripsView from "../../components/TripsView/TripsView";
import UserTable from "../../components/UsersTable/UsersTable";
import VehiclesTable from "../../components/VehiclesTable/VehiclesTable";
import { FleetsLayoutProvider } from "../../context/FleetsLayoutContext";
import { DevicesQueryParamsContextProvider } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import { EventsQueryParamsContextProvider } from "../../context/QueryParamsContext/EventsQueryParamsContextProvider";
import { UsersQueryParamsContextProvider } from "../../context/QueryParamsContext/UsersQueryParamsContextProvider";
import { VehiclesQueryParamsContextProvider } from "../../context/QueryParamsContext/VehiclesQueryParamsContextProvider";
import { DriversQueryParamsContextProvider } from "../../context/QueryParamsContext/DriversQueryParamsContextProvider";
import FleetsLayout from "../../layouts/FleetsLayout/FleetsLayout";
import HeaderLayout from "../../layouts/MainLayout/HeaderLayout";
import DriversTable from "../../components/DriversTable/DriversTable";
import ConfigurationTable from "../../components/ConfigurationTable/ConfigTable";
import BilingTable from "../../components/Biling/BillingTable";
import { ConfigQueryParamsContextProvider } from "../../context/QueryParamsContext/ConfigQueryParamsContextProvider";
import { BillingQueryParamsContextProvider } from "../../context/QueryParamsContext/BillingQueryParamsContextProvider";


export const fleetsRoutes: RouteObject[] = [
  {
    element: <HeaderLayout middleComponent={<RefreshLastUpdate />} />,
    path: RouteConstants.EVENTS_ON_MAP,
    children: [
      {
        element: (
          <FleetsLayoutProvider>
            <FleetsLayout />
          </FleetsLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <FleetView />,
          },
          {
            path: RouteConstants.TRIPS,
            element: <TripsView />,
          },
        ],
      },
    ],
  },
];

export const eventsRoutes: RouteObject[] = [
  {
    element: <HeaderLayout middleComponent={<RefreshLastUpdate />} />,
    children: [
      {
        path: RouteConstants.EVENTS_LIST,
        element: (
          <EventsQueryParamsContextProvider>
            <EventsTable />
          </EventsQueryParamsContextProvider>
        ),
      },
    ],
  },
];

export const managementRoutes: RouteObject[] = [
  {
    element: <HeaderLayout middleComponent={<ManagementNavbar />} />,
    path: RouteConstants.MANAGEMENT,
    children: [
      {
        index: true,
        element: <Navigate to={`/${RouteConstants.MANAGEMENT}/${RouteConstants.USERS}`} replace />,
      },
      {
        path: RouteConstants.USERS,
        element: (
          <UsersQueryParamsContextProvider>
            <UserTable />
          </UsersQueryParamsContextProvider>
        ),
      },
      {
        path: RouteConstants.DEVICES,
        element: (
          <DevicesQueryParamsContextProvider>
            <DevicesTable />
          </DevicesQueryParamsContextProvider>
        ),
      },
      {
        path: RouteConstants.DRIVERS,
        element: (
          <DriversQueryParamsContextProvider>
            <DriversTable />
          </DriversQueryParamsContextProvider>
        ),
      },
      {
        path: RouteConstants.VEHICLES,
        element: (
          <VehiclesQueryParamsContextProvider>
            <VehiclesTable />
          </VehiclesQueryParamsContextProvider>
        ),
      },
      {
        path: RouteConstants.SETTINGS,
        element: (
          <UsersQueryParamsContextProvider>
            <SettingsView />
          </UsersQueryParamsContextProvider>
        ),
      },
      {
        path: RouteConstants.CONFIGURATION,
        element: (
          <ConfigQueryParamsContextProvider>
            <ConfigurationTable />
          </ConfigQueryParamsContextProvider>
        ),
      },
      {
        path: RouteConstants.BILLING,
        element: (
          <BillingQueryParamsContextProvider>
            <BilingTable />
          </BillingQueryParamsContextProvider>
        ),
      },
    ],
  },
];
