import { FormattedMessage } from "react-intl";
import styles from "./SettingsView.module.scss";
import { Button, Form } from "react-bootstrap";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";
import { useForm, useWatch } from "react-hook-form";
import { dateUtils } from "../../util/date.util";
import { ReactComponent as SecurityIcon } from "../../assets/icons/Security.svg";
import { StandardOption } from "../ReactSelect/ReactSelect";
import useUser from "../../hooks/useUser";
import { LoggedUser, useAuthContext } from "../../context/AuthContext";
import { DateTimeFormat as BackDtFormat, Fleet, Measurement, Role } from "../../api/data-contracts";
import { getFrontDtFormat } from "../../util/date.util";
import ModalYesNo from "../ModalYesNo/ModalYesNo";

import { useEffect, useState } from "react";
import { ReactComponent as NoticeIcon } from "../../assets/icons/NoticeIcon.svg";
import ChangePasswordModal from "./ChangePasswordModal";
import { LangNamesType, useIntlContext } from "../../context/IntlContext";
import useQueryOwnFleet from "../../hooks/useQueryOwnFleet";
import useTsp from "../../hooks/useTsp";
import useFleet from "../../hooks/useFleet";
import { Tsp, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { Constants } from "../../constants";

type SettingsFormInputs = {
  language: StandardOption;
  isImperialSystem: boolean;
  format: { label: string; value: BackDtFormat };
};

type UpdateType = "USER" | "FLEET" | "TSP";

const dateTime = new Date();

function SettingsView() {
  const { getLangNames } = useIntlContext();
  const { loggedUser } = useAuthContext();
  const role = loggedUser?.role;
  const { data: ownFleet } = useQueryOwnFleet();
  const { activeTsp } = useTspFleetContext();
  const updateType: UpdateType =
    role === Role.SpManager || role === Role.SpUser ? "TSP" : role === Role.FleetManager ? "FLEET" : "USER";

  const { control, handleSubmit, reset } = useForm<SettingsFormInputs>({
    defaultValues: getFormDefaults(loggedUser, activeTsp, ownFleet, getLangNames),
  });

  useEffect(() => {
    reset(getFormDefaults(loggedUser, activeTsp, ownFleet, getLangNames))
  }, [loggedUser, activeTsp, ownFleet, getLangNames, reset])
  const { format, isImperialSystem } = useWatch({ control });

  const { putProfile, isLoadingPutProfile } = useUser({ onSuccessPutProfile: () => setShowValidationModal(false) });
  const { updateTspProfile, isLoadingUpdateTspProfile } = useTsp({
    onSuccessUpdateProfile: () => setShowValidationModal(false),
  });
  const { updateFleetProfile, isLoadingUpdateFleetProfile } = useFleet({
    onSuccessUpdateProfile: () => setShowValidationModal(false),
  });

  const [showValidationModal, setShowValidationModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const isOrgSettings = updateType === "TSP" || updateType === "FLEET";
  const isLoading =
    updateType === "USER"
      ? isLoadingPutProfile
      : updateType === "FLEET"
      ? isLoadingUpdateFleetProfile
      : isLoadingUpdateTspProfile;
  const isDisabled =
    !role || (updateType === "USER" ? !loggedUser?.email : updateType === "FLEET" ? !ownFleet?.id : !activeTsp?.id);

  const onSubmit = async ({ format, isImperialSystem, language }: SettingsFormInputs) => {
    const dtFormat = format.value;
    const measurement = isImperialSystem ? Measurement.Imperial : Measurement.Metric;
    const isoLang = language.value;

    if (updateType === "USER") {
      putProfile({
        userEmail: loggedUser?.email!,
        dateTimeFormat: dtFormat,
        isoLanguage: isoLang,
        measurement: measurement,
      });
    } else if (updateType === "FLEET") {
      updateFleetProfile({
        id: ownFleet?.id!,
        ...ownFleet,
        dateTimeFormat: dtFormat,
        isoLanguage: isoLang,
        measurement: measurement,
      });
    } else {
      updateTspProfile({
        id: activeTsp?.id!,
        dateTimeFormat: dtFormat,
        isoLanguage: isoLang,
        measurement: measurement,
      });
    }
  };

  return (
    <div className={`d-flex justify-content-center ${styles.container}`}>
      <Form className={styles["form-container"]}>
        <h3>
          <FormattedMessage id="DATE_AND_REGION" defaultMessage="Date and Region" />
        </h3>
        <FormattedMessage id="DATE_AND_TIME_SET" defaultMessage="Date and time set as user computer" />
        <div className="d-flex flex-column gap-3">
          <FormGroup
            label={<FormattedMessage id="SELECTED_LANGUAGE" defaultMessage="Selected Lanugage" />}
            input={
              <FormInput
                type="select"
                name="language"
                control={control}
                rules={{ required: true }}
                input={{
                  options: getLangNames()!.map((l) => ({ label: l.long, value: l.short })),
                  isDisabled: isLoading,
                }}
              />
            }
          />
          <div className={`d-flex justify-content-between gap-2`}>
            <FormattedMessage id="MEASUREMENTS_SYSTEM" defaultMessage="Measurements System" />
            <div className={`d-flex justify-content-end gap-2`} style={{ height: "1.5rem" }}>
              <FormattedMessage id="METRIC" defaultMessage="Metric" />
              <FormInput type="switch" name="isImperialSystem" control={control} input={{ disabled: isLoading }} />
              <FormattedMessage id="IMPERIAL" defaultMessage="Imperial" />
            </div>
          </div>
          <FormGroup
            label={<FormattedMessage id="SELECTED_TIME_FORMAT" defaultMessage="Selected Time Format" />}
            input={
              <FormInput
                type="select"
                name="format"
                control={control}
                rules={{ required: true }}
                input={{
                  options: Object.values(BackDtFormat).map((backDt) => ({
                    value: backDt,
                    label: dateUtils.getFormattedLocalDate(dateTime, getFrontDtFormat(backDt)?.timeDate),
                  })),
                  isDisabled: isLoading,
                }}
              />
            }
          />
        </div>
        <div className={`d-flex flex-column gap-2 p-3 ${styles["format-selected-display"]}`}>
          <div style={{ fontWeight: "600" }}>
            <FormattedMessage id="FORMAT_SELECTED" defaultMessage="Format selected Display: " />
          </div>
          <div>
            <FormattedMessage id="SPEED_UNIT" defaultMessage="Speed Unit" />:{isImperialSystem ? " Mi/H" : " Km/H"}
          </div>
          <div>
            <FormattedMessage id="DATE_FORMAT" defaultMessage="Date Format" />:
            {` ${dateUtils.getFormattedLocalDate(dateTime, getFrontDtFormat(format?.value)?.date)}`}
          </div>
          <div>
            <FormattedMessage id="TIME" defaultMessage="Time" />:
            {` ${dateUtils.getFormattedLocalDate(dateTime, getFrontDtFormat(format?.value)?.time)}`}
          </div>
        </div>
        <Button
          type="button"
          className="w-100"
          variant="outline-primary"
          disabled={isLoading || isDisabled}
          onClick={() => setShowValidationModal(true)}
        >
          {isOrgSettings ? (
            <FormattedMessage id="APPLY_SETTINGS_ORG" defaultMessage="Apply Settings to the all Organization" />
          ) : (
            <FormattedMessage id="APPLY_SETTINGS" defaultMessage="Apply settings" />
          )}
        </Button>
        <div className="d-flex align-items-center gap-2 py-3">
          <div className="p-1 bd-highlight">
            <SecurityIcon />
          </div>
          <div style={{ fontSize: "1.5rem" }}>
            <FormattedMessage id="SECURITY" defaultMessage="Security" />
          </div>
          <Button variant="link" onClick={() => setShowPasswordModal(true)}>
            <FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change Password" />
          </Button>
        </div>
      </Form>
      <ModalYesNo
        show={showValidationModal}
        onClickYes={handleSubmit(onSubmit)}
        onClickNo={() => setShowValidationModal(false)}
        onHide={() => setShowValidationModal(false)}
        isLoading={isLoading}
        body={
          <div className="d-flex flex-column align-items-center gap-4">
            <NoticeIcon />
            {isOrgSettings ? (
              <FormattedMessage
                id="SETTINGS.APPLY_ALL_QUESTION"
                defaultMessage="Are you sure you want to apply this settings to your entire organization?"
              />
            ) : (
              <FormattedMessage
                id="SETTINGS.APPLY_QUESTION"
                defaultMessage="Are you sure you want to apply this settings?"
              />
            )}
          </div>
        }
      />
      <ChangePasswordModal show={showPasswordModal} handleClose={() => setShowPasswordModal(false)} />
    </div>
  );
}

export default SettingsView;

function getFormDefaults(
  user: LoggedUser | undefined,
  tsp: Tsp | undefined,
  fleet: Fleet | undefined,
  getLangNames: () => LangNamesType[]
): SettingsFormInputs {
  let profile = user?.profile;
  let role = user?.role;
  let language = undefined;
  let isImperialSystem: boolean = false;
  let dtFormat = undefined;
  const nowDate = new Date();

  language = {
    value: profile?.isoLanguage,
    label: getLangNames()?.find((lang) => lang.short === profile?.isoLanguage)?.long,
  };
  isImperialSystem = profile?.measurement === Measurement.Imperial;
  dtFormat = {
    value: profile?.dateTimeFormat,
    label: dateUtils.getFormattedLocalDate(nowDate, getFrontDtFormat(profile?.dateTimeFormat)?.timeDate),
  };
  if (!user?.profile?.dateTimeFormat) {
    switch (role) {
      case Role.SpManager:
      case Role.SpUser:
        language = {
          value: tsp?.defaultLanguage,
          label: getLangNames()?.find((lang) => lang.short === tsp?.defaultLanguage)?.long,
        };
        isImperialSystem = tsp?.defaultMeasurement === Measurement.Imperial;
        dtFormat = {
          value: tsp?.defaultDateTimeFormat,
          label: dateUtils.getFormattedLocalDate(nowDate, getFrontDtFormat(tsp?.defaultDateTimeFormat)?.timeDate),
        };
        break;
      case Role.FleetManager:
      case Role.FleetUser:
        language = {
          value: fleet?.defaultLanguage,
          label: getLangNames()?.find((lang) => lang.short === fleet?.defaultLanguage)?.long,
        };
        isImperialSystem = fleet?.defaultMeasurement === Measurement.Imperial;
        dtFormat = {
          value: fleet?.defaultDateTimeFormat,
          label: dateUtils.getFormattedLocalDate(nowDate, getFrontDtFormat(fleet?.defaultDateTimeFormat)?.timeDate),
        };
        break;
    }
  }
  if (!language.label || !language.value) {
    language = { label: getLangNames()[0].long, value: getLangNames()![0].short };
  }
  if (!dtFormat.label || !dtFormat.value) {
    dtFormat = {
      label: dateUtils.getFormattedLocalDate(nowDate, Constants.DEFAULT_FRONT_DT_FORMAT.timeDate),
      value: Constants.DEFAULT_BACK_DT_FORMAT,
    };
  }

  return {
    language: { label: language.label!, value: language.value! },
    isImperialSystem,
    format: { label: dtFormat.label, value: dtFormat.value! },
  };
}
