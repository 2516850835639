import { Outlet, useLocation } from "react-router-dom";
import CircleButton from "../../components/Buttons/CircleButton/CircleButton";
import EventMap from "../../components/EventMap/EventMap";
import LastEventWindow from "../../components/FleetView/LastEvent/LastEventWindow";
import { useFleetsLayoutContext } from "../../context/FleetsLayoutContext";
import { RouteConstants } from "../../constants";

export default function FleetsLayout() {
  const {
    isMapExpanded,
    showEventInfo,
    toggleMapExpand,
    setShowEventInfo,
    selectedDataIndex,
    mapEvents,
    selectedEvent,
  } = useFleetsLayoutContext();

  const { pathname } = useLocation();

  const isFleetView = pathname === `/${RouteConstants.EVENTS_ON_MAP}`;
  const isTripsView = pathname === `/${RouteConstants.EVENTS_ON_MAP}/${RouteConstants.TRIPS}`;

  return (
    <div className="d-flex h-100 w-100">
      {/* Left Panel */}
      <div
        style={{
          position: "relative",
          width: !isMapExpanded ? "28.333vw" : "2rem",
          transition: "width 0.4s",
        }}
      >
        <div style={{ visibility: !isMapExpanded ? "visible" : "hidden" }} className="h-100">
          <Outlet />
        </div>
        <CircleButton opened={!isMapExpanded} onClick={toggleMapExpand} top="37vh" />
      </div>

      {/* Event info panel */}
      {showEventInfo && selectedEvent && (
        <LastEventWindow
          style={{
            width: "28.333vw",
            maxHeight: "calc(100vh - 7.5rem)",
            overflowY: "auto",
          }}
          onClose={() => setShowEventInfo(false)}
          event={selectedEvent}
        />
      )}

      {/* Map - Right Panel */}
      <div className="d-flex flex-grow-1">
        <EventMap
          events={mapEvents ?? []}
          selectedId={selectedDataIndex}
          showEventsFilter={isFleetView}
          showTripPath={isTripsView}
        />
      </div>
    </div>
  );
}
