import BaseButton, { BaseButtonProps } from "../BaseButton/BaseButton";
import styles from "./ActionButton.module.scss";

function ActionButton({ className, ...props }: BaseButtonProps) {
  return (
    <BaseButton
      className={`${styles.btn} ${props.disabled ? styles.disabled : ""} ${className ?? ""}`}
      {...props}
    ></BaseButton>
  );
}

export default ActionButton;
