import { SVGProps } from "react";
import { Color } from "../../constants";

interface ChevronWithLineProps {
  direction?: "LEFT" | "RIGHT";
  disabled?: boolean;
}

function ChevronWithLine({
  direction = "RIGHT",
  disabled,
  ...props
}: ChevronWithLineProps & SVGProps<SVGSVGElement>) {
  const rotation = direction === "RIGHT" ? 0 : 180;
  return (
    <svg
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      transform={`rotate(${rotation})`}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.04803 19.9165L6.79907 18.6675L12.5501 12.9165L6.79907 7.16547L8.04803 5.9165L15.048 12.9165L8.04803 19.9165Z"
        fill={disabled ? Color.GRAY_5 : Color.BLACK}
      />
      <rect
        x="15.7009"
        y="5.9165"
        width="1.5"
        height="14"
        fill={disabled ? Color.GRAY_5 : Color.BLACK}
      />
    </svg>
  );
}

export default ChevronWithLine;
