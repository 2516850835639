import { PropsWithChildren, ReactNode } from "react";
import Form from "react-bootstrap/Form";
import styles from "./FormGroup.module.css";

export type FormGroupProps = {
  className?: string;
  label?: ReactNode;
  input: ReactNode;
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl";
  required?: boolean;
  validation?: ReactNode;
  groupDescription?: ReactNode;
};
export function FormGroup({
  label,
  input,
  required,
  validation,
  groupDescription,
  breakpoint = "md",
  className,
}: FormGroupProps) {
  return (
    <Form.Group className={className}>
      <div>
        {label && (
          <Label className="app-form-label" breakpoint={breakpoint}>
            {required && <span className="prim-color">*&nbsp;</span>}
            {label}
          </Label>
        )}
        <span className={`mx-${breakpoint}-1`}></span>
        <div className={styles.input}>{input}</div>
      </div>
      {groupDescription}
      {validation}
    </Form.Group>
  );
}

const Label = ({
  className,
  breakpoint = "md",
  children,
}: PropsWithChildren<{
  className?: string;
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl";
}>) => {
  return (
    <Form.Label
      className={`${styles.label} ${
        className ? className : ""
      } font-md flex-grow-1 mb-2 mb-${breakpoint}-0`}
    >
      {children}
    </Form.Label>
  );
};
