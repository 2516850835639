import CheckboxTree, { Node as TNode } from "react-checkbox-tree";
import "./ExpandingTreeSelectInput.scss";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { Dropdown } from "react-bootstrap";
import MultiSelectInput from "../MultiSelectInput/MultiSelectInput";
import Arrow, { Direction } from "../../../icons/Arrow";
import { Color } from "../../../constants";
import { CSSProperties, ReactNode, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import CheckboxInput from "../../CheckboxInput/CheckboxInput";
import { TextInput } from "../TextInput/TextInput";
import { useIntl } from "react-intl";

interface ExpandingTreeSelectInputProps {
  nodes: TNode[];
  checked: readonly string[];
  onCheck: (checked: string[]) => void;
  expanded: string[];
  onExpand: (checked: string[]) => void;
  inputLabel?: string;
  placeholder?: string;
  width?: string;
  className?: string;
  style?: CSSProperties;
  isLoading?: boolean;
  getLabel: (checkedValue: string) => ReactNode;
}

const ExpandingTreeSelectInput = forwardRef<HTMLDivElement, ExpandingTreeSelectInputProps>(
  (
    {
      nodes,
      checked,
      onCheck,
      expanded,
      onExpand,
      inputLabel,
      placeholder,
      width,
      className,
      style,
      isLoading,
      getLabel,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const [inputStr, setInputStr] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const selectWrapperRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(selectWrapperRef, (e) => !dropDownRef.current?.contains(e.target as Node) && setIsOpen(false));

    useEffect(() => {
      if (checked && checked.length === 0)
        setInputStr('')
    }, [checked])

    var filteredNodes = useMemo(() => {
      var first = nodes![0];
      var allChildren = [...first.children![0].children!, ...first.children![1].children!, ...first.children![2].children!]
      allChildren.forEach((node) => {
        if (node.label?.toString().toLowerCase().includes(inputStr.toLowerCase())) {
          node.className = "";
        }
        else {
          node.className = "d-none"
        }
      })
      if (inputStr) {
        return allChildren;
      }
      return nodes;
    }, [inputStr, nodes])
     
    return (
      <div className={className} style={style} ref={ref}>
        <Dropdown show={isOpen}>
          <div className="input-with-label-container">
            {inputLabel && <div className="input-label">{inputLabel}</div>}
            <div ref={selectWrapperRef} onClick={() => setIsOpen((curr) => !curr)}>
              <MultiSelectInput
                isSearchable={false}
                isLoading={isLoading}
                placeholder={placeholder}
                menuIsOpen={false}
                value={checked.map((value) => ({ label: getLabel(value), value: value }))}
                controlWidth={width}
                controlOnly
              />
            </div>
          </div>
          <Dropdown.Menu ref={dropDownRef} className="expanding-tree-dropdown">
            <div className="px-1">
              <TextInput
                placeholder={formatMessage({
                  id: "SEARCH",
                  defaultMessage: "Search",
                })} 
              value={inputStr} onChange={e => setInputStr(e.target.value)} />
              <CheckboxTree
                nodes={filteredNodes}
                checked={checked as string[]}
                expanded={expanded}
                onCheck={onCheck}
                onExpand={onExpand}
                showNodeIcon={false}
                icons={{
                  expandOpen: <Arrow color={Color.BLACK} direction={Direction.Up} />,
                  expandClose: <Arrow color={Color.BLACK} direction={Direction.Down} />,
                  check: <CheckboxInput checked="FULL" />,
                  halfCheck: <CheckboxInput checked="HALF" />,
                  uncheck: <CheckboxInput checked="EMPTY" />,
                }}
              />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
);

export default ExpandingTreeSelectInput;
