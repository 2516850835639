import { UseMutationOptions } from "@tanstack/react-query";
import { authService } from "../services/authentication.service";
import { AxiosError, AxiosResponse } from "axios";
import { Login, LoginResponse } from "../api/data-contracts";

export const loginMutation = (): UseMutationOptions<
  AxiosResponse<LoginResponse | null>,
  AxiosError,
  Login
> => ({
  mutationKey: ["login"],
  mutationFn: (loginParams: Login) => authService.login(loginParams),
});
