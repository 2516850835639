import { Button } from "react-bootstrap";
import { MediaSource, MediaType } from "../../../api/data-contracts";
import { MediaDetails } from "../../EventsTable/EventsTable";
import { useEffect, useMemo, useState } from "react";
import { eventsService } from "../../../services/events.service";
import Media from "../../Media/Media";
import styles from "./LastEventMedia.module.scss";
import Video from "../../../icons/Video";
import Photo from "../../../icons/Photo";
import { ReactComponent as GreenCircleIcon } from "../../../assets/green_circle.svg";
import { Color } from "../../../constants";

function findSecondaryMedia(media: MediaDetails, mediaArr: MediaDetails[]) {
  return mediaArr.find((m) => m.type === media.type && m.index === media.index && m.source !== media.source);
}
export function sortMedia(media1: MediaDetails, media2: MediaDetails) {
  if (media1.type !== media2.type) {
    return media1.type === MediaType.Video ? -1 : 1;
  } else if (media1.index !== media2.index) {
    return media1.index - media2.index;
  } else {
    return media1.source === MediaSource.CABIN ? -1 : 1;
  }
}

interface LastEventMediaProps {
  mediaArr: MediaDetails[];
  messageId: number;
  deviceId: string;
  eventType?: string;
  subReason?: string
}
/* Component that renders buttons for selecting media (video or image), and on selection shows the media */
function LastEventMedia({ deviceId, messageId, mediaArr, eventType, subReason }: LastEventMediaProps) {
  const sortedMediaArr = useMemo(() => {
    return [...mediaArr].sort(sortMedia);
  }, [mediaArr]);

  const [activeMedia, setActiveMedia] = useState<MediaDetails>(sortedMediaArr[0]);

  useEffect(() => {
    setActiveMedia(sortedMediaArr[0]);
  }, [deviceId, messageId, sortedMediaArr]);

  const secondaryVideo =
    activeMedia.type === MediaType.Video ? findSecondaryMedia(activeMedia, sortedMediaArr) : undefined;

  const url = eventsService.getUrlForMedia({
    messageId,
    deviceId,
    type: activeMedia.type,
    source: activeMedia.source,
    index: activeMedia.index,
  });

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        {sortedMediaArr.map((media) => {
          const isActive = activeMedia === media;
          return (
            <Button
              className={`${styles.btn} ${isActive ? styles.active : ""}`}
              key={media.type + media.source + media.index}
              onClick={() => setActiveMedia(media)}
            >
              <div className="d-flex align-items-center gap-2">
                <GreenCircleIcon fillOpacity={isActive ? "100%" : "0% "} />
                {media.type === MediaType.Video ? (
                  <Video color={isActive ? Color.WHITE : Color.DARK_GRAY_1} />
                ) : (
                  <Photo color={isActive ? Color.WHITE : Color.DARK_GRAY_1} />
                )}
              </div>
            </Button>
          );
        })}
      </div>
      <Media
        width="100%"
        height="fit-content"
        urlMain={url}
        urlSecondary={
          !secondaryVideo
            ? undefined
            : eventsService.getUrlForMedia({
              messageId,
              deviceId,
              type: MediaType.Video,
              source: secondaryVideo.source,
              index: secondaryVideo.index,
            })
        }
        eventType={eventType}
        subReason={subReason}
        mediaDetails={activeMedia}
        style={{ border: "4px solid rgba(55, 156, 216, 1)", minHeight: "13rem" }}
      />
    </div>
  );
}

export default LastEventMedia;
