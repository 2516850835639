import logLevel from "loglevel";

const infoStyle = "color: cyan;";
const errorStyle = "background-color: red; color: white";

type LoggerOptions = {
  source?: string;
  includeStack?: boolean;
  off?: boolean;
};

export default class Logger {
  constructor(private _options?: LoggerOptions) {}

  static log(...optionalParams: any[]) {
    logLevel.debug(`[${new Date().toISOString()}]`, ...optionalParams);
  }

  static info(...optionalParams: any[]) {
    logLevel.info(
      `%c [${new Date().toISOString()}]`,
      infoStyle,
      ...optionalParams
    );
  }

  static error(...optionalParams: any[]) {
    logLevel.error(
      `%c [${new Date().toISOString()}]`,
      errorStyle,
      ...optionalParams
    );
  }

  log(...optionalParams: any[]) {
    const { source, includeStack, off } = this._options ?? {};

    if (off) return;

    logLevel.debug(
      `[${new Date().toISOString()}]${source ? `[${source}]` : ""}`,
      ...optionalParams,
      includeStack ? new Error().stack?.replace("Error", "") : ""
    );
  }

  info(...optionalParams: any[]) {
    const { source, includeStack, off } = this._options ?? {};

    if (off) return;

    logLevel.info(
      `%c [${new Date().toISOString()}][INFO]${source ? `[${source}]` : ""}`,
      infoStyle,
      ...optionalParams,
      includeStack ? new Error().stack?.replace("Error", "") : ""
    );
  }

  error(...optionalParams: any[]) {
    const { source, off } = this._options ?? {};

    if (off) return;

    logLevel.error(
      `%c [${new Date().toISOString()}][ERR]${source ? `[${source}]` : ""}`,
      errorStyle,
      ...optionalParams
    );
  }
}
