import styles from "./CaptureDriver.module.scss";
import { useState } from "react";

import { ReactComponent as CaptureBigIcon } from "../../../assets/CaptureBig.svg";
import DriverDeviceTable from "./DriverVehiclesTable";
import { useQueryDeviceFilteredByDeviceIdOrVehicle } from "../../../hooks/useQueryDevices";
import { devicesToDriverVehicles } from "./driver-vehicles-table.util";
import { useVehiclesQueryParamsContext } from "../../../context/QueryParamsContext/VehiclesQueryParamsContextProvider";
import Pagination from "../../Table/Pagination";
import { SearchBox } from "../../form/SearchBox/SearchBox";
import { addBase64Prefix } from "../../../util/shared.util";
import { useTspFleetContext } from "../../../context/TspFleetContext/TspFleetContext";
import { Fleet } from "../../../api/data-contracts";

interface AddDriverModalProps {
  photo?: string;
  onDeviceSelected: (deviceId?: string) => void;
}

export type AddDriverFormInputs = {
  driverIdName: string;
  photoUrl?: string;
};

function CaptureDriver({ photo, onDeviceSelected }: AddDriverModalProps) {
  const [deviceFilter, setDeviceFilter] = useState<string>();

  const { selected } = useTspFleetContext() ;
  const selectedFleet = selected as Fleet; 
  const {
    data: deviceData,
    isError: isErrorDevices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices,
  } = useQueryDeviceFilteredByDeviceIdOrVehicle(deviceFilter, undefined, selectedFleet && { tspId: selectedFleet.tspId!, fleetId: selectedFleet.id }, devicesToDriverVehicles);
  const { queryParams, setPageIndex } = useVehiclesQueryParamsContext();

  const onRowClicked = (deviceId?: string) => {
    setDeviceFilter("");
    onDeviceSelected(deviceId);
  };
  const photoArea = (
    <div className={`${styles["photo-container"]} flex-fill justify-content-center d-flex`}>
      {!photo && <CaptureBigIcon className={styles["capture-big-icon"]} />}
      {photo && <img src={addBase64Prefix(photo)} className={styles["photo"]} alt="driver" />}
    </div>
  );

  return (
    <div id="captureDriver" className="h-100 d-flex flex-column h-100">
      <SearchBox
        search={deviceFilter}
        defaultPlaceHolderMessage="Type Device Id or Vehicle License plate"
        placeHolderId="SEARCH_DEVICE_BOX"
        onChange={(e) => setDeviceFilter(e.target.value)}
      />
      <>
        {deviceFilter && (
          <>
            <DriverDeviceTable
              data={deviceData?.data}
              isError={isErrorDevices}
              isLoading={isLoadingDevices}
              isFetching={isFetchingDevices}
              onClickRow={(device) => {
                onRowClicked(device?.deviceId);
              }}
            />
            <Pagination
              className="mt-auto"
              debouncedPageIndex={queryParams.paging.pageIndex}
              onChangePageIndex={setPageIndex}
              pageCount={deviceData?.pageCount}
              allDisabled={isFetchingDevices}
              totalEntries={deviceData?.totalCount}
            />
          </>
        )}
      </>
      {!deviceFilter && photoArea}
    </div>
  );
}

export default CaptureDriver;
