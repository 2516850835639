import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";
import EnglishUS from "../i18n/languages/en-US.json";
import Spanish from "../i18n/languages/es-ES.json";
import { IntlProvider } from "react-intl";
import { useProfile } from "../hooks/useProfile";

export type LocaleType = "en-US" | "es-ES";
const initialLocale: LocaleType = "en-US";

const intlMessages: Record<string, Record<string, string>> = {
  "en-US": EnglishUS,
  "es-ES": Spanish
};

export type LangNamesType = { short: LocaleType; long: string };
const langNames: LangNamesType[] = [
  {
    short: "en-US",
    long: "English (US)",
  },
  {
    short: "es-ES",
    long: "Spanish",
  },
  
];

interface IIntlContext {
  changeLanguage: (locale: LocaleType) => void;
  getLangNames: () => LangNamesType[];
  getCurrentLanguage: () => LangNamesType | undefined;
}

const IntlContext = createContext<IIntlContext>({
  changeLanguage: () => {},
  getLangNames: () => [],
  getCurrentLanguage: () => undefined,
});

/* Context for Intl */
export function IntlContextProvider({ children }: PropsWithChildren<{}>) {
  const [locale, setLocale] = useState<LocaleType>(initialLocale);
  const profile = useProfile();

  const changeLanguage = useCallback((newLocale: LocaleType): void => {
    document.documentElement.lang = newLocale;
    setLocale(newLocale);
  }, []);

  useEffect(() => {
    changeLanguage(profile.language)
  }, [profile, changeLanguage]);

  const getLangNames = () => langNames;
  const getCurrentLanguage = () => langNames.find((l) => l.short === locale);

  return (
    <IntlProvider messages={intlMessages[locale]} locale={locale}>
      <IntlContext.Provider value={{ changeLanguage, getLangNames, getCurrentLanguage }}>
        {children}
      </IntlContext.Provider>
    </IntlProvider>
  );
}

export const useIntlContext = () => useContext(IntlContext);
