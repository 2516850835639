import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { IGiveDriverFacePhoto, ITakeDriverFacePhoto, driverManagementService } from "../services/device-driver.service";

export const TakeDriverFacePhotoMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  ITakeDriverFacePhoto
> => ({
  mutationKey: ["takeDriverFacePhoto"],
  mutationFn: (deviceDriver: ITakeDriverFacePhoto) => driverManagementService.takeDriverFacePhoto(deviceDriver)
})

export const GiveDriverFacePhotoMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  IGiveDriverFacePhoto
> => ({
  mutationKey: ["giveDriverFacePhoto"],
  mutationFn: (deviceDriver: IGiveDriverFacePhoto) => driverManagementService.giveDriverFacePhoto(deviceDriver),
})
