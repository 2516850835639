import { useRef, useState } from "react";
import styles from "./SideBarControl.module.css";
import { Button, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import addIconPath from "../../assets/icons/Add.svg";
import AddEditTspFleetModalForm from "../AddEditTspFleetModalForm/AddEditTspFleetModalForm";
import useTsp from "../../hooks/useTsp";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useOnClickOutside } from "usehooks-ts";
import useFleet from "../../hooks/useFleet";
import { SelectedType, Tsp, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { useAuthContext } from "../../context/AuthContext";
import { DateTimeFormat, Measurement, Role } from "../../api/data-contracts";
import { isTsp } from "../../context/TspFleetContext/tsp-fleet-context.util";

function SideBarControl() {
  const dropDownMenuRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  useOnClickOutside(dropDownMenuRef, () => setShowDropdown(false));

  const userRole = useAuthContext().loggedUser?.role;
  const { selected: ctxSelected } = useTspFleetContext();
  const [tspModalShow, setTspModalShow] = useState(false);
  const [fleetModalShow, setFleetModalShow] = useState(false);
  const [modalData, setModalData] = useState<SelectedType>();
  const { addTsp, isLoadingAddTsp } = useTsp({ onSuccessAdd: () => setTspModalShow(false) });
  const { addFleet, isLoadingAddFleet } = useFleet({ onSuccessAdd: () => setFleetModalShow(false) });

  return (
    <div className={`d-flex justify-content-start m-0 ${styles.container}`}>
      <Dropdown id="side-bar-add-dropdown" show={showDropdown}>
        <Button className={styles.ctrl_btn} onClick={() => setShowDropdown(true)}>
          <img src={addIconPath} className={styles.btn_img} alt="add" />
          <span className="p-2">
            <FormattedMessage id="ADD" defaultMessage="Add" />
          </span>
        </Button>
        <DropdownMenu ref={dropDownMenuRef}>
          {userRole === Role.CipiaAdministrator && (
            <Dropdown.Item
              as="button"
              onClick={() => {
                setTspModalShow(true);
                setModalData(ctxSelected);
                setShowDropdown(false);
              }}
            >
              <FormattedMessage id="ADD_TSP" defaultMessage="Add TSP" />
            </Dropdown.Item>
          )}
          {(userRole === Role.CipiaAdministrator || userRole === Role.SpManager || userRole === Role.SpUser) && (
            <Dropdown.Item
              as="button"
              disabled={!ctxSelected || !isTsp(ctxSelected)}
              onClick={() => {
                setFleetModalShow(true);
                setModalData(ctxSelected);
                setShowDropdown(false);
              }}
            >
              <FormattedMessage id="ADD_FLEET" defaultMessage="Add Fleet" />
            </Dropdown.Item>
          )}
        </DropdownMenu>
      </Dropdown>
      {tspModalShow && (
        <AddEditTspFleetModalForm
          show={tspModalShow}
          handleClose={() => !isLoadingAddTsp && setTspModalShow(false)}
          headersTextVariant="TSP"
          isLoading={isLoadingAddTsp}
          onSubmit={async ({
            companyName,
            companyAddress,
            defaultLanguage,
            description,
            fullName,
            image,
            password,
            phone,
            userName,
            
          }) =>
            addTsp({
              companyAddress,
              companyDescription: description,
              companyFriendlyName: companyName,
              logo: image,
              defaultLanguage: defaultLanguage?.value,
              User: { FullName: fullName, PhoneNumber: phone, Email: userName, password },
              defaultDateTimeFormat: DateTimeFormat.DayLongMonthYearTwentyFourHours,
              defaultMeasurement : Measurement.Imperial
            })
          }
        />
      )}
      {fleetModalShow && (
        <AddEditTspFleetModalForm
          show={fleetModalShow}
          handleClose={() => !isLoadingAddFleet && setFleetModalShow(false)}
          headersTextVariant="FLEET"
          isLoading={isLoadingAddFleet}
          onSubmit={async ({
            companyName,
            companyAddress,
            defaultLanguage, 
            description,
            fullName,
            image,
            password,
            phone,
            userName,
          }) =>
            addFleet({
              tspId: (modalData as Tsp).id!,
              companyAddress,
              companyFriendlyName: companyName,
              companyDescription: description,
              defaultLanguage:  defaultLanguage?.value,
              logo: image,
              User: { Email: userName, FullName: fullName, PhoneNumber: phone, password },
            })
          }
        />
      )}
    </div>
  );
}

export default SideBarControl;
