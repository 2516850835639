import { QueryFunctionContext } from "@tanstack/react-query";
import { GetBillingParams, GetBillingResult, billingService } from "../services/billing.service";

export function billingReportQuery<TResult>(
  params: GetBillingParams,
  selector: (queryResult: GetBillingResult) => TResult,
  enabled: boolean
) {
  return {
    queryKey: ["billing", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["billing", GetBillingParams]>) =>
      await billingService.getBillingReport(context.queryKey[1]),
    select: selector,
    keepPreviousData: true,
    enabled: enabled,
  };
}
