import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RouteConstants } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";

export function Protected() {
  const { authState } = useAuthContext();

  const location = useLocation();

  const validUser = Boolean(authState);
  //TODO: when `termsApproveDate` is a part of the token this line should be used.
  // const validUser = Boolean(authState?.termsApproveDate)

  if (!validUser) {
    return <Navigate to={`/${RouteConstants.AUTH}/${RouteConstants.LOGIN}`} state={{ from: location }} />;
  }

  return <Outlet />;
}
