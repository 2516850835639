import { useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";

interface ILastUpdateContext {
  time: string;
  invalidateQueries: () => void;
}

const LastUpdateContext = createContext<ILastUpdateContext>({
  time: new Date().toISOString(),
  invalidateQueries: () => {},
});

export function LastUpdateContextProvider({ children }: PropsWithChildren<{}>) {
  const queryClient = useQueryClient();
  const [time, setTime] = useState<string>(new Date().toISOString());

  const invalidateQueries = useCallback(async () => {
    await queryClient.invalidateQueries();
    setTime(new Date().toISOString());
  }, [queryClient]);

  return <LastUpdateContext.Provider value={{ time, invalidateQueries }}>{children}</LastUpdateContext.Provider>;
}

export const useLastUpdateContext = () => useContext(LastUpdateContext);
