import { AxiosResponse } from "axios";
import { Software } from "../api/Software";
import Logger from "../util/logger.util";
import { VersionInfo } from "../api/data-contracts";

const logger = new Logger({ source: "software Service", off: true });

const softwareApi = new Software();

export const softwareService = {
  getTerms: async (): Promise<AxiosResponse<string, any>> => {
    logger.info(`get terms...`);
    return await softwareApi.termsList();
  },
  getVersions: async (): Promise<VersionInfo> => {
    const result = await softwareApi.versionList();
    return result.data
  },
};
