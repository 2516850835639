import React, { ReactNode } from "react";
import { Button, Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import greenCirclePath from "../../assets/green_circle.svg";
import { FormattedMessage } from "react-intl";

type NavbarButtonProps = {
  path: string;
  buttonClass: string;
  text: string;
  iconRenderer: (isActive: boolean) => ReactNode;
};
const NavbarButton: React.FunctionComponent<NavbarButtonProps> = ({
  path,
  buttonClass,
  text,
  iconRenderer,
}: NavbarButtonProps) => {
  const active = useLocation().pathname.split("/").pop() === path; // get last part of the path

  return (
    <Nav.Link
      as={() => (
        <NavLink to={path}>
          <Button
            size="sm"
            style={{ minWidth: "128px", width: "fit-content" }}
            className={`text-nowrap toggle-button ${buttonClass} ${active ? "active" : ""}`}
          >
            {active && (
              <img className="green-circle" src={greenCirclePath} alt="gs" />
            )}
            {iconRenderer(active)}
            <span style={{ marginLeft: "0.25rem", verticalAlign: "middle" }}>
              {
                <FormattedMessage
                  id={`ManagementLayout.${text}`}
                  defaultMessage={text}
                />
              }
            </span>
          </Button>{" "}
        </NavLink>
      )}
    />
  );
};

export default NavbarButton;
