import { PropsWithChildren, ReactNode } from "react";
import {
    Button as BsButton,
    ButtonProps as BsButtonProps
} from "react-bootstrap";

import styles from "./Button.module.css";

export type ButtonProps = {
  icon?: {
    src: string;
    width?: number;
    height?: number;
  };
  customIcon?: ReactNode;
  bgColor?: string;
  className?: string;
} & BsButtonProps;

export const Button = ({
  bgColor,
  children,
  className,
  icon,
  customIcon,
  style,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const viewIcon = customIcon ? (
    customIcon
  ) : icon ? (
    <img
      src={icon.src}
      alt=""
      width={icon.width ?? 12}
      height={icon.height ?? 12}
    />
  ) : null;

  return (
    <BsButton
      id={styles.button}
      {...rest}
      className={`${className ? className : ""} d-flex align-items-center`}
      style={{ background: bgColor ?? "", ...style }}
    >
      {viewIcon && (
        <>
          {viewIcon}
          {children && <span className={styles.iconDivider}></span>}
        </>
      )}
      {children}
    </BsButton>
  );
};
