import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastContext } from "../context/ToastContext";
import {
  addDriverMutation,
  updateDriverMutation,
  // deleteDriverMutation,
  // addDriverToDevice,
  // removeDriverFromDevice,
  deleteDriverMutation,
  addDriverToDeviceMutation,
  removeDriverFromDeviceMutation
} from "../mutations/driver.mutation";
import {  DeleteDriverParams } from "../services/driver.service";

interface UseDriverProps {
  onSuccessAddDriver?: () => void;
  onSuccessUpdateDriver?: () => void;
  onSuccessDeleteDrivers?: () => void;
  onSuccessAddDriverToDevice?: () => void;
  onSuccessRemoveDriverFromDevice?: () => void;
}

function useDriver({
  onSuccessAddDriver,
  onSuccessUpdateDriver,
  onSuccessDeleteDrivers,
  onSuccessAddDriverToDevice,
  onSuccessRemoveDriverFromDevice,
}: UseDriverProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();
  const { mutate: mutateDeleteDriver } = useMutation(
    deleteDriverMutation({
      onSuccess: (_, { driverId }) => {
        queryClient.invalidateQueries({ queryKey: ["drivers"] });
        addToast({ type: "POSITIVE", content: `Driver ${driverId} deleted Successfully` });
      },
      onError: (_, { driverId }) => {
        addToast({
          type: "NEGATIVE",
          content: `Failed deleting user ${driverId}`,
        });
      },
    })
  );

  const {
    mutate: addDriver,
    isLoading: isLoadingAddDriver,
    isError: isErrorAddDriver,
  }
    = useMutation(addDriverMutation(
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["drivers"] });
          queryClient.invalidateQueries({ queryKey: ["device"] });
          addToast({ type: "POSITIVE", content: `Driver add Success` });
          onSuccessAddDriver?.();
        },
        onError: (error) => {
          let { response } = error;
          let data = response?.data as any;
          let message = data?.Exception?.Message;
          addToast({ type: "NEGATIVE", content: `Driver add Failed ${message ? message : ""}` });
        },
      }
    
  ));

  const {
    mutate: updateDriver,
    isLoading: isLoadingUpdateDriver,
    isError: isErrorUpdateDriver,
  }
    = useMutation(updateDriverMutation(
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["drivers"] });
          addToast({ type: "POSITIVE", content: `Driver edit Success` });
          onSuccessUpdateDriver?.();
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `Driver edit Failed` });
        },
      }
    ));
    const {
      mutate: addDriverToDevice,
      isLoading: IsLoadinAddDriverDevice,
      isError: isErrorAddDriverDevice,
    }
      = useMutation(addDriverToDeviceMutation(
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["drivers"] });
            queryClient.invalidateQueries({ queryKey: ["driverDevices"] });
            queryClient.invalidateQueries({ queryKey: ["device"] });

            addToast({ type: "POSITIVE", content: `Add Driver to device Success` });
            onSuccessAddDriverToDevice?.();
          },
          onError: () => {
            addToast({ type: "NEGATIVE", content: `Add Driver to device Failed` });
          },
        }
      ));

      const {
        mutate: removeDriverFromDevice,
      }
        = useMutation(removeDriverFromDeviceMutation(
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: ["drivers"] });
              queryClient.invalidateQueries({ queryKey: ["driverDevices"] });
              queryClient.invalidateQueries({ queryKey: ["device"] });
              
              addToast({ type: "POSITIVE", content: `Remove Driver from device Success` });
              onSuccessRemoveDriverFromDevice?.();
            },
            onError: () => {
              addToast({ type: "NEGATIVE", content: `Remove Driver from device Failed` });
            },
          }
        ));

      

  async function deleteDrivers(drivers: DeleteDriverParams[]): Promise<void> {
    for (let index = 0; index < drivers.length; index++) {
      await mutateDeleteDriver(      drivers[index],
        {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["drivers"] });
          onSuccessDeleteDrivers?.();
          addToast({ type: "POSITIVE", content: `Driver Delete Success` });
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `Delete Driver Failed` });
        },
        
      })
    }
  }

  return {
    addDriver,
    updateDriver,
    deleteDrivers,
    removeDriverFromDevice,
    addDriverToDevice,
    IsLoadinAddDriverDevice,
    isErrorAddDriverDevice,
    isLoadingUpdateDriver,
    isErrorUpdateDriver,
    isLoadingAddDriver,
    isErrorAddDriver
  };
}

export default useDriver;
 

