import { PropsWithChildren } from "react";
import { DevicesSortFilterParamName, GetDevicesParams } from "../../services/devices.service";
import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { Constants } from "../../constants";
import { DEVICES_CONTEXT } from "./queryParamContextObjects";


export function DevicesQueryParamsContextProvider({ children, initialParams }: PropsWithChildren<{ initialParams?: GetDevicesParams }>) {
  initialParams = initialParams || {
    filters: [],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
    includeInstallationReport: false
  };
  return <QueryParamsContextProvider context={DEVICES_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useDevicesQueryParamsContext = () =>
  useQueryParamsContext<DevicesSortFilterParamName, DevicesSortFilterParamName>(DEVICES_CONTEXT);
