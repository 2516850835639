import { useToastContext } from "../../context/ToastContext";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { Color } from "../../constants";
import { ReactComponent as IconCheckV } from "../../assets/icons/CheckV.svg";
import { ReactComponent as IconExclamationMark } from "../../assets/icons/ExclamationMark.svg";
import { FormattedMessage } from "react-intl";

const FONT_SIZE = "1.5rem";
const FONT_COLOR = Color.WHITE;

/* Renders all toasts from ToastContext (has to be inside ToastContext) */
function ToastRenderer() {
  const { toasts, remove } = useToastContext();

  return (
    <ToastContainer position="bottom-center" style={{ minWidth: "35.5rem", maxWidth: "70rem" }}>
      {toasts.map((toast) => {
        const { content, type, onUndo, id } = toast;
        return (
          <Toast
            key={id}
            style={{
              fontSize: FONT_SIZE,
              fontWeight: "700",
              color: FONT_COLOR,
              width: "100%",
              boxShadow: "0px -6px 10px 0px rgba(0, 0, 0, 0.1)",
              border: "none",
              borderRadius: "6px 6px 0px 0px",
              backgroundColor:
                type === "POSITIVE" ? Color.DARKER_GREEN : Color.RED,
            }}
          >
            <Toast.Body
              className="px-3 d-flex align-items-center gap-2"
              style={{ height: "3.4rem" }}
            >
              {type === "POSITIVE" ? (
                <IconCheckV fill={Color.WHITE} />
              ) : (
                <IconExclamationMark fill={Color.WHITE} />
              )}
              <div>{content}</div>
              {onUndo && (
                <Button
                  onClick={() => {
                    onUndo();
                    remove(id);
                  }}
                  variant="link"
                  style={{
                    fontSize: FONT_SIZE,
                    color: FONT_COLOR,
                  }}
                  className="ms-auto"
                >
                  <FormattedMessage id="UNDO" defaultMessage="Undo" />
                </Button>
              )}
            </Toast.Body>
          </Toast>
        );
      })}
    </ToastContainer>
  );
}

export default ToastRenderer;
