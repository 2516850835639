import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetTripsParams, TripsSortFilterParamName } from "../../services/trips.service";
import { PropsWithChildren } from "react";
import { TRIPS_CONTEXT } from "./queryParamContextObjects";


export function TripsQueryParamsContextProvider({ initialParams,children }: PropsWithChildren<{ initialParams?: GetTripsParams }>) {
   
  initialParams = initialParams || { filters: [], sorts: [], paging: { pageIndex: 1, pageSize: 10 } }

  return <QueryParamsContextProvider context={TRIPS_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useTripsQueryParamsContext = () =>
  useQueryParamsContext<TripsSortFilterParamName, TripsSortFilterParamName>(TRIPS_CONTEXT);
