import { QueryFunctionContext, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { GetTspsParams, GetTspsResult, tspService } from "../services/tsp.service";

export function tspsQuery(
  params: GetTspsParams,
  enabled: boolean
): UseQueryOptions<GetTspsResult, AxiosError, GetTspsResult, readonly ["tsp", GetTspsParams]> {
  return {
    queryKey: ["tsp", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["tsp", GetTspsParams]>) =>
      await tspService.getTsps(context.queryKey[1]),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: enabled,
  };
}
