import { PropsWithChildren } from "react";
import {} from "../../models/requests/shared.request";
import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetDevicesParams } from "../../services/devices.service";
import { Constants } from "../../constants";
import { DEVICES_CONTEXT } from "./queryParamContextObjects";

export function VehiclesQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetDevicesParams = {
    filters: [{ paramName: "hasVehicle", operator: "==", values: ["true"] }],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
    includeInstallationReport: true
  };
  return <QueryParamsContextProvider context={DEVICES_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useVehiclesQueryParamsContext = () => useQueryParamsContext(DEVICES_CONTEXT);
