import styles from "./ConfigTableController.module.css";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Close.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/Upload.svg";
import { ReactComponent as ConfiguratorIcon } from "../../assets/icons/Configurator.svg";
import { Color } from "../../constants";
import ActionButton from "../Buttons/ActionButton/ActionButton";

interface ConfigControllerProps {
  onClickDelete?: () => void;
  onClickUpload: () => void;
  onClickConfigurator?: () => void;
  configCount?: number;
  disable?: {
    delete?: boolean;
    upload?: boolean;
    tool?: boolean;
  };
}

function ConfigTableController({
  onClickDelete,
  onClickUpload,
  onClickConfigurator,
  configCount,
  disable,
}: ConfigControllerProps) {
  return (
    <div className="d-flex align-items-center gap-2 py-3">
      <div className="header-font me-5">
        <FormattedMessage id="CONFIGURATION" defaultMessage="Configuration" />
        {` (${configCount || 0})`}
      </div>
      <ActionButton
        icon={<DeleteIcon
        fill={Color.CIPIA_BLUE} />}
        onClick={onClickDelete}
        disabled={disable?.delete}>
        <FormattedMessage id="DELETE" defaultMessage="Delete" />
      </ActionButton>

      <ActionButton
        icon={<UploadIcon fill={Color.CIPIA_BLUE} />}
        onClick={onClickUpload}
        disabled={disable?.upload}>
        <FormattedMessage id="UPLOAD_NEW_CONFIGURATION" defaultMessage="Upload New Configuration" />
      </ActionButton>

      <ActionButton
        icon={<ConfiguratorIcon fill={Color.CIPIA_BLUE} />}
        onClick={onClickConfigurator}
        disabled={disable?.tool}
      >
        <FormattedMessage id="CONFIGURATOR_TOOL" defaultMessage="Configurator Tool" />
        <span className={styles.coming_soon}>
          <FormattedMessage id="COMING_SOON" defaultMessage="Coming soon" />
        </span>

      </ActionButton>
    </div>
  );
}

export default ConfigTableController;
