import { FormattedMessage } from "react-intl";
import { ReactComponent as DeviceIcon } from "../../../assets/icons/UnpairModal.svg";
import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { Color } from "../../../constants";

interface UnpairModalProps {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
}

function UnpairModal({ show, onClickNo, onClickYes }: UnpairModalProps) {
  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={onClickYes}
      onHide={onClickNo}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <DeviceIcon fill={Color.CIPIA_BLUE} />{" "}
          <FormattedMessage
            id="Vehicles.UNPAIR_QUESTION"
            defaultMessage="Are you sure you want to Unpair this Device?"
          />
        </div>
      }
    />
  );
}

export default UnpairModal;
