/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Error, TermsAndCondition, VersionInfo } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Software<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Returns a JSON object with the Cloud Middleware version and the minimum/maximum API versions supported.<br><br> <u>NOTE:</u> This API does not require authorization.
   *
   * @tags Software
   * @name VersionList
   * @summary Retrieve version information of the Cloud Middleware software
   * @request GET:/version
   * @secure
   */
  versionList = (params: RequestParams = {}) =>
    this.request<VersionInfo, Error>({
      path: `/version`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Software
   * @name TermsList
   * @summary Retrieve Terms and Condition of the Cloud Middleware software
   * @request GET:/terms
   * @secure
   */
  termsList = (params: RequestParams = {}) =>
    this.request<TermsAndCondition, Error>({
      path: `/terms`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
