import { FleetManagement } from "../api/FleetManagement";
import { Fleet, PostFleet } from "../api/data-contracts";
import { FilterParam, QueryParams, ServiceGetResult, SortParam } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { stripBase64Prefix } from "../util/shared.util";
import { sortParamsToString } from "../util/sorting.util";
import { PutProfileParams } from "./tsp.service";

export interface AddFleetData extends PostFleet {
  tspId: number;
}

export type FleetsSortFilterParamName = "tspId" | "fleetId";
export type FleetsSortParam = SortParam<FleetsSortFilterParamName>;
export type FleetsFilterParam = FilterParam<FleetsSortFilterParamName>;

export type GetFleetsParams = QueryParams<FleetsSortParam, FleetsFilterParam>;
export type GetFleetsResult = ServiceGetResult<Fleet[]>;

export type UpdateFleetParams = Fleet & { fleetId: number };
export type DeleteFleetParams = { fleetId: number };

const fleetApi = new FleetManagement();
addAuthHandling(fleetApi);

export const fleetService = {
  addFleet: async ({ tspId, logo, ...rest }: AddFleetData) =>
    fleetApi.addFleet(tspId, { logo: logo ? stripBase64Prefix(logo) : undefined, ...rest }),

  getFleets: async ({ paging, sorts, filters }: GetFleetsParams): Promise<GetFleetsResult> => {
    const result = await fleetApi.getFleets({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging.pageIndex,
      pageSize: paging.pageSize,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }

    return {
      data: result.data.data,
      pageCount,
    };
  },
  deleteFleet: async ({ fleetId }: DeleteFleetParams) => fleetApi.deleteFleet(fleetId),
  updateFleet: async ({ fleetId, logo, ...rest }: UpdateFleetParams) =>
    fleetApi.updateFleet(fleetId, { logo: logo ? stripBase64Prefix(logo) : undefined, ...rest }),

  updateProfile: async ({ id, ...rest }: PutProfileParams) => fleetApi.updateFleetProfile(id, rest),
  getFleet: async (fleetId: number) => (await fleetApi.getFleet(fleetId)).data,
};
