/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DriverName {
  name?: string;
}

export interface DriverNames {
  drivers?: DriverName[];
}

export interface VehicleConfig {
  /**
   * name of config
   * @example "volvo FH 2015 - the blue version"
   */
  friendlyName?: string;
  /**
   * manufacturer of car
   * @example "volvo"
   */
  manufacturer?: string;
  /**
   * model of car
   * @example "FH"
   */
  model?: string;
  /**
   * year of model
   * @min 1970
   * @example 2015
   */
  year?: number;
  /**
   * offset of installation (in cm)
   * @format double
   * @min -100
   * @max 100
   * @example -44.3
   */
  offset?: number;
  /**
   * height of installation (in cm)
   * @format double
   * @min 50
   * @max 500
   * @example 60.5
   */
  height?: number;
}

export interface RecordDeviceResponse {
  /**
   * message id of footage that device will send to middleware asynchronously
   * @example 1234
   */
  messageId?: MessageId;
}

export interface GetListResponseLanguageDictionary {
  languages?: {
    languageId?: string;
    /** Language string dictionary - by id and value. All JSON fields are dynamic fields. */
    dictionary?: LanguageDictionary;
  }[];
}

export interface GetListResponseRoleInfo {
  roles?: RoleInfo[];
}

export interface GetListResponsePermission {
  data?: Permission[];
}

export interface GetListResponseGroup {
  data?: Group[];
}

export interface GetListResponseFleet {
  data?: Fleet[];
  header?: ListHeader;
}

export interface GetListDeviceConfig {
  data?: GetDeviceConfig[];
  header?: ListHeader;
}

export interface GetListResponseSP {
  data?: SP[];
  header?: ListHeader;
}

export interface GetListResponseVehicle {
  data?: Vehicle[];
  header?: ListHeader;
}

export interface GetListResponseFleetSenseDevice {
  data?: FleetSenseDevice[];
  header?: ListHeader;
}

export interface GetListResponseFleetSenseDrivers {
  data?: DriverWithMoreData[];
  header?: ListHeader;
}

export interface GetListResponseEventMessage {
  data?: EventMessage[];
  header?: ListHeader;
}

export interface GetListResponseTrip {
  data?: Trip[];
  header?: ListHeader;
}

export interface GetEventTypesResponse {
  data?: string[];
}

export interface GetEventDriversResponse {
  data?: string[];
}

export type GetBillingReportHeader = ListHeader & {
  /**
   * @format double
   * @example 123.45
   */
  total?: number;
  /** @example "USD" */
  currencyISO?: string;
};

export interface GetListResponseBillingReport {
  data?: FleetSenseDeviceBilling[];
  header?: GetBillingReportHeader;
}

export interface FleetSenseDevice {
  /**
   * Device unique identifier
   * @example "device123"
   */
  id?: string;
  /** @example "DF3234X" */
  vehicleId?: string | null;
  /** @example "DF3234X" */
  lastVehicleId?: string | null;
  lastEventMessage?: EventMessage;
  perDeviceMediaStorageLimitInMB?: number | null;
  mediaRetentionTimeInDays?: number | null;
  /** Fleet id */
  fleetId?: FleetId;
  /** SP id */
  tspId?: TspId;
  installationData?: InstallationData | null;
  isActive?: boolean;
  properties?: DeviceProperties;
  fleetAssigned?: string;
  tspAssigned?: string;
  /** @format date-time */
  assignFleetDate?: string | null;
  /** @format date-time */
  firstEventDate?: string | null;
  drivers?: Driver[];
  driverCount?: number;
}

export type FleetSenseDeviceBilling = FleetSenseDevice & {
  /**
   * @format double
   * @example 123.45
   */
  cost?: number;
  /** @example 10 */
  eventsCount?: number;
};

export interface Calibration {
  /**
   * @format double
   * @example 32.3
   */
  cameraRotationYaw?: number | null;
  /**
   * @format double
   * @example 32.3
   */
  cameraRotationPitch?: number | null;
  /**
   * @format double
   * @example 32.3
   */
  cameraRotationRoll?: number | null;
  /**
   * @format double
   * @example 32.3
   */
  cameraLocationX?: number | null;
  /**
   * @format double
   * @example 32.3
   */
  cameraLocationY?: number | null;
  /**
   * @format double
   * @example 32.3
   */
  cameraLocationZ?: number | null;
}

export interface DriverInfo {
  /** @example "John Doe" */
  name?: string;
  /** @example "123456789" */
  driverId?: string;
  /** SP id */
  tspId?: TspId;
  /** Fleet id */
  fleetId?: FleetId;
}

export interface Trip {
  /**
   * license plate of the vehicle.
   * @example "554-458-852"
   */
  vehicleId?: string;
  /**
   * a numerator which should allow the server side to group events within a single trip performed by a certain driver.
   * @format uint16
   */
  tripID?: number;
  /** the driver ID of an enrolled driver as saved in the Fleet-Sense enrolled drivers database. If the driver is not identified by the Fleet-Sense device, this field should hold zeros. Driver ID should be sent along with the permission status of the specific driver. */
  drivers?: DriverInfo[];
  firstEvent?: TripEvent;
  lastEvent?: TripEvent;
  totalSafetyEvents?: number;
}

export interface TripEvent {
  lat?: number;
  long?: number;
  address?: string;
  /** @format date-time */
  time?: string;
}

export interface EventMessage {
  /**
   * a unique number across all manufactured fleet-sense devices.
   * @example "device123"
   */
  deviceID?: string;
  /**
   * license plate of the vehicle.
   * @example "554-458-852"
   */
  vehicleId?: string;
  /** The ID of the message. */
  messageID?: MessageId;
  /** IP address in case connected through cellular modem or WiFi Access-Point. */
  selfIP?: string;
  /**
   * a numerator which should allow the server side to group events within a single trip performed by a certain driver.
   * @format uint16
   */
  tripID?: number;
  /**
   * the driver ID of an enrolled driver as saved in the Fleet-Sense enrolled drivers database. If the driver is not identified by the Fleet-Sense device, this field should hold zeros. Driver ID should be sent along with the permission status of the specific driver.
   * @format string
   */
  driverID?: string;
  /**
   * Upon event detection by the DMS algorithm, the Fleet-Sense App should fetch the system accurate time and associate it with the generated event.
   * @format date-time
   */
  eventDateTime?: string;
  /**
   * @format double
   * @example 32.3
   */
  lastKnownLocationLong?: number;
  /**
   * @format double
   * @example 34.3
   */
  lastKnownLocationLat?: number;
  /** @example "Times Square, Manhattan, NY 10036, United States" */
  lastKnownStreetAddress?: string;
  /**
   * Upon event detection by the DMS algorithm, the Fleet-Sense App should fetch the "last location time" and associate it with the generated event. If the Location time is different than the Event time, it means that the event location/speed/heading are not necessarily accurate.
   * @format date-time
   */
  locationTime?: string;
  /**
   * Upon event detection by the DMS algorithm, the Fleet-Sense App should fetch the speed register and associate it with the generated event. Retrieved as meter/sec
   * @format double
   * @example 39.3
   */
  speed?: number;
  /**
   * Upon event detection by the DMS algorithm, the Fleet-Sense App should fetch the Heading-angle register and associate it with the generated event.
   * @format double
   * @example 23.3
   */
  headingAngle?: number;
  /**
   * Upon event detection by the DMS algorithm, the Fleet-Sense App should fetch the FIX quality register and associate it with the generated event. If the FIX quality is below or close to threshold, it means that there might be a significant deviation (higher than 10meters) in the “Last know location” from the actual location of the device at the time of the event generation.
   * @format double
   * @example 3
   */
  locationQuality?: number;
  /**
   * ExtPower(mV) - External input voltage at the time of the event detection.
   * @example 3
   */
  extPower?: string;
  /**
   * InternalBatteryPower(mV) - Internal backup battery voltage at the time of the event detection.
   * @format double
   * @example 3
   */
  internalBatteryPower?: number;
  iOArrayStatus?: InputState[];
  /**
   * event type that occurred
   * @example "SMOKING"
   */
  eventType?: string;
  /**
   * additional information related to the Event type provided in the previous filed, such as system boot failure type, reset type, safety event reason, external triggering reason etc. If the generated event does not have additional info, this field should be zeroed.
   * @example "GPS loss"
   */
  eventAttr1?: string;
  /**
   * The number of videos from the DMS camera associated with this event
   * @format uint16
   */
  cabinVideoCount?: number;
  /**
   * The number of images from the DMS camera associated with this event
   * @format uint16
   */
  cabinImageCount?: number;
  /**
   * The number of videos from the road camera associated with this event
   * @format uint16
   */
  roadVideoCount?: number;
  /**
   * The number of images from the cabin camera associated with this event
   * @format uint16
   */
  roadImageCount?: number;
  /** Type (mandatory) - Private / LCV / MCV / HCV / BUS / Forklift / Machine */
  vehicleType?: VehicleType;
  category?: number;
}

/**
 * indicate if the media is from road or from cabin
 * @default "CABIN"
 */
export enum MediaSource {
  ROAD = "ROAD",
  CABIN = "CABIN",
}

export interface DeviceProperties {
  /** @example "myunit" */
  unitID?: string;
  /** @example 2.232 */
  hWVersion?: string;
  /** @example 22.2 */
  oSVersion?: string;
  /** @example "1.2.3" */
  mainAppVersion?: string;
  /** @example "4.23.2" */
  dmsLibraryVersion?: string;
  /** @example "4.23.2" */
  adasLibraryVersion?: string;
  /** @example 2.232 */
  modemVersion?: string;
  /** @example 2.232 */
  gpsVersion?: string;
  /** @example "2021-01-06T00:00:00.000Z" */
  productionDate?: string;
  testBenchId?: string;
  configFileVersion?: string;
  audioPackage?: string;
  SimCardNumber?: string;
  IMSI?: string;
  IMEI?: string;
  WiFiBtModuleVersion?: string;
  bTMac?: string;
  wifiMac?: string;
  sdCard?: string;
  /**
   * @format date-time
   * @example "2020-12-31T15:03:41+0000"
   */
  installDate?: string | null;
  /**
   * @format date-time
   * @example "2020-12-31T15:03:41+000"
   */
  dmsCalibrationDate?: string | null;
  /**
   * @format date-time
   * @example "2020-12-31T15:03:41+000"
   */
  adasCalibrationDate?: string | null;
  deviceType?: string;
  isLeftHandDrive?: boolean | null;
  dmsCalibration?: Calibration;
  /**
   * @format date-time
   * @example "2020-12-31T15:03:41+000"
   */
  adasLicense?: string | null;
}

/** The unique identifier for the device */
export type DeviceId = string;

export interface ActivateOutput {
  portId?: string;
  /**
   * set -1 for always on, 0 to deactivate
   * @example 60
   */
  secondToActivate?: number;
}

export interface ActivateOutputPWM {
  portId?: string;
  /** @example 60 */
  timeActiveSec?: number;
  timeNonActiveSec?: number;
  /** @example 3 */
  times?: number;
}

export interface AudioConfig {
  /**
   * @format byte
   * @example "eyJjb25maWcxIiA6IDM0MzQ0fQ=="
   */
  config?: string;
}

export interface CameraTestsResult {
  VoltageTest?: TestResult;
  IgnitionTest?: TestResult;
  AudioTest?: TestResult;
  LEDTest?: TestResult;
  GPSTest?: TestResult;
  ServerCommTest?: TestResult;
}

/**
 * FS10 configuration object. The version parameter is mandatory and allows
 * validation of JSON Schemas. Other JSON fields depend on the version.
 */
export interface Configurations {
  /**
   * @pattern ^\d+\.\d+$
   * @example "1.2"
   */
  version: string;
  [key: string]: any;
}

export interface DeviceMessage {
  /** a unique number across all manufactured fleet-sense devices. */
  deviceId?: string;
  /** The ID of the message. */
  messageId?: MessageId;
}

export interface DeviceSettings {
  /** Storgage settings of the device - retention and limits. <br>omit to leave unchanged, set null to internal values to use system defaults */
  storageSettings?: StorageSettings;
  /** mqtt broker password that device will use to log in. For device creation must be set */
  password?: string;
}

export type Driver = AddDriver & {
  driverId?: string;
};

export type AddDriver = DriverName & {
  /** @format byte */
  image?: string;
  /** @format byte */
  vector?: string;
};

export type DriverWithMoreData = Driver & {
  /** SP id */
  tspId?: TspId;
  /** Fleet id */
  fleetId?: FleetId;
  fleetName?: string;
  lastEventMessageType?: string;
  /** @format date-time */
  lastEventMessageTime?: string;
  /** @format int64 */
  lastEventMessageTripId?: number | null;
  devices?: FleetSenseDevice[];
};

export interface EnrolledDriver {
  driverId?: string;
  permission?: boolean | null;
  /** @format base64 */
  image?: string;
}

export interface Files {
  /** @format base64 */
  file: string;
}

export interface FleetSenseDriver {
  permission?: boolean | null;
  /** @format base64 */
  image?: string;
}

export interface GetListResponseUser {
  data?: UserWithMoreData[];
  header?: ListHeader;
}

export interface ImageData {
  /** @format byte */
  vectorImage?: string;
  /** @format byte */
  pngImage?: string;
}

/** a single report of the I/O state */
export interface InputState {
  portId?: IoPortId;
  type?: IoPortType;
  logicState?: IoLogicState;
  /** @format int32 */
  data?: number;
}

export enum InstallMode {
  Dash = "Dash",
  WS = "WS",
}

export interface InstallationData {
  /**
   * installation time
   * @format date-time
   */
  Added?: string;
  /** installation data */
  Data?: object;
  /** The unique identifier for the device */
  DeviceId?: DeviceId;
  /** Type (mandatory) - Private / LCV / MCV / HCV / BUS / Forklift / Machine */
  VehicleType?: VehicleType;
  LicensePlateNumber?: string;
  Model?: string;
  /** @format int32 */
  Year?: number;
  /** @format base64 */
  Image?: string;
}

export enum DeviceStatus {
  Active = "active",
  Disabled = "disabled",
  Paired = "paired",
  Unpaired = "unpaired",
}

export interface InstallationComplete {
  /**
   * The Installer ID
   * @example "username"
   */
  InstallerId?: string | null;
  LocationTimeStamp?: LocationTimeStamp;
  /** @format date-time */
  Time?: string;
  /** will add\update vehicle by license-plate. if the vehicle already exists, should send the entire details as null. if the installer sent the entire details - the vehicle will be updated */
  Vehicle?: Vehicle;
  /**
   * FS10 configuration object. The version parameter is mandatory and allows
   * validation of JSON Schemas. Other JSON fields depend on the version.
   */
  Configurations?: Configurations;
  Tests?: CameraTestsResult;
  Driver?: Driver;
  /**
   * Installation evidence
   * @format base64
   */
  InstallationImage?: string | null;
}

export enum IoLogicState {
  Active = "Active",
  Inactive = "Inactive",
}

export enum IoPortId {
  IGN = "IGN",
  GPI = "GPI",
  GPO = "GPO",
  GPIO = "GPIO",
}

export enum IoPortType {
  In = "In",
  Out = "Out",
}

export interface ListHeader {
  /** @example 3 */
  page?: number | null;
  /** @example 10 */
  pageSize?: number | null;
  /** @example 234 */
  totalCount?: number;
}

export interface LocationTimeStamp {
  /**
   * Last location acquisition time in Unix timestamp
   * @format int32
   * @example 1595435629
   */
  UnixTime?: number;
  /**
   * @format double
   * @example 148.15
   */
  Latitude?: number;
  /**
   * @format double
   * @example -34.28
   */
  Longitude?: number;
}

export interface Login {
  /**
   * The user's email address.
   * @format email
   */
  email: string;
  /**
   * The user's password.
   * @format password
   * @example "myverysecretpassword!#Z"
   */
  password: string;
}

export interface LoginResponse {
  /** A JWT token that can be used to authenticate future requests. */
  token?: string;
}

/**
 * The ID of the message.
 * @format int64
 * @example 1609948948123
 */
export type MessageId = number;

export interface MessagesId {
  /** array of message ids and units (device/vehicle) */
  ids?: DeviceMessage[];
}

export enum DeviceMode {
  InstallationCalibration = "InstallationCalibration",
  Maintenance = "Maintenance",
  Standard = "Standard",
}

export interface User {
  /** @example "john.doe" */
  fullName?: string;
  /**
   * Email address (used and the default user name)
   * @format email
   * @example "fleet.user@fl.com"
   */
  email: string;
  /** id of the user's role, this role is dynamic. */
  role: Role;
  /**
   * Phone number
   * @example "0575241563"
   */
  phoneNumber?: string;
  address?: string;
  /** SP id of the user, if the user isn't in a SP - the value should be null */
  tspId?: NullableTspId;
  /** SP fleet of the user, if the user isn't in a fleet - the value should be null */
  fleetId?: NullableFleetId;
  profile?: UserProfile;
  /** @format date-time */
  lastAccessed?: string | null;
  company?: string;
  description?: string;
  /** @format date */
  termsApproveDate?: string | null;
}

export interface PostUser {
  /** @example "john.doe" */
  fullName: string;
  /** id of the user's role, this role is dynamic. */
  role: Role;
  /**
   * Phone number
   * @example "0575241563"
   */
  phoneNumber?: string;
  address?: string;
  password?: string;
  /** SP id */
  tspId?: NullableTspId;
  /** Fleet id */
  fleetId?: NullableFleetId;
}

export interface RetrieveVideo {
  /** @format date-time */
  StartTime?: string;
  /** @example 60 */
  SecondsToFilm?: number;
}

export type FullDeviceConfig = DeviceConfig & {
  /** @format date-time */
  addedOn?: string;
  /**
   * FS10 configuration object. The version parameter is mandatory and allows
   * validation of JSON Schemas. Other JSON fields depend on the version.
   */
  data?: Configurations;
};

export type AddDeviceConfig = DeviceConfig & {
  /**
   * FS10 configuration object. The version parameter is mandatory and allows
   * validation of JSON Schemas. Other JSON fields depend on the version.
   */
  data?: Configurations;
};

export interface DeviceConfig {
  name?: string;
  version?: string;
  enabled?: boolean;
  description?: string;
  /** SP id */
  tspId?: TspId;
}

export type GetDeviceConfig = DeviceConfig & {
  /** @format date-time */
  addedOn?: string;
};

export type UserProfile = {
  dateTimeFormat?: DateTimeFormat;
  measurement?: Measurement;
  isoLanguage?: IsoLanguage;
};

/** id of the user's role, this role is dynamic. */
export enum Role {
  CipiaAdministrator = "CipiaAdministrator",
  SpManager = "SpManager",
  FleetManager = "FleetManager",
  SpUser = "SpUser",
  FleetUser = "FleetUser",
  Installer = "Installer",
}

export interface SetPassword {
  /** @example "mymoresecretpassword!#X" */
  password: string;
}

export interface ChangePassword {
  /** @example "mymoresecretpassword!#X" */
  password: string;
  /** @example "mymoresecretpassword!#X" */
  oldPassword: string;
}

export interface StorageSettings {
  perDeviceMediaStorageLimitInMB?: number | null;
  mediaRetentionTimeInDays?: number | null;
}

export interface TestResult {
  /** @example false */
  Success: boolean;
  /** @example "Test failed due to..." */
  Message: string | null;
  Status?: TestStatus;
  Code?: TestCode;
}

/** @example "VoltageUnstableError" */
export enum TestCode {
  TimeoutError = "TimeoutError",
  VoltageUnstableError = "VoltageUnstableError",
  MicRecordError = "MicRecordError",
  SpeakerPlaybackError = "SpeakerPlaybackError",
  BluetoothCommError = "BluetoothCommError",
  GPSError = "GPSError",
  ServerCommError = "ServerCommError",
  Validation = "Validation",
}

/** @example "Passed" */
export enum TestStatus {
  Failed = "Failed",
  Passed = "Passed",
  Skipped = "Skipped",
}

export type UserWithMoreData = User & {
  /** @format date-time */
  lastAccessed?: string | null;
};

export type VehicleWithLastMessage = {
  lastEventMessage?: EventMessage;
} & Vehicle;

export interface VehicleWithoutId {
  /** @example "Honda" */
  make?: string | null;
  /** @example "Civic" */
  model?: string | null;
  /**
   * @format int32
   * @example 2020
   */
  year?: number;
  InstallType?: InstallMode;
  /** Type (mandatory) - Private / LCV / MCV / HCV / BUS / Forklift / Machine */
  vehicleType?: VehicleType;
  /**
   * is the vehicle should be active or not.
   * @example true
   */
  isActive?: boolean;
  /** @example "mirror was broken" */
  Comment?: string | null;
  /** @format base64 */
  InstallSnapshot?: string | null;
}

/** Type (mandatory) - Private / LCV / MCV / HCV / BUS / Forklift / Machine */
export enum VehicleType {
  Private = "Private",
  LCV = "LCV",
  MCV = "MCV",
  HCV = "HCV",
  BUS = "BUS",
  Forklift = "Forklift",
  Machine = "Machine",
}

export type Vehicle = VehicleWithoutId & {
  /** @example "123-45-678" */
  licensePlateNumber?: string | null;
};

export type TermsAndCondition = string;

export interface VersionInfo {
  /** @example "2.0.0" */
  maxRestApiVersion?: string;
  /** @example "1.0.0" */
  minRestApiVersion?: string;
  /** @example "2.0.0" */
  maxDeviceApiVersion?: string;
  /** @example "1.0.0" */
  minDeviceApiVersion?: string;
  /** @example "2.0.6" */
  version?: string;
}

/** @format byte */
export type MediaResponse = string;

/** @format base64 */
export type FileRequest = string;

export enum MediaType {
  Video = "video",
  Image = "image",
}

export interface Simlock {
  /** simlock release code */
  code?: string;
}

export interface DeviceLicense {
  /** license key */
  licenseKey?: string;
}

export interface CompanyUser {
  FullName?: string;
  /**
   * Phone number
   * @example "0575241563"
   */
  PhoneNumber?: string;
  /**
   * The contact's email address.
   * @format email
   */
  Email?: string;
  password?: string;
}

export enum Measurement {
  Metric = "Metric",
  Imperial = "Imperial",
}

/** @example "en" */
export type IsoLanguage = string;

export enum DateTimeFormat {
  TwentyFourHoursLongMonthDayYear = "TwentyFourHours_LongMonth_Day_Year",
  ShourtHoursLongMonthDayYear = "ShourtHours_LongMonth_Day_Year",
  YearMonthDayTwentyFourHours = "Year_Month_Day_TwentyFourHours",
  TwentyFourHoursYearMonthDay = "TwentyFourHours_Year_Month_Day",
  DayLongMonthYearTwentyFourHours = "Day_LongMonth_Year_TwentyFourHours",
}

export type SP = SPWithoutId & {
  /**
   * SP's id
   * @example 34543
   */
  id?: number;
};

export type AddSP = SPWithoutId;

export interface SPWithoutId {
  /**
   * the name of the 's company
   * @example "CompanyA"
   */
  companyFriendlyName?: string;
  /**
   * the description of the user's company (free text)
   * @example "A global sweet drink company"
   */
  companyDescription?: string;
  /**
   * the address of the company
   * @example "Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678"
   */
  companyAddress?: string;
  User?: CompanyUser;
  /** logo of SP, optional */
  logo?: FileRequest | null;
  defaultDateTimeFormat?: DateTimeFormat;
  defaultLanguage?: IsoLanguage;
  defaultMeasurement?: Measurement;
}

/** users in sp / fleet - user data + password + role, if the user exists - update data, else - create a new user */
export type AddUsersList = {
  user: User;
  password?: SetPassword | null;
}[];

export type AddFleet = PostFleet;

/**
 * SP id
 * @format int64
 * @example 34543
 */
export type TspId = number;

/**
 * SP id
 * @example 34543
 */
export type NullableTspId = number | null;

/**
 * Fleet id
 * @format int64
 * @example 34543
 */
export type FleetId = number;

/**
 * Fleet id
 * @example 34543
 */
export type NullableFleetId = number | null;

export interface PostFleet {
  /**
   * the name of the company
   * @example "CompanyA"
   */
  companyFriendlyName?: string;
  /**
   * the description of the user's company (free text)
   * @example "A global sweet drink company"
   */
  companyDescription?: string;
  /**
   * the address of the company
   * @example "Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678"
   */
  companyAddress?: string;
  User?: CompanyUser;
  /** logo of fleet, mandatory */
  logo?: FileRequest;
  defaultLanguage?: IsoLanguage;
}

export type Fleet = PostFleet & {
  /** SP id */
  tspId?: TspId;
  /** Fleet id */
  id?: FleetId;
  defaultDateTimeFormat?: DateTimeFormat;
  defaultLanguage?: IsoLanguage;
  defaultMeasurement?: Measurement;
};

export interface PairDevice {
  /**
   * a unique number across all manufactured fleet-sense devices. if the value is null - remove the existing vehicle-device pair
   * @example "device123"
   */
  deviceId?: string | null;
}

export interface GroupWithoutId {
  /**
   * the name of the group
   * @example "CompanyA"
   */
  friendlyName?: string;
  /**
   * a short description of the group
   * @example "location"
   */
  description?: string;
  tags?: TagList;
}

export type Group = GroupWithoutId & {
  /**
   * group id
   * @example 34543
   */
  id?: number;
};

export interface Permission {
  /** permission id */
  id?: number;
  /**
   * short description of the permission
   * @example "read devices permission"
   */
  name?: string;
}

export interface RoleInfo {
  /** id of the user's role, this role is dynamic. */
  id?: Role;
  /**
   * a name of the role
   * @example "Cipia Administrator"
   */
  name?: string;
}

export interface RolePermissions {
  permissions?: (Permission & {
    value?: boolean;
  })[];
}

export interface TopRibbon {
  /** Total number of vehicles for this selection */
  total_vehicles?: number;
  /** Total number of drivers for this selection */
  total_drivers?: number;
  /**
   * Accumulated driven distance (km unit).
   * @format double
   */
  accumulated_driven_distance_Km?: number;
  /**
   * Accumulated driven time (Hours).
   * @format double
   */
  accumulated_driven_time?: number;
  /**
   * Total number of safety events for this selection
   * @format double
   */
  total_number_safety_events?: number;
  /**
   * Safety events rate per KM
   * @format double
   */
  safety_events_rate_per_KM?: number;
}

export interface SafetyEventTypeWithEventsSummary {
  safety_event_name?: string;
  /** Total number of events in this event type */
  total_events?: number;
}

export type SafetyEventTypeBreakdown = SafetyEventTypeWithEventsSummary & {
  /**
   * Total number of Kms, to analyze the number of events per Km per each type of safety event
   * @format double
   */
  total_Km?: number;
  worst_drivers?: DriverName[];
};

export interface SafetyEventsBreakdown {
  /** Summary of events per each type of safety event */
  safety_events_summary?: SafetyEventTypeBreakdown[];
}

export interface SafetyEventsClusterList {
  event_clusters?: SafetyEventsCluster[];
}

export interface SafetyEventsCluster {
  location?: LocationPoint;
  dominate_event_type?: string;
  count_of_events?: number;
}

export interface RiskyDrivingTimeSlot {
  /**
   * UTC start time of the slot
   * @format date-time
   */
  start_time?: string;
  events_summary?: SafetyEventTypeWithEventsSummary;
}

export interface RiskyDrivingTime {
  slots?: RiskyDrivingTimeSlot[];
}

/**
 * unit to do action on
 * @default "Device"
 */
export enum TargetUnit {
  Device = "Device",
  Vehicle = "Vehicle",
}

/** Language string dictionary - by id and value. All JSON fields are dynamic fields. */
export type LanguageDictionary = Record<string, any>;

export type TagList = string[];

export interface TagListObject {
  tags?: TagList;
}

export interface DefaultFilterValues {
  time_range?: "Day" | "Week" | "Month" | "ThreeMonths" | "Year";
  /** Type (mandatory) - Private / LCV / MCV / HCV / BUS / Forklift / Machine */
  vehicle_type?: VehicleType;
  vehicle_tags?: TagList;
  safety_event_types?: string[];
  drivers?: DriverNames;
}

export interface MapFilter {
  top_left_corner?: LocationPoint;
  bottom_right_corner?: LocationPoint;
  /** @format double */
  cluster_distance?: number;
}

export interface LocationPoint {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface Error {
  code: string;
  message: string;
}
