import { PropsWithChildren } from "react";
import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { BillingSortAndFilterParamNames, GetBillingParams } from "../../services/billing.service";
import { dateUtils } from "../../util/date.util";
import { Constants } from "../../constants";
import { BILLING_CONTEXT } from "./queryParamContextObjects";
 
export type GetBillingParamsWithoutFromTo = Omit<GetBillingParams, 'from' | 'to'>;
const billingTableInitialParams: GetBillingParamsWithoutFromTo = {

  filters: [{ paramName: "from", operator: ">=", values: [dateUtils.getFormattedDate( new Date(new Date().getFullYear(), new Date().getMonth(), 1),"YYYY-MM-DD")] }],
  sorts: [],
  paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
};

export function BillingQueryParamsContextProvider({
  children,
}: PropsWithChildren<{ initialParams?: GetBillingParams }>) {
  const initialParams = {
    filters: [],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return (
    <QueryParamsContextProvider context={BILLING_CONTEXT} initialParams={initialParams ?? billingTableInitialParams}>
      {children}
    </QueryParamsContextProvider>
  );
}

export const useBillingQueryParamsContext = () =>
  useQueryParamsContext<BillingSortAndFilterParamNames, BillingSortAndFilterParamNames>(BILLING_CONTEXT);
