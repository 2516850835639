import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";
import App from "../App";
import { Protected } from "../components/ProtectedRoute/ProtectedRoute";
import { eventsRoutes, fleetsRoutes, managementRoutes } from "../util/routes/routes.util";
import { authRoutes } from "../util/routes/auth-routes";
import { RouteConstants } from "../constants";
import MainLayout from "../layouts/MainLayout/MainLayout";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to={`/${RouteConstants.EVENTS_ON_MAP}`} replace />,
      },
      {
        path: "*",
        element: <Navigate to={`/${RouteConstants.EVENTS_ON_MAP}`} replace />,
      },
      ...authRoutes,
      {
        element: <Protected />,
        children: [
          {
            element: <MainLayout />,
            children: [...fleetsRoutes, ...eventsRoutes, ...managementRoutes],
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default function RoutingProvider() {
  return <RouterProvider router={router} />;
}
