import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as StatusActiveIcon } from "../assets/status/StatusActive.svg";
import { ReactComponent as StatusUnassignedIcon } from "../assets/status/StatusUnassigned.svg";
import { ReactComponent as StatusUnpairedIcon } from "../assets/status/StatusUnpaired.svg";
import { ReactComponent as StatusSuspendedIcon } from "../assets/status/StatusSuspended.svg";

export type Status = "ACTIVE" | "MUTED" | "UNPAIRED" | "DISABLED";
interface StatusIconProps {
  type: Status;
}

const typeToIcon = new Map<Status, FunctionComponent<SVGProps<SVGSVGElement>>>([
  ["ACTIVE", (props) => <StatusActiveIcon {...props} />],
  ["MUTED", (props) => <StatusUnassignedIcon {...props} />],
  ["UNPAIRED", (props) => <StatusUnpairedIcon {...props} />],
  ["DISABLED", (props) => <StatusSuspendedIcon {...props} />],
]);

function StatusIcon({ type, ...props }: StatusIconProps & SVGProps<SVGSVGElement>) {
  return typeToIcon.get(type)?.(props) ?? null;
}

export default StatusIcon;
