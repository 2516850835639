import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Location, useLocation, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { Fleet, Role } from "../../api/data-contracts";
import { Constants } from "../../constants";
import { LocationState } from "../../models/location.model";
import { FilterOperator, FilterParam, QueryParams, SortParam } from "../../models/requests/shared.request";
import { areFiltersDistinct, mergeFilterParams } from "../../util/filtering.util";
import { commonQueryReducer } from "../../util/reducers/query-reducer.util";
import { getIsFleetRole } from "../../util/roles.util";
import { uniqueId } from "../../util/shared.util";
import { useAuthContext } from "../AuthContext";
import { ActiveTsp, useTspFleetContext } from "../TspFleetContext/TspFleetContext";

export type FilterDistinctors = Omit<FilterParam, "values">;

interface RegisterFilter {
  distinctors: FilterDistinctors;
  onForcedClear: () => void;
}
interface RegisteredFilter extends RegisterFilter {
  id: FilterId;
}
type FilterId = string;

export interface IQueryParamsContext<SortParamName extends string = string, FilterParamName extends string = string> {
  queryParams: QueryParams<SortParam<SortParamName>, FilterParam<FilterParamName>>;
  setPageIndex: (pageIndex: number) => void;
  upsertFilter: (paramName: FilterParamName, operator: FilterOperator, values: string[]) => void;
  removeFilter: (paramName: FilterParamName, operator: FilterOperator) => void;
  setSort: (paramName: SortParamName, descending?: boolean) => void;
  clearFilters: (exceptions: Omit<FilterParam<FilterParamName>, "values">[]) => void;
  registerFilter: (registerFilter: RegisterFilter) => FilterId;
  unregisterFilter: (filterId: FilterId) => void;
}



type QueryParamsContextProviderProps<SortParamName extends string, FilterParamName extends string> = {
  context: React.Context<IQueryParamsContext<any, any>>,
  initialParams: QueryParams<SortParam<SortParamName>, FilterParam<FilterParamName>>;
} & PropsWithChildren;

export function QueryParamsContextProvider<SortParamName extends string, FilterParamName extends string>({
  context, 
  initialParams: initialParamsFromProps,
  children,
}: QueryParamsContextProviderProps<SortParamName, FilterParamName>) {
  const role = useAuthContext().loggedUser?.role;
  const { activeTsp, activeFleets } = useTspFleetContext();


  const location = useLocation();
  const initialParams = useRef<QueryParams<SortParam, FilterParam>>();
  const [registeredFilters, setRegisteredFilters] = useState<RegisteredFilter[]>([]);
  let [, setSearchParams] = useSearchParams();

  if (!initialParams.current) {
    initialParams.current = getInitialParams({ role, activeTsp, activeFleets, location, initialParamsFromProps });
  }

  const [params, dispatch] = useReducer(
    commonQueryReducer<SortParam<SortParamName>, FilterParam<FilterParamName>>,
    initialParams.current as QueryParams<SortParam<SortParamName>, FilterParam<FilterParamName>>
  );

  const updateSearchParams = useCallback(
    (paramName: string, operator?: FilterOperator, values?: string[]) => {
      const initialFilter = initialParams.current?.filters.find(
        (filter) => filter.paramName === paramName && filter.operator === operator
      );
      const registeredFilter = registeredFilters.find(
        (filter) => filter.distinctors.paramName === paramName && filter.distinctors.operator === operator
      );

      const includeInUrl = registeredFilter?.distinctors.includeInUrl ?? initialFilter?.includeInUrl;

      if (!includeInUrl) return;

      const urlParamName = registeredFilter?.distinctors.urlParamName ?? initialFilter?.urlParamName ?? paramName;

      setSearchParams(
        (prev) => {
          urlParamName !== paramName && prev.delete(paramName);
          values?.length ? prev.set(urlParamName, values.join("|")) : prev.delete(urlParamName);
          return prev;
        },
        { replace: true }
      );
    },
    [setSearchParams, registeredFilters]
  );

  const setPageIndex = useCallback((pageIndex: number) => {
    return dispatch({ type: "SET_PAGE_INDEX", payload: pageIndex });
  }, []);
  const setPageIndexDebounced = useDebouncedCallback(setPageIndex, Constants.PAGINATION_DEBOUNCE_DELAY);

  const upsertFilter = useCallback(
    (paramName: FilterParamName, operator: FilterOperator, values: string[]) => {
      dispatch({
        type: "UPSERT_FILTER",
        payload: { paramName, operator, values },
      });
      setPageIndexDebounced.cancel();

      updateSearchParams(paramName, operator, values);
    },
    [setPageIndexDebounced, updateSearchParams]
  );

  const removeFilter = useCallback(
    (paramName: FilterParamName, operator: FilterOperator) => {
      dispatch({
        type: "REMOVE_FILTER",
        payload: { paramName, operator },
      });

      updateSearchParams(paramName, operator);

      setPageIndexDebounced.cancel();
    },
    [setPageIndexDebounced, updateSearchParams]
  );

  const setSort = useCallback(
    (paramName: SortParamName, descending?: boolean) =>
      dispatch({
        type: "SET_SORT",
        payload: [{ param: paramName, descending: !!descending }],
      }),
    []
  );

  const clearFilters = useCallback(
    (exceptions: Omit<FilterParam<FilterParamName>, "values">[]) => {
      dispatch({ type: "CLEAR_FILTERS", payload: { exceptions } });
      setPageIndexDebounced.cancel();

      for (const registeredFilter of registeredFilters) {
        updateSearchParams(registeredFilter.distinctors.paramName, registeredFilter.distinctors.operator);

        if (exceptions.every((exception) => areFiltersDistinct(exception, registeredFilter.distinctors))) {
          registeredFilter.onForcedClear();
        }
      }
    },
    [setPageIndexDebounced, registeredFilters, updateSearchParams]
  );

  const registerFilter = useCallback((newRegister: RegisterFilter) => {
    const id = uniqueId("f");
    setRegisteredFilters((curr) => [...curr, { ...newRegister, id }]);
    return id;
  }, []);

  const unregisterFilter = useCallback((id: FilterId) => {
    setRegisteredFilters((curr) => curr.filter((registered) => registered.id !== id));
  }, []);

  useEffect(() => {
    // On params.filters change, update search params
    params.filters.forEach(({ paramName, values, operator }) => {
      updateSearchParams(paramName, operator, values);
    });
  }, [params.filters, updateSearchParams]);

  return (
    <context.Provider
      value={{
        queryParams: params,
        setPageIndex: setPageIndexDebounced,
        upsertFilter,
        removeFilter,
        setSort,
        clearFilters,
        registerFilter,
        unregisterFilter,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useQueryParamsContext<SortParamName extends string, FilterParamName extends string>(
  ctx: React.Context<IQueryParamsContext<any, any>>) {
  return useContext<IQueryParamsContext<SortParamName, FilterParamName>>(ctx);
}

 

interface GetInitialParams {
  role?: Role;
  activeTsp?: ActiveTsp;
  activeFleets: Fleet[];
  location: Location;
  initialParamsFromProps: QueryParams<SortParam, FilterParam>;
}
function getInitialParams({ role, activeTsp, activeFleets, location, initialParamsFromProps }: GetInitialParams) {
  let allFilters: FilterParam[] = [];

  if (role && !getIsFleetRole(role) && activeFleets.length) {
    if (activeTsp?.checkType === "FULL") {
      allFilters.push({ paramName: "tspId", operator: "==", values: [String(activeTsp.id)] });
    } else {
      allFilters.push({ paramName: "fleetId", operator: "==", values: activeFleets.map((fleet) => String(fleet.id)) });
    }
  }

  const locationState: LocationState | undefined = location.state;
  allFilters = allFilters.concat(initialParamsFromProps.filters);
  const mergedFilters = mergeFilterParams(allFilters, locationState?.filters);

  return {
    filters: mergedFilters,
    sorts: initialParamsFromProps.sorts,
    paging: initialParamsFromProps.paging,
    geocoding: initialParamsFromProps.geocoding,
  };
}
