import { useForm } from "react-hook-form";
import useUser from "../../../hooks/useUser";
import { PostUser, Role } from "../../../api/data-contracts";
import FormInput from "../../form/FormInput/FormInput";
import { FormattedMessage } from "react-intl";
import { UserTableEntry } from "../UsersTable";
import { ReactComponent as AddCipiaAdminIcon } from "../../../assets/icons/AddCipiaAdmin.svg";
import { ReactComponent as AddTspMngrIcon } from "../../../assets/icons/AddTSPManager.svg";
import { ReactComponent as AddFleetUserIcon } from "../../../assets/icons/AddFleetUser.svg";
import { ReactComponent as EditCipiaAdminIcon } from "../../../assets/icons/EditCipiaAdmin.svg";
import { ReactComponent as EditTspMngrIcon } from "../../../assets/icons/EditTSPManager.svg";
import { ReactComponent as EditFleetUserIcon } from "../../../assets/icons/EditFleetUser.svg";
import { ReactComponent as CheckVIcon } from "../../../assets/icons/CheckV.svg";
import { Color } from "../../../constants";
import TspBigIcon from "../../../assets/TspBig.svg";
import FleetBigIcon from "../../../assets/FleetBig.svg";
import ModalSecondaryButton from "../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import FormModal from "../../form/FormModal/FormModal";
import { FormGroup } from "../../form/FormGroup/FormGroup";
import { useAuthContext } from "../../../context/AuthContext";
import { generatePassword, isPasswordValid } from "../../../util/password.util";
import { isCipia, isFleet, isTsp } from "../../../context/TspFleetContext/tsp-fleet-context.util";
import { ReactNode, useEffect, useRef, useState } from "react";
import { SelectedType } from "../../../context/TspFleetContext/TspFleetContext";
import { getIsFleetRole, getIsTspRole } from "../../../util/roles.util";
import useQueryOwnFleet from "../../../hooks/useQueryOwnFleet";
import { addBase64Prefix } from "../../../util/shared.util";
import CipiaIcon from "../../../assets/mocks/CipiaIcon.svg";

const RoleOptions = {
    Admin: {
        value: Role.CipiaAdministrator,
        label: <FormattedMessage id="CIPIA_ADMIN" defaultMessage="Cipia Administrator" />,
        isDisabled: (selected: SelectedType | undefined) => selected !== "CIPIA"
    },
    SpManager: {
        value: Role.SpManager,
        label: <FormattedMessage id="TSP_MANAGER" defaultMessage="TSP Manager" />,
        isDisabled: (selected: SelectedType | undefined) => !selected || !isTsp(selected),
    },
    SpUser: {
        value: Role.SpUser,
        label: <FormattedMessage id="TSP_USER" defaultMessage="TSP User" />,
        isDisabled: (selected: SelectedType | undefined) => !selected || !isTsp(selected),
    },
    FleetManager: {
        value: Role.FleetManager,
        label: <FormattedMessage id="FLEET_MANAGER" defaultMessage="Fleet Manager" />,
        isDisabled: (selected: SelectedType | undefined, loggedUserRole?: Role) =>
            !getIsFleetRole(loggedUserRole) && (!selected || !isFleet(selected)),
    },
    FleetUser: {
        value: Role.FleetUser,
        label: <FormattedMessage id="FLEET_USER" defaultMessage="Fleet User" />,
        isDisabled: (selected: SelectedType | undefined, loggedUserRole?: Role) =>
            !getIsFleetRole(loggedUserRole) && (!selected || !isFleet(selected)),
    },
    Installer: {
        value: Role.Installer,
        label: <FormattedMessage id="INSTALLER" defaultMessage="Installer" />,
        isDisabled: (selected: SelectedType | undefined, loggedUserRole?: Role) =>
        !getIsFleetRole(loggedUserRole) && (!getIsTspRole(loggedUserRole) || (!selected || !isFleet(selected))),
    },
} as const;

type RoleOption = (typeof RoleOptions)[keyof typeof RoleOptions];

const getRoleOptions = (role: Role, currentRole?: Role) => {
    let options: RoleOption[] = [];
    if (role === Role.CipiaAdministrator) {
        options = [RoleOptions.Admin, RoleOptions.SpUser, RoleOptions.FleetUser, RoleOptions.Installer];
    } else if (role === Role.SpManager) {
        options = [RoleOptions.SpUser, RoleOptions.Installer];
    } else if (role === Role.SpUser) {
        options = [RoleOptions.Installer];
    } else if (role === Role.FleetManager) {
        options = [RoleOptions.FleetUser, RoleOptions.Installer];
    } else if (role === Role.FleetUser) {
        options = [RoleOptions.FleetUser, RoleOptions.Installer];
    } else {
        options = [RoleOptions.Installer];
    }
    //in case of FleetManager or SpManager role change is blocked to ensure existance of a company manager 
    if (currentRole === Role.FleetManager || currentRole === Role.SpManager) {
        options = [];
    }
    if (currentRole && !options.find((roleOption) => roleOption.value === currentRole)) {
        options.push(getRoleOption(currentRole));
    }
    return options;
};

const getModalElements = (isEdit?: boolean, role?: Role, userRole?: Role, tspTreeSelected?: SelectedType) => {
    let icon, logo, roleStr, action;
    if (isEdit) {
        action = "Edit";
        if (userRole === Role.SpManager || userRole === Role.SpUser) {
            icon = <EditTspMngrIcon />;
            roleStr = "TSP";
        } else if (userRole === Role.FleetManager || userRole === Role.FleetUser) {
            icon = <EditFleetUserIcon />;
            roleStr = "Fleet";
        } else if (userRole === Role.Installer) {
            icon = <EditFleetUserIcon />;
            roleStr = "Installer";
        }
        else {
            icon = <EditCipiaAdminIcon />;
            roleStr = "Cipia";
        }
    } else {
        action = "Add";
        if (!tspTreeSelected) {
            if (role === Role.FleetManager || role === Role.FleetUser) {
                icon = <AddFleetUserIcon />;
                roleStr = "Fleet";
            } else {
                icon = <AddCipiaAdminIcon />;
                roleStr = "Cipia";
            }
        } else if (isTsp(tspTreeSelected)) {
            icon = <AddTspMngrIcon />;
            roleStr = "TSP";
        } else if (isFleet(tspTreeSelected)) {
            icon = <AddFleetUserIcon />;
            roleStr = "Fleet";
        }
    }
    if (tspTreeSelected && isCipia(tspTreeSelected)) {
        logo = CipiaIcon;
    } else {
        logo = tspTreeSelected?.logo
            ? addBase64Prefix(tspTreeSelected.logo)
            : isTsp(tspTreeSelected!)
                ? TspBigIcon
                : FleetBigIcon;
    }

    return { icon, logo, roleStr, action };
};

interface IExtendedFormFields {
    control: any;
    tspTreeSelected?: SelectedType;
    logo?: string;
}

const ExtendedFormFields = ({ control, logo }: IExtendedFormFields) => {
    return (
        <div className="d-flex align-items-center gap-3">
            <div className="flex-grow-1">
                <FormGroup
                    label={<FormattedMessage id="AddUserForm.COMPANY_NAME" defaultMessage="Company Name" />}
                    key="companyName"
                    input={<FormInput type="text" name="companyName" control={control} input={{ disabled: true }} />}
                />
                <FormGroup
                    label={<FormattedMessage id="DESCRIPTION" defaultMessage="Description" />}
                    input={
                        <FormInput
                            type="text"
                            input={{
                                disabled: true,
                                type: "textarea",
                                textareaRows: 6,
                            }}
                            name="description"
                            control={control}
                        />
                    }
                />
            </div>
            <div
                style={{ height: "12rem", width: "12rem" }}
                className="d-flex align-items-center justify-content-center mt-4"
            >
                <img alt="logo" style={{ maxWidth: "100%", maxHeight: "100%" }} src={logo} />
            </div>
        </div>
    );
};

const formModalClasses = {
    header: "modal-form-header",
    footer: "modal-form-footer",
    dialog: "modal-form-dialog",
    body: "modal-form-body",
};

interface UserFormInputs {
    companyName?: string;
    description?: string;
    companyAddress?: string;
    email?: string;
    role?: { value: Role; label: ReactNode };
    fullName?: string;
    phone?: string;
    password?: string;
}

type UserActionModalProps = {
    onClickOk: () => void;
    onClickCancel: () => void;
    userInitialData?: UserTableEntry;
    tspTreeSelected: SelectedType | undefined;
    isEdit?: boolean;
};

function AddEditUserModal({
    onClickOk,
    onClickCancel,
    userInitialData,
    isEdit,
    tspTreeSelected,
}: UserActionModalProps) {
    const { addUser, isLoading } = useUser({ onSuccessAddUser: onClickOk });
    const { updateUser } = useUser({ onSuccessUpdateUser: onClickOk });
    const { control, handleSubmit, setValue, trigger } = useForm<UserFormInputs>({
        reValidateMode: "onSubmit",
        defaultValues: {
            companyName: userInitialData?.company ?? (tspTreeSelected && isCipia(tspTreeSelected) ? undefined : tspTreeSelected?.companyFriendlyName),
            description: userInitialData?.description ?? (tspTreeSelected && isCipia(tspTreeSelected) ? undefined : tspTreeSelected?.companyDescription),
            email: userInitialData?.email || "",
            role: {
                value: userInitialData?.role,
                label: userInitialData?.role ? getRoleOption(userInitialData?.role).label : undefined,
            },
            fullName: userInitialData?.fullName,
            phone: userInitialData?.phone,
        },
    });
    const [clickedResetPassword, setClickedResetPassword] = useState<boolean>(false);
    const passwordToggleRef = useRef<HTMLDivElement>(null);
    const { loggedUser } = useAuthContext();
    const { data: ownFleet } = useQueryOwnFleet();


    useEffect(() => {
        if (ownFleet) {
            setValue("companyName", ownFleet.companyFriendlyName);
            setValue("description", ownFleet.companyDescription);
        }

    }, [ownFleet, setValue])

    const onSubmit = async ({ companyAddress, email, role, fullName, phone, password }: UserFormInputs) => {
        let tspId;
        let fleetId;
        if (isEdit) {
            tspId = userInitialData?.tspId;
            fleetId = userInitialData?.fleetId;
        } else {
            if (tspTreeSelected) {
                if (isTsp(tspTreeSelected)) {
                    tspId = tspTreeSelected.id;
                } else if (isFleet(tspTreeSelected)) {
                    tspId = tspTreeSelected.tspId;
                }
            }
            if (getIsFleetRole(loggedUser?.role)) {
                fleetId = ownFleet?.id;
                tspId = ownFleet?.tspId
            } else {
                fleetId = tspTreeSelected && isFleet(tspTreeSelected) ? tspTreeSelected.id : undefined;
            }
        }
        const userData: PostUser = {
            fullName: fullName!,
            role: role?.value as Role,
            phoneNumber: phone,
            address: companyAddress,
            tspId,
            fleetId,
            password,
        };
        if (isEdit) {
            await updateUser(userData, email!);
        } else {
            await addUser(userData, email!);
        }
    };

    const generateAndSetPassword = () => {
        setValue("password", generatePassword());
        trigger("password");
        const passwordToggle = passwordToggleRef.current;
        if (passwordToggle?.getAttribute("data-shown-state") === "hidden") {
            passwordToggle.click();
        }
        setClickedResetPassword(true);
    };
    const { icon, logo, roleStr, action } = getModalElements(isEdit, loggedUser?.role, userInitialData?.role, tspTreeSelected);

    return (
        <>
            <FormModal
                show
                id="addDriverModal"
                handleClose={onClickCancel}
                onSubmit={handleSubmit(onSubmit)}
                classes={formModalClasses}
                header={
                    <>
                        {icon}
                        <div className="p-2">
                            <FormattedMessage id="AddUserForm.USER_ACTION" values={{ action, role: roleStr }} />
                        </div>
                    </>
                }
                footer={
                    <div className="d-flex align-items-center gap-2">
                        <ModalSecondaryButton disabled={isLoading} type="button" onClick={onClickCancel}>
                            <FormattedMessage id="CLOSE" defaultMessage="Close" />
                        </ModalSecondaryButton>
                        <ModalMainButton icon={<CheckVIcon fill={Color.DARKER_GREEN} />} type="submit">
                            {isEdit ? (
                                <FormattedMessage id="SAVE" defaultMessage="Save" />
                            ) : (
                                <FormattedMessage id="CREATE" defaultMessage="Create" />
                            )}
                        </ModalMainButton>
                    </div>
                }
            >
                {(!!tspTreeSelected || loggedUser?.role === Role.FleetManager || loggedUser?.role === Role.FleetUser) && (
                    <ExtendedFormFields control={control} tspTreeSelected={tspTreeSelected} logo={logo} />
                )}
                <FormattedMessage
                    id="AddUserForm.USERDETAILS"
                    defaultMessage="User Details (Mandatory)"
                    values={{
                        role:
                            loggedUser?.role === Role.SpManager || loggedUser?.role === Role.SpUser
                                ? "TSP"
                                : loggedUser?.role === Role.FleetManager || loggedUser?.role === Role.FleetUser
                                    ? "Fleet"
                                    : "",
                    }}
                >
                    {(txt) => <strong>{txt}</strong>}
                </FormattedMessage>
                <FormGroup
                    label={<FormattedMessage id="USER_NAME_EMAIL" defaultMessage="User Name (Email)" />}
                    key="email"
                    input={
                        <FormInput
                            type="text"
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            input={{ disabled: isEdit }}
                        />
                    }
                />
                <FormGroup
                    label={<FormattedMessage id="ROLE" defaultMessage="Role" />}
                    key="role"
                    input={
                        <FormInput
                            type="select"
                            name="role"
                            control={control}
                            rules={{ required: true }}
                            input={{
                                options: getRoleOptions(loggedUser?.role || Role.FleetUser, userInitialData?.role),
                                isOptionDisabled: (roleOption) => roleOption.isDisabled?.(tspTreeSelected, loggedUser?.role),
                            }}
                        />
                    }
                />
                <FormGroup
                    label={<FormattedMessage id="AddUserForm.FULLNAME" defaultMessage="Full name" />}
                    key="fullName"
                    input={<FormInput type="text" name="fullName" control={control} rules={{ required: true }} />}
                />
                <FormGroup
                    label={<FormattedMessage id="USERS_TABLE.PHONE" defaultMessage="Phone" />}
                    key="phone"
                    input={
                        <FormInput
                            type="text"
                            name="phone"
                            control={control}

                            rules={{ required: true }}
                            input={{ disabled: false, pattern: "[0-9]+" }}
                        />
                    }
                />
                <div className="d-flex align-items-end gap-2">
                    <FormGroup
                        className="flex-grow-1"
                        label={<FormattedMessage id="AddUserForm.PASSWORD" defaultMessage="Password" />}
                        key="password"
                        input={
                            <FormInput
                                type="text"
                                name="password"
                                control={control}
                                rules={{
                                    validate: (pass: string | undefined) =>
                                        isEdit
                                            ? !pass || isPasswordValid(pass, true)
                                            : pass !== undefined && pass.length > 0 && isPasswordValid(pass, true),
                                }}
                                input={{ type: "password", disabled: isEdit && !clickedResetPassword, passwordToggleRef }}
                            />
                        }
                    />
                    <ModalSecondaryButton type="button" onClick={generateAndSetPassword} style={{ height: "2rem" }}>
                        <FormattedMessage
                            id="SUGGEST_PASSWORD"
                            defaultMessage="Suggest Password"
                            values={{ action: isEdit ? "Reset" : "Suggest" }}
                        />
                    </ModalSecondaryButton>
                </div>
            </FormModal>
        </>
    );
}

export default AddEditUserModal;

function getRoleOption(value: Role) {
    switch (value) {
        case Role.CipiaAdministrator:
            return RoleOptions.Admin;
        case Role.SpManager:
            return RoleOptions.SpManager;
        case Role.SpUser:
            return RoleOptions.SpUser;
        case Role.FleetManager:
            return RoleOptions.FleetManager;
        case Role.FleetUser:
            return RoleOptions.FleetUser;
        case Role.Installer:
            return RoleOptions.Installer;
    }
}
