interface VideoProps {
  color?: string;
}

function Video({ color = "black" }: VideoProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6387 6.83276H4.62608C4.22985 6.83276 3.84984 6.99017 3.56966 7.27035C3.28948 7.55053 3.13208 7.93053 3.13208 8.32676L3.13208 17.3394C3.13208 17.7356 3.28948 18.1156 3.56966 18.3958C3.84984 18.676 4.22985 18.8334 4.62608 18.8334H13.6387C14.0349 18.8334 14.4149 18.676 14.6951 18.3958C14.9753 18.1156 15.1327 17.7356 15.1327 17.3394V8.32676C15.1327 7.93053 14.9753 7.55053 14.6951 7.27035C14.4149 6.99017 14.0349 6.83276 13.6387 6.83276ZM19.5571 8.01086L16.1317 10.3734V15.2928L19.5571 17.6526C19.7064 17.7568 19.8814 17.8184 20.0632 17.8306C20.2449 17.8429 20.4266 17.8053 20.5886 17.722C20.7506 17.6387 20.8868 17.5128 20.9825 17.3578C21.0783 17.2029 21.13 17.0247 21.1321 16.8426V8.81276C21.1283 8.63143 21.0753 8.45453 20.9789 8.3009C20.8825 8.14728 20.7462 8.02268 20.5846 7.94039C20.423 7.8581 20.242 7.82119 20.0611 7.8336C19.8802 7.84601 19.706 7.90728 19.5571 8.01086Z"
        fill={color}
      />
    </svg>
  );
}

export default Video;
