import { useQuery } from "@tanstack/react-query";
import { GetTripsResult } from "../services/trips.service";
import { tripsQuery } from "../queries/trips.query";
import { ServiceGetResult } from "../models/requests/shared.request";
import { useTripsQueryParamsContext } from "../context/QueryParamsContext/TripsQueryParamsContextProvider";
import { useEffect } from "react";
import useFilterByTspFleetContext from "./useFilterByTspFleetContext";

function useQueryTrips<selectedT extends ServiceGetResult<unknown>>(
  selector: (queryResult: GetTripsResult) => selectedT
) {
  
  const { queryParams, setPageIndex,upsertFilter,removeFilter } = useTripsQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(upsertFilter,removeFilter,queryParams.filters);
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  const { data, isLoading, isError, isFetching } = useQuery(tripsQuery(queryParams, selector, showData));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: showData ? data : undefined,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
  };
}

export default useQueryTrips;
