import { SVGProps } from "react";
import { Color } from "../../constants";

interface ChevronProps {
  direction?: "LEFT" | "RIGHT";
  disabled?: boolean;
}

function Chevron({
  direction = "RIGHT",
  disabled,
  ...props
}: ChevronProps & SVGProps<SVGSVGElement>) {
  const rotation = direction === "RIGHT" ? 0 : 180;
  return (
    <svg
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      transform={`rotate(${rotation})`}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.25574 19.9165L8.00677 18.6675L13.7578 12.9165L8.00677 7.16547L9.25574 5.9165L16.2557 12.9165L9.25574 19.9165Z"
        fill={disabled ? Color.GRAY_5 : Color.BLACK}
      />
    </svg>
  );
}

export default Chevron;
