import { Fleet, Role } from "../api/data-contracts";
import { FilterDistinctors } from "../context/QueryParamsContext/QueryParamsContext";
import { ActiveTsp } from "../context/TspFleetContext/TspFleetContext";
import { FilterParam } from "../models/requests/shared.request";
import { getIsFleetRole } from "./roles.util";

/* 
Receives FilterParam[],
Returns a filtering string as required by sieve on the backend 
*/
export function filterParamsToString(filterParams: FilterParam[]) {
  let result = "";
  const len = filterParams.length;
  if (!len) return result;

  for (let i = 0; i < len - 1; ++i) {
    result += filterParams[i].paramName;
    result += filterParams[i].operator;
    result += formatValues(filterParams[i].values);
    result += ",";
  }
  result += filterParams[len - 1].paramName;
  result += filterParams[len - 1].operator;
  result += formatValues(filterParams[len - 1].values);

  return result;
}

function formatValues<TValue extends string>(values: TValue[]) {
  const len = values.length;
  let result = "";

  if (len) {
    for (let i = 0; i < len - 1; ++i) {
      result += values[i] + "|";
    }
    result += values[len - 1];
  }

  return result;
}

/* Compares the "unique constraint fields" of 2 FilterParams, use it for sorting filters */
const collator = new Intl.Collator();
export function compareFiltersUniqueConstraint(filter1: FilterDistinctors, filter2: FilterDistinctors): number {
  return collator.compare(filter1.paramName + filter1.operator, filter2.paramName + filter2.operator);
}

/* Returns true if 2 filters have different "unique constraint fields", false if same  */
export function areFiltersDistinct(filter1: FilterDistinctors, filter2: FilterDistinctors): boolean {
  return compareFiltersUniqueConstraint(filter1, filter2) !== 0;
}

export function getFormattedFilterParam<TFilter extends FilterParam>(filter: TFilter): TFilter {
  return { ...filter, values: [...filter.values].sort() };
}

export function isDateInRange(date: Date | string, from?: Date | string, to?: Date | string) {
  let isInRange = true;
  const time = new Date(date).getTime();
  if (from) {
    isInRange &&= time >= new Date(from).getTime();
  }
  if (to) {
    isInRange &&= time <= new Date(to).getTime();
  }

  return isInRange;
}

export function getInitTspFleetFilter(
  role: Role | undefined,
  activeTsp: ActiveTsp | undefined,
  activeFleets: Fleet[]
): FilterParam<"tspId" | "fleetId"> | undefined {
  if (!role || getIsFleetRole(role) || !activeFleets.length) return undefined;

  return {
    paramName: activeTsp?.checkType === "FULL" ? "tspId" : "fleetId",
    operator: "==",
    values: activeTsp?.checkType === "FULL" ? [String(activeTsp.id)] : activeFleets.map((fleet) => String(fleet.id)),
  };
}

export function mergeFilterParams(
  lowPriorityfilterParams: FilterParam[],
  highPriorityfilterParams?: FilterParam[]
): FilterParam[] {
  const resultFilters: FilterParam[] = [];
  for (const filter of lowPriorityfilterParams) {
    const existingFilterIndex = resultFilters.findIndex((resultFilter) => !areFiltersDistinct(resultFilter, filter));
    if (existingFilterIndex !== -1) {
      resultFilters[existingFilterIndex] = getFormattedFilterParam(filter);
    } else {
      resultFilters.push(getFormattedFilterParam(filter));
    }
  }
  if (highPriorityfilterParams) {
    for (const filter of highPriorityfilterParams) {
      const existingFilterIndex = resultFilters.findIndex((resultFilter) => !areFiltersDistinct(resultFilter, filter));
      if (existingFilterIndex !== -1) {
        resultFilters[existingFilterIndex] = getFormattedFilterParam(filter);
      } else {
        resultFilters.push(getFormattedFilterParam(filter));
      }
    }
  }
  resultFilters.sort(compareFiltersUniqueConstraint);

  return resultFilters;
}
