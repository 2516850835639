import { Fleet } from "../../api/data-contracts";
import { Tsp } from "../../context/TspFleetContext/TspFleetContext";
import ModalYesNo from "../ModalYesNo/ModalYesNo";
import { ReactComponent as TrashIcon } from "../../assets/icons/TrashBig.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/Warning.svg";
import { FormattedMessage } from "react-intl";
import AddEditTspFleetModalForm, { TspFleetFormInputs } from "../AddEditTspFleetModalForm/AddEditTspFleetModalForm";
import useTsp from "../../hooks/useTsp";
import useFleet from "../../hooks/useFleet";
import { addBase64Prefix } from "../../util/shared.util";
import { useIntlContext } from "../../context/IntlContext";
import { useEffect, useState } from "react";

export type Operation = "DELETE_TSP" | "DELETE_FLEET" | "EDIT_TSP" | "EDIT_FLEET" | null;
export type OperationData = Tsp | Fleet;

type ModalVariant = Operation | "CONFIRM_DELETE_TSP" | "CONFIRM_DELETE_FLEET";

interface EditDeleteTspFleetModalsProps {
  operation: Operation;
  onFinish: (success: boolean) => void;
  data: OperationData;
}

/* data for DELETE_FLEET or EDIT_FLEET must be Fleet,
data for DELETE_TSP or EDIT_TSP must be Tsp  */
function EditDeleteTspFleetModals({ operation, onFinish, data }: EditDeleteTspFleetModalsProps) {
  const [shownModal, setShownModal] = useState<ModalVariant>(operation);
  useEffect(() => {
    setShownModal(operation);
  }, [operation]);

  const finish = (success: boolean) => {
    setShownModal(null);
    onFinish(success);
  };

  const { getLangNames } = useIntlContext();
  const { deleteTsp, isLoadingDeleteTsp, updateTsp, isLoadingUpdateTsp } = useTsp({
    onSuccessUpdate: () => finish(true),
    onSuccessDelete: () => finish(true),
  });
  const { deleteFleet, isLoadingDeleteFleet, updateFleet, isLoadingUpdateFleet } = useFleet({
    onSuccessUpdate: () => finish(true),
    onSuccessDelete: () => finish(true),
  });

  function getDefaultEditInputs({
    companyFriendlyName,
    companyDescription,
    companyAddress,
    logo,
    defaultLanguage,
    User,
  }: OperationData): TspFleetFormInputs {
    return {
      companyName: companyFriendlyName!,
      companyAddress,
      image: logo ? addBase64Prefix(logo) : undefined,
      description: companyDescription,
      fullName: User?.FullName,
      phone: User?.PhoneNumber,
      userName: User?.Email,
      defaultLanguage: defaultLanguage
        ? {
            label: getLangNames()!.find((l) => l.short === defaultLanguage)?.long || defaultLanguage,
            value: defaultLanguage,
          }
        : undefined,
    };
  }
  return (
    <>
      <ModalYesNo
        body={
          <div className="d-flex flex-column align-items-center gap-4">
            <TrashIcon />
            <FormattedMessage
              id="TSP.DELETE_QUESTION"
              defaultMessage="Deleting the tsp will delete all associated data. Are you sure you want to Delete this Tsp?"
            />
          </div>
        }
        onClickNo={() => finish(false)}
        onHide={() => finish(false)}
        onClickYes={() => setShownModal("CONFIRM_DELETE_TSP")}
        show={shownModal === "DELETE_TSP"}
      />
      <ModalYesNo
        body={
          <div className="d-flex flex-column align-items-center gap-4">
            <WarningIcon />
            <FormattedMessage
              id="TSP.DELETE_WARNING"
              defaultMessage="By confirming the deletion of the tsp all its data will be deleted permanently. Are you sure you want to continue?"
            />
          </div>
        }
        onClickNo={() => finish(false)}
        onHide={() => finish(false)}
        onClickYes={() => deleteTsp({ tspId: data?.id! })}
        show={shownModal === "CONFIRM_DELETE_TSP"}
        isLoading={isLoadingDeleteTsp}
      />
      <ModalYesNo
        body={
          <div className="d-flex flex-column align-items-center gap-4">
            <TrashIcon />
            <FormattedMessage
              id="FLEET.DELETE_QUESTION"
              defaultMessage="Deleting the fleet will delete all associated data. Are you sure you want to Delete this Fleet?"
            />
          </div>
        }
        onClickNo={() => finish(false)}
        onHide={() => finish(false)}
        onClickYes={() => setShownModal("CONFIRM_DELETE_FLEET")}
        show={shownModal === "DELETE_FLEET"}
      />
      <ModalYesNo
        body={
          <div className="d-flex flex-column align-items-center gap-4">
            <WarningIcon />
            <FormattedMessage
              id="FLEET.DELETE_WARNING"
              defaultMessage="By confirming the deletion of the fleet all its data will be deleted permanently. Are you sure you want to continue?"
            />
          </div>
        }
        onClickNo={() => finish(false)}
        onHide={() => finish(false)}
        onClickYes={() => deleteFleet({ fleetId: data?.id! })}
        show={shownModal === "CONFIRM_DELETE_FLEET"}
        isLoading={isLoadingDeleteFleet}
      />
      {shownModal === "EDIT_TSP" && (
        <AddEditTspFleetModalForm
          headersTextVariant="TSP"
          operationType="EDIT"
          show={shownModal === "EDIT_TSP"}
          defaultValues={data ? getDefaultEditInputs(data) : undefined}
          handleClose={() => finish(false)}
          onSubmit={({ companyAddress, description, companyName, image, userName, fullName, phone, defaultLanguage }) =>
            updateTsp({
              tspId: data?.id!,
              companyAddress,
              companyDescription: description,
              companyFriendlyName: companyName,
              logo: image,
              defaultLanguage: defaultLanguage?.value,
              User: { Email: userName, FullName: fullName, PhoneNumber: phone },
            })
          }
          isLoading={isLoadingUpdateTsp}
        />
      )}
      {shownModal === "EDIT_FLEET" && (
        <AddEditTspFleetModalForm
          headersTextVariant="FLEET"
          operationType="EDIT"
          show={shownModal === "EDIT_FLEET"}
          defaultValues={data ? getDefaultEditInputs(data) : undefined}
          handleClose={() => finish(false)}
          onSubmit={({ companyAddress, description, companyName, image, userName, fullName, phone, defaultLanguage }) =>
            updateFleet({
              fleetId: data?.id!,
              id: data?.id,
              tspId: (data as Fleet).tspId,
              companyAddress,
              companyDescription: description,
              companyFriendlyName: companyName,
              logo: image,
              defaultLanguage: defaultLanguage?.value,
              User: { Email: userName, FullName: fullName, PhoneNumber: phone },
            })
          }
          isLoading={isLoadingUpdateFleet}
        />
      )}
    </>
  );
}

export default EditDeleteTspFleetModals;
