import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as CipiaIcon } from "../assets/icons/CipiaAdmin.svg";
import { ReactComponent as TspManagerIcon } from "../assets/icons/TspManager.svg";
import { ReactComponent as FleetManagerIcon } from "../assets/icons/FleetManager.svg";
import { ReactComponent as FleetUserIcon } from "../assets/icons/FleetUser.svg";
import { ReactComponent as InstallerIcon } from "../assets/icons/Installer.svg";
import { Role } from "../api/data-contracts";

interface RoleIconProps {
  role: Role;
}

const typeToIcon = new Map<Role, FunctionComponent<SVGProps<SVGSVGElement>>>([
  [Role.CipiaAdministrator, (props) => <CipiaIcon {...props} />],
  [Role.SpManager, (props) => <TspManagerIcon {...props} />],
  [Role.SpUser, (props) => <FleetUserIcon {...props} />],
  [Role.FleetManager, (props) => <FleetManagerIcon {...props} />],
  [Role.FleetUser, (props) => <FleetUserIcon {...props} />],
  [Role.Installer, (props) => <InstallerIcon {...props} />],

]);

function RoleIcon({ role, ...props }: RoleIconProps & SVGProps<SVGSVGElement>) {
  return typeToIcon.get(role)?.(props) ?? null;
}

export default RoleIcon;
