import { forwardRef, ReactNode } from "react";
import Form from "react-bootstrap/Form";
import { FormCheckProps } from "react-bootstrap/FormCheck";
import { uniqueId } from "../../../util/shared.util";
import styles from "./CheckBox.module.css";

export type CheckBoxType = "checkbox" | "radio" | "switch";

export type CheckBoxProps = {
  className?: string;
  type?: CheckBoxType;
  checked?: boolean;
  label?: ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<FormCheckProps, "id">;

export const CheckBox = forwardRef(
  (
    {
      label,
      type = "checkbox",
      checked,
      onChange,
      isInvalid,
      className,
      disabled,
      ...rest
    }: CheckBoxProps,
    ref
  ) => {
    const id = uniqueId("checkbox");

    return (
      <Form.Group
        controlId={id}
        className={`${
          className ? className : ""
        } checkbox mb-0 d-flex align-items-center`}
      >
        <Form.Check
          // custom
          id={id}
          type={type}
          className={`${styles.box} ${styles[type]} ${
            disabled ? styles.disabled : ""
          } font-md`}
          checked={checked ?? false}
          onChange={onChange}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        <Form.Label
          className={`mb-0 user-select-none ${
            checked ? "font-weight-bold" : ""
          } ${!disabled ? "cursor-pointer" : ""}`}
        >
          {label}
        </Form.Label>
      </Form.Group>
    );
  }
);
