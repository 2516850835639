import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { configurationService } from "../services/configuration.service";
import { AddDeviceConfig, DeviceConfig } from "../api/data-contracts";

export interface ConfigParams {
  name: string;
  version:string,
  tspId:number
}
type EnableDisableConfigParams = ConfigParams & {enabled:boolean};
type DeleteConfigMutationOptions = UseMutationOptions<AxiosResponse, AxiosError, ConfigParams>;
type AddConfigMutationOptions = UseMutationOptions<AxiosResponse, AxiosError, AddDeviceConfig>;
type EnableDisableConfigMutationOptions = UseMutationOptions<AxiosResponse, AxiosError, EnableDisableConfigParams>;

export const deleteConfigMutation = (
  options?: Omit<DeleteConfigMutationOptions, "mutationFn" | "mutationKey">
): UseMutationOptions<AxiosResponse, AxiosError, ConfigParams> => ({
  mutationKey: ["deleteUser"],
  mutationFn: (config: ConfigParams) => configurationService.deleteConfig(config.name,config.version,config.tspId),
  ...options,
});

export const addConfigMutation = (
  options?: Omit<AddConfigMutationOptions, "mutationFn" | "mutationKey">
): UseMutationOptions<AxiosResponse, AxiosError, AddDeviceConfig> => ({
  mutationKey: ["addUser"],
  mutationFn: (config: DeviceConfig) => configurationService.addConfig(config),
  ...options,
});


export const enableOrDisableConfigMutation = (
  options?: Omit<EnableDisableConfigMutationOptions, "mutationFn" | "mutationKey">
): UseMutationOptions<AxiosResponse, AxiosError, EnableDisableConfigParams> => ({
  mutationKey: ["addUser"],
  mutationFn: (config: EnableDisableConfigParams)  =>
  {
    return config.enabled ? configurationService.enableConfig(config.name,config.version,config.tspId)
                          : configurationService.disableConfig(config.name,config.version,config.tspId)
  },
  ...options,
});
