import { useEffect } from "react";
import { useTspFleetContext } from "../context/TspFleetContext/TspFleetContext";
import { useAuthContext } from "../context/AuthContext";
import { getIsFleetRole } from "../util/roles.util";
import { FilterOperator, FilterParam } from "../models/requests/shared.request";
 

 

function useFilterByTspFleetContext<ParamName extends string ="tspId" | "fleetId" >(upsertFilter: (paramName: ParamName, operator: FilterOperator, values: string[]) => void, removeFilter: (paramName: ParamName, operator: FilterOperator)=> void,filters: FilterParam[] ) {

  const role = useAuthContext().loggedUser?.role;
  const isFleetRole = getIsFleetRole(role);
  const { activeTsp, activeFleets, selected, allTsps } = useTspFleetContext();
   
  useEffect(() => {
    if (!allTsps.length) return;

    if (selected === "CIPIA" || !activeTsp?.checkType) {
      removeFilter("tspId" as ParamName, "==")
      removeFilter("fleetId"    as ParamName, "==")
      return;
    }
    upsertFilter("tspId" as ParamName, "==", [String(activeTsp.id)])

    if (activeTsp?.checkType === "EMPTY") {
      upsertFilter("fleetId"  as ParamName, "==", ["null"])
      return;
    }

    if (activeTsp?.checkType === "HALF") {
       
      upsertFilter("fleetId"  as ParamName,"==",activeFleets.map((fleet) => String(fleet.id)));
      return;
    }

    if (activeTsp?.checkType === "FULL") {
      removeFilter("fleetId" as ParamName, "==")
      return;
    }
  }, [activeFleets, activeTsp, allTsps.length, upsertFilter,removeFilter,  selected]);

  // const contextValues: readonly string[] = useMemo(() => {
  //   const filter = filters.find((filter) => !areFiltersDistinct(filter, { "fleetId", "==" }));
  //   return filter?.values ?? EMPTY_ARR;
  // }, [paramName, operator, contextFilters]);
  return {
    isApplied: true,//!!contextValuesTsp.length || !!contextValuesFleet.length,
    isRequired: !isFleetRole && selected !== "CIPIA",
  };
}

export default useFilterByTspFleetContext;
