import { useQuery } from "@tanstack/react-query";
import { GetUsersResult } from "../services/user.service";
import { usersQuery } from "../queries/user.query";
import { ServiceGetResult } from "../models/requests/shared.request";
import { useUsersQueryParamsContext } from "../context/QueryParamsContext/UsersQueryParamsContextProvider";
import { useEffect } from "react";
import useFilterByTspFleetContext from "./useFilterByTspFleetContext";

function useQueryUsers<selectedT extends ServiceGetResult<unknown>>(
  selector: (queryResult: GetUsersResult) => selectedT
) {
  
  const { queryParams, setPageIndex,upsertFilter,removeFilter } = useUsersQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(upsertFilter,removeFilter, queryParams.filters);
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;
  const { data, isLoading, isError, isFetching } = useQuery(usersQuery(queryParams, selector, showData));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: showData ? data : undefined,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
  };
}

export default useQueryUsers;
