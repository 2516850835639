import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { PostUser, User } from "../api/data-contracts";
import { PutProfileParams, userService } from "../services/user.service";

export const addUserMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  { user: PostUser; email: string }
> => ({
  mutationKey: ["addUser"],
  mutationFn: ({ user, email }: { user: PostUser; email: string }) => userService.addUser(user, email),
});

export const updateUserMutation = (): UseMutationOptions<
  AxiosResponse<User>,
  AxiosError,
  { user: PostUser; email: string }
> => ({
  mutationKey: ["updateUser"],
  mutationFn: ({ user, email }: { user: PostUser; email: string }) => userService.updateUser(user, email),
});

export interface SetPasswordInt {
  userEmail: string;
  password: string;
}
export interface ChangePasswordInt {
  oldPassword: string;
  newPassword: string;
}
type SetPasswordMutationOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, SetPasswordInt>;
export const setPasswordMutation = (
  options: Omit<SetPasswordMutationOptions, "mutationFn" | "mutationKey">
): SetPasswordMutationOptions => ({
  mutationKey: ["setPassword"],
  mutationFn: (setPassword: SetPasswordInt) => userService.setPassword(setPassword),
  ...options,
});

type ChangePasswordMutationOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, ChangePasswordInt>;

export const changePasswordMutation = (
  options: Omit<ChangePasswordMutationOptions, "mutationFn" | "mutationKey">
): ChangePasswordMutationOptions => ({
  mutationKey: ["changeassword"],
  mutationFn: (changePassword: ChangePasswordInt) => userService.changePassword(changePassword),
  ...options,
});

export interface DeleteUser {
  email: string;
}

type DeleteUserMutationOptions = UseMutationOptions<AxiosResponse, AxiosError, DeleteUser>;

export const deleteUserMutation = (
  options?: Omit<DeleteUserMutationOptions, "mutationFn" | "mutationKey">
): UseMutationOptions<AxiosResponse, AxiosError, DeleteUser> => ({
  mutationKey: ["deleteUser"],
  mutationFn: (userEmail: DeleteUser) => userService.deleteUser(userEmail.email),
  ...options,
});

type PutProfileMutationOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, PutProfileParams>;
export const putProfileMutation = (
  options: Omit<PutProfileMutationOptions, "mutationFn" | "mutationKey">
): PutProfileMutationOptions => ({
  mutationKey: ["profile"],
  mutationFn: (params: PutProfileParams) => userService.putProfile(params),
  ...options,
});

type TermsMutationOptions = UseMutationOptions<AxiosResponse<void, any>, AxiosError, string>;
export const acceptTermsMutation = (
  options?: Omit<TermsMutationOptions, "mutationFn" | "mutationKey">
): TermsMutationOptions => ({
  mutationKey: ["termsApproval"],
  mutationFn: (userEmail: string) => userService.approveTerms(userEmail),
  ...options,
});
