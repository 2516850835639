import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { GetBillingResult } from "../services/billing.service";
import { useBillingQueryParamsContext } from "../context/QueryParamsContext/BillingQueryParamsContextProvider";
import { billingReportQuery } from "../queries/billingReport.query";

function useQueryBillingReport<TTarget extends { pageCount?: number }>(
  selector: (queryResult: GetBillingResult) => TTarget,
  enabled: boolean = true
) {

  let { queryParams, setPageIndex } = useBillingQueryParamsContext();
  const { data, isLoading, isError, isFetching } = useQuery(billingReportQuery(queryParams, selector, enabled));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return { data, isLoading: isLoading && isFetching, isError, isFetching };
}

export default useQueryBillingReport;
