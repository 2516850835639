import { ReactComponent as CaptureIcon } from "../../../assets/icons/capture.svg";
import { DriverTableEntry } from "../DriversTable";
import { useTspFleetContext } from "../../../context/TspFleetContext/TspFleetContext";
import { FormattedMessage } from "react-intl";
import { FormGroup } from "../../form/FormGroup/FormGroup";
import FormInput from "../../form/FormInput/FormInput";
import FormModal from "../../form/FormModal/FormModal";
import { useForm } from "react-hook-form";
import ModalSecondaryButton from "../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import { ReactComponent as CheckVIcon } from "../../../assets/icons/CheckV.svg";
import { ReactComponent as AddDriverIcon } from "../../../assets/icons/Driver-add.svg";
import { stripBase64Prefix, toBase64 } from "../../../util/shared.util";
import { Color } from "../../../constants";
import useDriver from "../../../hooks/useDriver";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProfilePhotoUpload from "./ProfilePhotoUpload";
import EnrollDriver from "./EnrollDriver";
import DriverDeviceTable, { DriverDeviceEntry } from "./DriverVehiclesTable";
import { useQueryDeviceFilteredByDeviceIdOrVehicle } from "../../../hooks/useQueryDevices";
import { devicesToDriverVehicles } from "./driver-vehicles-table.util";
import { Fleet } from "../../../api/data-contracts";
import { ReactComponent as AddIcon } from "../../../assets/icons/Add.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/TrashBig.svg";
import Pagination from "../../Table/Pagination";
import { useVehiclesQueryParamsContext } from "../../../context/QueryParamsContext/VehiclesQueryParamsContextProvider";
import useQueryDriverDevices from "../../../hooks/useQueryDriverDevices";
import { SearchBox } from "../../form/SearchBox/SearchBox";
import CaptureDriver from "./CaptureDriver";
import useMutationDeviceDriver from "../../../hooks/useMutationDeviceDriver";
import { ImageData } from "../../../api/data-contracts";
import style from "./DriverCardModal.module.scss";
import { useQueryDriver } from "../../../hooks/useQueryDrivers";
const formModalClasses = {
  header: "modal-form-header",
  footer: "modal-form-footer",
  dialog: "big-modal-form-dialog",
  body: "big-modal-form-body",
};

interface DriverCardFormInputs {
  driverId: string;
  name?: string;
  profilePhoto?: string;
  vectorPhoto?: string;
}

interface DriverCardInfo {
  driverId: string;
  tspId: number;
  fleetId: number;
}

export type EnrollDriverFormInputs = {
  deviceId: string;
  photoUrl?: string;
};

export type EnrollStatus = "none" | "not-enrolled" | "enrolling" | "enrolled" | "saved";

type DriverCardModalProps = {
  show: boolean;
  onClickClose: () => void;
  driverInitialData?: DriverTableEntry;
  isEdit?: boolean;
};

function DriverCardModal({ show, onClickClose, driverInitialData, isEdit }: DriverCardModalProps) {
  const [deviceFilter, setDeviceFilter] = useState<string>();
  const [enrollDeviceId, setEnrollDeviceId] = useState<string | undefined>();
  const [enrollImageData, setEnrollImageData] = useState<ImageData>();
  const [enrollStatus, setEnrollStatus] = useState<EnrollStatus>("none");

  const [driver, setDriver] = useState<DriverCardInfo | undefined>(
    driverInitialData?.driverId
      ? {
          driverId: driverInitialData?.driverId!,
          fleetId: driverInitialData?.fleetId!,
          tspId: driverInitialData?.tspId!,
        }
      : undefined
  );

  const driverDevices = useQueryDriverDevices({ ...driver!, includeDevices: true }, !!driver?.driverId);

  const driverDeviceData = useMemo(() => devicesToDriverVehicles(driverDevices)?.data, [driverDevices]);

  const { selected } = useTspFleetContext();
  const selectedFleet = selected as Fleet;
  const {
    data: deviceData,
    isError: isErrorDevices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices,
  } = useQueryDeviceFilteredByDeviceIdOrVehicle(
    deviceFilter,
    driverDevices?.data,
    selectedFleet && { tspId: selectedFleet.tspId!, fleetId: selectedFleet.id },
    devicesToDriverVehicles
  );
  const { takeDriverFacePhoto } = useMutationDeviceDriver((enrollData) => setEnrollImageData(enrollData));

  const { addDriverToDevice, removeDriverFromDevice } = useDriver();
  const { addDriver, updateDriver, isLoadingAddDriver: isAddLoading } = useDriver();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<DriverCardFormInputs>({
    resetOptions: { keepDefaultValues: true },
    defaultValues: {
      driverId: driverInitialData?.driverId,
      name: driverInitialData?.name,
      profilePhoto: driverInitialData?.image,
      vectorPhoto: driverInitialData?.vector,
    },
  });
  useEffect(() => {
    if (enrollStatus !== "none" && enrollStatus !== "saved") {
      setEnrollStatus(enrollImageData ? "enrolled" : "not-enrolled");
    }
  }, [enrollImageData, enrollStatus]);
  useEffect(() => {
    if (enrollStatus === "not-enrolled") {
      setEnrollImageData(undefined);
      setEnrollDeviceId(undefined);
    }
  }, [enrollStatus]);

  let driverValues = watch();

  const { data: driverByDriverId, refetch } = useQueryDriver(
    { fleetId: selectedFleet.id!, tspId: selectedFleet.tspId!, driverId: driverValues?.driverId },
    !!driverValues?.driverId && !isEdit
  );


  const onClickCapture = useCallback(async () => {
    let driverId = getValues("driverId");
    if (enrollDeviceId && driverId) {
      setEnrollStatus("enrolling");
      await takeDriverFacePhoto({ deviceId: enrollDeviceId, driverId });
      setEnrollStatus("enrolled");
    }
  }, [takeDriverFacePhoto, enrollDeviceId, getValues]);

  function OnClickOnX() {
    if (isInEnrollProcess()) {
      setEnrollStatus("none");
      return;
    }
    onClickClose();
  }
  const devices = useMemo(() => {
    if (deviceData?.data && driverDeviceData) {
      let devs = deviceData?.data.map((d) => {
        const ret = {
          ...d,
          existInDriver: driverDeviceData && driverDeviceData.findIndex((dr) => dr.deviceId === d.deviceId) > -1,
        };
        return ret as DriverDeviceEntry;
      });

      return { ...deviceData, data: devs };
    }
  }, [deviceData, driverDeviceData]);
  const hasDevices = useMemo(() => driverDeviceData && driverDeviceData.length > 0, [driverDeviceData]);

  const { queryParams, setPageIndex } = useVehiclesQueryParamsContext();
  const onClickAddDriverDevice = async (device: DriverDeviceEntry) => {
    if (driver) {
      await addDriverToDevice({
        deviceId: device.deviceId!,
        driverId: driver.driverId,
        fleetId: driver.fleetId,
        tspId: driver.tspId,
      });
    }
  };
  const onClickRemoveDriverDevice = async (device: DriverDeviceEntry) => {
    if (driver) {
      await removeDriverFromDevice({
        deviceId: device.deviceId!,
        driverId: driver.driverId,
        fleetId: driver.fleetId,
        tspId: driver.tspId,
      });
    }
  };
  const onSubmit = async ({ driverId, name, profilePhoto, vectorPhoto }: DriverCardFormInputs) => {
    if (isEdit) {
      await updateDriver({
        driverId: driver?.driverId!,
        name,
        image: !profilePhoto?.includes(",") ? profilePhoto : stripBase64Prefix(profilePhoto ?? ""),
        vector: vectorPhoto,
        fleetId: driver?.fleetId!,
        tspId: driver?.tspId!,
      });
    } else {
      var fleet = selected as Fleet;
      await addDriver({
        driverId,
        name,
        image: profilePhoto,
        vector: vectorPhoto,
        fleetId: fleet.id!,
        tspId: fleet.tspId!,
      });
      setDriver({ driverId, fleetId: fleet.id!, tspId: fleet.tspId! });
    }
  };

  const onClickSaveEnrollment = () => {
    setValue("vectorPhoto", enrollImageData?.vectorImage);
    setEnrollStatus("saved");
  };

  const isInEnrollProcess = (): boolean => {
    return enrollStatus !== "none" && enrollStatus !== "saved";
  };

  async function onDriverIdBlur(): Promise<void> {
    clearErrors("driverId");
    if (driverValues?.driverId) {
      await refetch();
      if (driverByDriverId?.length) {
        setError("driverId", { message: "DRIVER_EXIST" });
      }
    }
  }

  return (
    <FormModal
      show={show}
      id="addDriverModal"
      handleClose={OnClickOnX}
      onSubmit={handleSubmit(onSubmit)}
      classes={formModalClasses}
      header={
        <>
          <>
            <AddDriverIcon />
            <div className="p-2">
              <FormattedMessage id="DRIVER_CRAD" defaultMessage="Driver card" />
            </div>
          </>
          {enrollStatus !== "none" && (
            <>
              <AddDriverIcon style={{ marginLeft: "8rem" }} />
              <div className="p-2">
                <FormattedMessage id="ENROLL_DRIVER" defaultMessage="Enroll driver" />
              </div>
            </>
          )}
        </>
      }
      footer={
        isInEnrollProcess() ? (
          <div className="d-flex flex-column align-items-left justify-content-end gap-2 w-100">
            <div className="d-flex align-items-left justify-content-end gap-2">
              <ModalSecondaryButton type="button" onClick={() => setEnrollStatus("none")}>
                <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
              </ModalSecondaryButton>
              <ModalSecondaryButton
                type="button"
                className={`action-btn px-4 ${
                  enrollStatus === "not-enrolled" ? "w-50 " + style["capture-button"] : ""
                }`}
                disabled={!enrollDeviceId || !getValues("driverId") || enrollStatus === "enrolling"}
                onClick={() => onClickCapture()}
              >
                <CaptureIcon fill={Color.CIPIA_BLUE} />
                {enrollStatus === "enrolling" && (
                  <>
                    <FormattedMessage id="CPTURING" defaultMessage="Capturing" />
                    ...
                  </>
                )}
                {enrollStatus === "not-enrolled" && (
                  <>
                    <FormattedMessage id="CAPTURE" defaultMessage="Capture" />
                  </>
                )}
                {enrollStatus === "enrolled" && (
                  <>
                    <FormattedMessage id="RECAPTURE" defaultMessage="ReCapture" />
                  </>
                )}
              </ModalSecondaryButton>
              {enrollStatus === "enrolled" && (
                <ModalMainButton
                  style={{ marginRight: "10%" }}
                  icon={<CheckVIcon fill={Color.DARKER_GREEN} />}
                  type="button"
                  onClick={() => onClickSaveEnrollment()}
                >
                  <FormattedMessage id="CREATE" defaultMessage="Create" />
                </ModalMainButton>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-right gap-2">
            <ModalSecondaryButton type="button" onClick={onClickClose}>
              <FormattedMessage id="CLOSE" defaultMessage="Close" />
            </ModalSecondaryButton>
          </div>
        )
      }
    >
      <div className="d-flex justify-content-between align-items-stretch gap-4">
        <div className="d-flex flex-column">
          <div className="p-2">
            <ProfilePhotoUpload
              image={getValues("profilePhoto") && getValues("profilePhoto")}
              disabled={isAddLoading || !driverValues.driverId}
              onChange={async (img) => {
                if (img.file) {
                  setValue("profilePhoto", stripBase64Prefix(await toBase64(img.file)));
                }
              }}
            />
          </div>
          <div className="p-2">
            <EnrollDriver
              driverEnrolled={!!getValues("vectorPhoto")}
              disabled={isAddLoading || !driverValues.driverId}
              onClick={() => setEnrollStatus("not-enrolled")}
              enrollStatus={enrollStatus}
            />
          </div>
          <FormGroup
            label={<FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />}
            key="driverId"
            className="p-2"
            input={
              <FormInput
                type="text"
                name="driverId"
                control={control}

                rules={{ required: true }}
                input={{ disabled: isEdit, onBlur: () => onDriverIdBlur() }}
              />
            }
          />
          {errors.driverId && (
            <div className="text-danger ps-2">
              <FormattedMessage id={errors.driverId.message} defaultMessage="Driver Exist" />
            </div>
          )}
          <FormGroup
            label={<FormattedMessage id="DRIVER_NAME" defaultMessage="Driver name" />}
            key="name"
            className="p-2"
            input={
              <FormInput
                type="text"
                name="name"
                control={control}
                rules={{ required: true }}
                input={{ disabled: !driverValues.driverId }}
              />
            }
          />
          <ModalMainButton
            className="w-50"
            style={{ margin: "1rem 0 0 7rem" }}
            icon={<CheckVIcon fill={Color.DARKER_GREEN} />}
            type="submit"
          >
            <FormattedMessage id="SAVE" defaultMessage="Save" />
          </ModalMainButton>
        </div>
        <div className={enrollStatus === "not-enrolled" ? "w-100" : "d-flex flex-fill"}>
          {isInEnrollProcess() && (
            <div className="h-100 w-100">
              <hr className={style["line"]}></hr>
              <div className="h-100 d-flex justify-content-center" style={{ marginLeft: "-8px" }}>
                <div className="m-2 w-75">
                  <CaptureDriver
                    photo={enrollImageData?.pngImage}
                    onDeviceSelected={(deviceId) => setEnrollDeviceId(deviceId)}
                  />
                </div>
              </div>
            </div>
          )}
          {!isInEnrollProcess() && (
            <>
              <div className="w-50 p-2">
                <div className="p-2" style={{ borderBottom: `2px solid ${Color.GRAY_5}` }}>
                  <FormattedMessage id="ASSIGNED_TO_VEHICLES" defaultMessage="Assigned to vehicles" />
                </div>
                <div className="w-100">
                  {hasDevices && (
                    <DriverDeviceTable
                      buttonIcon={<RemoveIcon style={{ maxWidth: "24px", maxHeight: "24px" }} />}
                      buttonLabel={<FormattedMessage id="REMOVE" defaultMessage="Remove" />}
                      onClick={onClickRemoveDriverDevice}
                      data={!driver ? undefined : driverDeviceData}
                      isError={isErrorDevices}
                      isLoading={isLoadingDevices}
                      isFetching={isFetchingDevices}
                    />
                  )}
                </div>
                {!hasDevices && (
                  <div className="p-2" style={{ color: Color.GRAY_5 }}>
                    <FormattedMessage
                      id="LIST_OF_VEHICLES"
                      defaultMessage="A list of vehicles that this driver is assigned to"
                    />
                  </div>
                )}
              </div>
              {driver && (
                <div className="w-50 p-2" style={{ borderLeft: `2px solid ${Color.GRAY_5}` }}>
                  <FormattedMessage
                    id="TO_ASSIGN_THE_DRIVER"
                    defaultMessage="To assign the driver to more vehicles, add drivers' profile to a vehicle"
                  />
                  <div>
                    <SearchBox
                      defaultPlaceHolderMessage="Type Device Id or Vehicle License plate"
                      placeHolderId="SEARCH_DEVICE_BOX"
                      onChange={(e) => setDeviceFilter(e.target.value)}
                    />
                  </div>
                  {
                    <>
                      <DriverDeviceTable
                        buttonIcon={<AddIcon />}
                        buttonLabel={<FormattedMessage id="ADD" defaultMessage="Add" />}
                        onClick={onClickAddDriverDevice}
                        data={!driver ? undefined : devices?.data}
                        isError={isErrorDevices}
                        isLoading={isLoadingDevices}
                        isFetching={isFetchingDevices}
                      />
                      <Pagination
                        className="mt-auto"
                        debouncedPageIndex={queryParams.paging.pageIndex}
                        onChangePageIndex={setPageIndex}
                        pageCount={devices?.pageCount}
                        allDisabled={isFetchingDevices}
                        totalEntries={devices?.totalCount}
                      />
                    </>
                  }
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </FormModal>
  );
}

export default DriverCardModal;
