import { PropsWithChildren } from "react";
import useSort from "../../hooks/useSort";
import SortArrow from "../../icons/SortArrow";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";
/*
Renders "children" with addition of arrow indicator, and provides sorting functionality on click.
Requires QueryParamsContext.
 */
type SortingHeaderContainerProps<SortParamName extends string> = {
  context: React.Context<IQueryParamsContext<any, any>>,
  paramName: SortParamName;
} & PropsWithChildren;

function SortingHeaderContainer<SortParamName extends string>({
  context,
  paramName,
  children,
}: SortingHeaderContainerProps<SortParamName>) {
  const { status, setSort } = useSort<SortParamName>(paramName, context);

  return (
    <div className="d-flex align-center cursor-pointer" style={{ width: "fit-content" }} onClick={setSort}>
      <div>{children}</div>
      <div>
        <SortArrow direction={status === "ASCENDING" ? "UP" : "DOWN"} opacity={status === "INACTIVE" ? 0 : 100} />
      </div>
    </div>
  );
}

export default SortingHeaderContainer;
