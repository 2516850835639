import { FormattedMessage } from "react-intl";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import { Color } from "../../../constants";
import { ReactComponent as CaptureIcon } from "../../../assets/icons/capture.svg";
import { ReactComponent as DriverBackground } from "../../../assets/DriverBackground.svg";
import { ReactComponent as IconAwesomeCheck } from "../../../assets/IconAwesomeCheck.svg";
import { Button } from "react-bootstrap";
import { EnrollStatus } from "./DriverCardModal";

interface EnrollDriverProps {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  enrollStatus?: EnrollStatus;
  driverEnrolled : boolean
}

function EnrollDriver({ onClick, disabled, enrollStatus, driverEnrolled }: EnrollDriverProps) {
  
  const vectorExist = (): boolean => {
    return (enrollStatus === "saved" || enrollStatus === "none") && driverEnrolled

  }

  return (
    <div
      className="align-self-end rounded"
      style={{ height: "12rem", width: "14rem", border: `2px solid ${Color.LIGHT_GRAY_5}` }}
    >
      <div className="d-flex justify-content-center h-100">
        <div className="position-relative">
          <div style={{ backgroundColor: Color.LIGHT_LIGHT_GRAY, maxWidth: "100%", maxHeight: "100%" }}>
            <DriverBackground
              className="mx-auto"
              style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "-0.5rem" }}
            />
          </div>
          {!vectorExist() && (
            <div className="position-absolute top-0 start-0 d-flex align-items-start flex-column h-100 mr-auto">
              <div className="mx-auto mb-auto text-center">
                <FormattedMessage id="DRIVER_IDENTIFICATION" defaultMessage="Driver identification" />
              </div>
              <ModalMainButton
                type="button"
                className="mx-auto"
                style={{ backgroundColor: Color.WHITE }}
                disabled={disabled}
                onClick={onClick}
                icon={<CaptureIcon fill={Color.CIPIA_BLUE} />}
              >
                <FormattedMessage id="ENROLL_DRIVER" defaultMessage="Enroll driver" />
              </ModalMainButton>
              <div className="mx-auto mt-auto" style={{ padding: "0 1.5rem ", fontSize: "13px", fontWeight: 500 }}>
                <FormattedMessage
                  id="ENROLL_INFO"
                  defaultMessage="ⓘ Enroll the driver with the system for automatic driver identification"
                />
              </div>
            </div>
          )}
          {enrollStatus !== "none" && enrollStatus !== "saved" && (
            
            <div
              className="position-absolute"
              style={{
                left: "-4px",
                top: "-2px",
                height: "102%",
                width: "115%",
                borderRadius: "0.4rem 0 0 0.4rem",
                backgroundColor: Color.CIPIA_BLUE,
              }}
            >
              <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "5rem" }}>
                <CaptureIcon fill={Color.WHITE} />
                <div style={{ marginLeft: "0.5rem", color: Color.WHITE, fontSize: "18px" }}>
                  <FormattedMessage id="ENROLL_DRIVER" defaultMessage="Enroll driver" />
                </div>
              </div>
            </div>
          )}
          {vectorExist() && (
            <div className="position-absolute w-100 top-0 start-0 d-flex align-items-start flex-column h-100 mr-auto">
              <div className="mx-auto mb-auto text-center">
                <FormattedMessage id="DRIVER_IDENTIFICATION" defaultMessage="Driver identification" />
              </div>
              <IconAwesomeCheck className="mx-auto" />
              <div className="mx-auto" style={{ padding: "0 1.5rem ", fontWeight: 500 }}>
                <FormattedMessage id="DRIVER_ENROLLED" defaultMessage="The driver is enrolled" />
              </div>
              <div className="w-100 position-relative">
                <Button className="mx-auto mt-auto float-end" variant="link" onClick={onClick}>
                  <CaptureIcon fill={Color.CIPIA_BLUE} />
                  <FormattedMessage id="RE-ENROLL" defaultMessage="Re-enroll" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EnrollDriver;
