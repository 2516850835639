import { useMemo } from "react";
import { DateTimeFormat, Measurement, Role } from "../api/data-contracts";
import { Constants } from "../constants";
import { useAuthContext } from "../context/AuthContext";
import { LocaleType } from "../context/IntlContext";
import { useTspFleetContext } from "../context/TspFleetContext/TspFleetContext";
import { isCipia } from "../context/TspFleetContext/tsp-fleet-context.util";
import { getIsFleetRole } from "../util/roles.util";
import useQueryOwnFleet from "./useQueryOwnFleet";


export const useProfile = () => {
  const { loggedUser } = useAuthContext();
  const { selected, activeTsp } = useTspFleetContext();
  const { data: ownFleet } = useQueryOwnFleet();

 var newProfile =  useMemo(() => {
    let dtFormat: DateTimeFormat | undefined = undefined;
    let language: LocaleType | undefined = undefined;
    let measurement: Measurement | undefined = undefined;

    dtFormat = loggedUser?.profile?.dateTimeFormat;
    language = loggedUser?.profile?.isoLanguage as LocaleType | undefined;
    measurement = loggedUser?.profile?.measurement;

    if (loggedUser?.role === Role.CipiaAdministrator && selected && !isCipia(selected)) {
      if (!dtFormat) dtFormat = selected?.defaultDateTimeFormat;
      if (!language) language = selected?.defaultLanguage as LocaleType | undefined;
      if (!measurement) measurement = selected?.defaultMeasurement;
    } else if (getIsFleetRole(loggedUser?.role)) {
      if (!dtFormat) dtFormat = ownFleet?.defaultDateTimeFormat;
      if (!language) language = ownFleet?.defaultLanguage as LocaleType | undefined;
      if (!measurement) measurement = ownFleet?.defaultMeasurement;
    } else {
      if (!dtFormat) dtFormat = activeTsp?.defaultDateTimeFormat;
      if (!language) language = activeTsp?.defaultLanguage as LocaleType | undefined;
      if (!measurement) measurement = activeTsp?.defaultMeasurement;
    }
    if (!dtFormat) dtFormat = Constants.DEFAULT_BACK_DT_FORMAT;
    if (!language) language = "en-US";
    if (!measurement) measurement = Measurement.Metric;

    return { dtFormat, language, measurement };
    
  }, [loggedUser, selected, ownFleet, activeTsp]);
  return newProfile
};
