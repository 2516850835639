import { ReactNode, Ref } from "react";
import { GroupBase, Props } from "react-select";
import ReactSelectType from "react-select/dist/declarations/src/Select";
import Select from "react-select";

export type ReactSelectRef<
  Option extends BaseOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Ref<ReactSelectType<Option, IsMulti, Group>>;

export interface BaseOption {
  value: unknown;
  label: ReactNode;
}

export interface StandardOption {
  value: string;
  label: string;
}

type ReactSelectProps<
  Option extends BaseOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  selectRef?: ReactSelectRef<Option, IsMulti, Group>;
};

const ReactSelect = <
  Option extends BaseOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  selectRef,
  ...rest
}: ReactSelectProps<Option, IsMulti, Group>) => {
  return <Select {...rest} ref={selectRef} />;
};

export default ReactSelect;
