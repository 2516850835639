import { useState } from "react";
import ReactImageUploading, { ImageType } from "react-images-uploading";
import { FormattedMessage } from "react-intl";
// import styles from "./ProfilePhotoUpload.module.css";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import { Color } from "../../../constants";
import { ReactComponent as DriverPhoto } from "../../../assets/icons/DriverPhoto.svg";
import { ReactComponent as UploadDriverPhoto } from "../../../assets/UploadDriverPhoto.svg";
import { Button } from "../../Button/Button";

interface ProfilePhotoUploadProps {
  onChange?: (img: ImageType) => void;
  disabled?: boolean;
  className?: string;
  image?: string
}

function ProfilePhotoUpload({ onChange, disabled, className, image }: ProfilePhotoUploadProps) {
  const [img, setImg] = useState<ImageType>();

  return (
    <ReactImageUploading
      value={img ? [img] : []}
      onChange={(images) => {
        const image = images[0];
        setImg(image);
        onChange?.(image);
      }}
    >
      {({ imageList, onImageUpload }) => {
        return (
          <div
            className="align-self-end rounded"
            style={{ height: "12rem", width: "14rem", border: `2px solid ${Color.LIGHT_GRAY_5}` }}
          >
            <div className="d-flex h-100">
              <div className="position-relative">
                {imageList[0] || image ? (
                  <>
                    <div className="d-flex flex-column" style={{ height: "10rem" }}>
                      <img className="mw-100 mh-100 align-self-center" alt="img-upload" src={imageList[0]?.dataURL || image} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="p-1" style={{ fontSize: "14px" }}>
                        <FormattedMessage id="PROFILE_PICTURE" defaultMessage="Profile picture" />
                      </div>
                      <Button className="p-0" style={{ fontSize: "14px" }} variant="link" onClick={onImageUpload}>
                        <DriverPhoto fill={Color.CIPIA_BLUE} />
                        <FormattedMessage id="CHANGE_PHOTO" defaultMessage="Change photo" />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ backgroundColor: Color.LIGHT_LIGHT_GRAY, maxWidth: "100%", maxHeight: "100%" }}>
                      <UploadDriverPhoto style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "-1rem" }} />
                    </div>
                    <div className="position-absolute top-0 start-0 d-flex align-items-start flex-column h-100 mr-auto">
                      <div className="mx-auto mb-auto text-center">
                        <FormattedMessage id="PROFILE_PHOTO" defaultMessage="Driver photo" />
                      </div>
                      <ModalMainButton
                        type="button"
                        className="mx-auto"
                        style={{ backgroundColor: Color.WHITE }}
                        disabled={disabled}
                        onClick={onImageUpload}
                        icon={<DriverPhoto fill={Color.CIPIA_BLUE} />}
                      >
                        <FormattedMessage id="UPLOAD_PHOTO" defaultMessage="Upload photo" />
                      </ModalMainButton>
                      <div
                        className="mx-auto mt-auto"
                        style={{ padding: "0 1.5rem ", fontSize: "13px", fontWeight: 500 }}
                      >
                        <FormattedMessage
                          id="DRIVER_PHOTO_INFO"
                          defaultMessage="ⓘ This photo will be shown in drivers and vehicles tables"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </ReactImageUploading>
  );
}

export default ProfilePhotoUpload;
