import { Outlet } from "react-router-dom";
import styles from "./AuthLayout.module.scss";
import { ReactComponent as LoginHeaderPic } from "../../assets//LoginHeader.svg";

function AuthLayout() {
  return (
    <div className={`${styles.wrapper} vh-100 overflow-auto d-flex align-items-center justify-content-center`}>
      <div className={`${styles.container} d-flex flex-column align-items-center justify-content-center gap-4`}>
        <LoginHeaderPic />
        <div className={`${styles["inner-container"]}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
