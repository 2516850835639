import styles from "./UsersTableController.module.css";
import { FormattedMessage } from "react-intl";
import addIconPath from "../../assets/icons/Add.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Close.svg";
import { Color } from "../../constants";
import ActionButton from "../Buttons/ActionButton/ActionButton";

interface UsersControllerProps {
  onClickAdd: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  usersCount?: number;
  disable?: {
    add?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
}

function UsersTableController({
  onClickAdd,
  onClickEdit,
  onClickDelete,
  usersCount,
  disable,
}: UsersControllerProps) {
  return (
    <div className="d-flex align-items-center gap-2 py-3">
      <div className="header-font me-5">
        <FormattedMessage id="USERS" defaultMessage="Users" />
        {` (${usersCount || 0})`}
      </div>
      <ActionButton
        icon={<img src={addIconPath} className={styles.btn_img} alt="add" />}
        onClick={onClickAdd}
        disabled={disable?.add}
      >
        <FormattedMessage id="ADD" defaultMessage="Add" />
      </ActionButton>

      <ActionButton icon={<EditIcon fill={Color.CIPIA_BLUE} />} onClick={onClickEdit} disabled={disable?.edit}>
        <FormattedMessage id="EDIT" defaultMessage="Edit" />
      </ActionButton>

      <ActionButton icon={<DeleteIcon fill={Color.CIPIA_BLUE} />} onClick={onClickDelete} disabled={disable?.delete}>
        <FormattedMessage id="DELETE" defaultMessage="Delete" />
      </ActionButton>
    </div>
  );
}

export default UsersTableController;
