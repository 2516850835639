import { useQuery } from "@tanstack/react-query";
import { GetConfigResult } from "../services/configuration.service";
import { configurationQuery } from "../queries/configuration.query";
import { ServiceGetResult } from "../models/requests/shared.request";
import { useConfigQueryParamsContext } from "../context/QueryParamsContext/ConfigQueryParamsContextProvider";
import { useEffect } from "react";
import useFilterByTspFleetContext from "./useFilterByTspFleetContext";

function useQueryConfiguration<selectedT extends ServiceGetResult<unknown>>(
  selector: (queryResult: GetConfigResult) => selectedT
) {
  const { queryParams, setPageIndex, upsertFilter,removeFilter } = useConfigQueryParamsContext();
  
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(upsertFilter,removeFilter,queryParams.filters);
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  const { data, isLoading, isError, isFetching } = useQuery(configurationQuery(queryParams, selector,true));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: showData ? data : undefined,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
  };
}

export default useQueryConfiguration;
