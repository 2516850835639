import { Constants } from "../constants";

export type UpdateLocalStorageEvent<T = undefined> = Event & {
  key: string;
  updatedValue: T;
};

export default class LocalStorage {
  static get<T>(key: string): T | undefined {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : undefined;
  }

  static set<T>(key: string, value: T): void {
    const event = new Event(
      Constants.UpdateLocalStorageEventKey
    ) as UpdateLocalStorageEvent<T>;

    event.updatedValue = value;
    event.key = key;

    document.dispatchEvent(event);

    window.localStorage.setItem(key, JSON.stringify(value));
  }

  static remove(keys: string[]): void {
    keys.forEach((key) => {
      const event = new Event(
        Constants.UpdateLocalStorageEventKey
      ) as UpdateLocalStorageEvent;

      event.key = key;

      document.dispatchEvent(event);
      window.localStorage.removeItem(key);
    });
  }
}
