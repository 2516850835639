import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ReactNode } from "react";
import ModalSecondaryButton from "../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ModalMainButton from "../Buttons/ModalMainButton/ModalMainButton";
import { Color } from "../../constants";

interface AreYouSureModalProps {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  onHide: () => void;
  body: ReactNode;
  isLoading?: boolean;
}

function ModalYesNo({ show, onClickNo, onClickYes, onHide, body, isLoading }: AreYouSureModalProps) {
  return (
    <Modal centered show={show} onHide={() => !isLoading && onHide()} dialogClassName="modal-yes-no-dialog">
      <ModalHeader closeButton className="border-bottom-0"></ModalHeader>
      <ModalBody>
        <div className="text-center pb-5 px-3" style={{ fontSize: "1.875rem" }}>
          {body}
        </div>
      </ModalBody>
      <ModalFooter className="py-1" style={{ backgroundColor: Color.LIGHT_LIGHT_GRAY }}>
        <div className="d-flex align-items-center gap-2">
          <ModalSecondaryButton
            onClick={onClickNo}
            disabled={isLoading}
            style={{ paddingLeft: "2.25rem", paddingRight: "2.25rem" }}
          >
            <FormattedMessage id="NO" defaultMessage="No" />
          </ModalSecondaryButton>
          <ModalMainButton
            onClick={onClickYes}
            disabled={isLoading}
            style={{ paddingLeft: "2.25rem", paddingRight: "2.25rem" }}
          >
            <FormattedMessage id="YES" defaultMessage="Yes" />
          </ModalMainButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ModalYesNo;
