import RefreshButton from "../Buttons/RefreshButton/RefreshButton";
import { FormattedMessage } from "react-intl";
import { useLastUpdateContext } from "../../context/LastUpdateContext";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";

function RefreshLastUpdate() {
  const { time, invalidateQueries } = useLastUpdateContext();
  const { getFormattedLocalDate } = useFormattedLocalDate();

  return (
    <div className="d-flex align-items-center gap-2">
      <RefreshButton onClick={() => invalidateQueries()} />
      <div>
        <FormattedMessage id="LAST_UPDATE" defaultMessage="Last update" />
        {": "}
        {getFormattedLocalDate(time)}
      </div>
    </div>
  );
}

export default RefreshLastUpdate;
