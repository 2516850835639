import { ReactNode } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Constants, InputUi } from "../../constants";
import { SxProps } from "@mui/material";
import DateTime from "../../assets/DateTime.svg";
import { Dayjs } from "dayjs";
import { useProfile } from "../../hooks/useProfile";
import { getFrontDtFormat } from "../../util/date.util";

const sx: SxProps = {
  "& .MuiInputBase-root": {
    height: InputUi.HEIGHT,
    "& input": {
      fontSize: "0.875rem",
      letterSpacing: "normal",
    },
  },
  "& .MuiInputAdornment-root": {
    marginLeft: "0",
  },
};

/* This component renders 2 DateTimePicker components,
 one of them for the "from" filter, the second for the "to"
 it requires being in  QueryParamsContext, and it changes the filter in the context according to user input*/
type DateTimeRangeFilterProps = {
  className?: string;
  fromLabel?: ReactNode;
  toLabel?: ReactNode;
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
  onChangeFrom: (dt: Dayjs | null) => void;
  onChangeTo: (dt: Dayjs | null) => void;
};

const DateTimeRangeFilter = ({
   
  className,
  fromLabel,
  toLabel,
  fromDate,
  toDate,
  onChangeFrom,
  onChangeTo,
}: DateTimeRangeFilterProps) => {
  const { dtFormat } = useProfile();

  const format = getFrontDtFormat(dtFormat)?.timeDate ?? Constants.DEFAULT_FRONT_DT_FORMAT.timeDate;

  return (
    <div className={`d-flex gap-2 align-items-center ${className}`}>
      <div className="input-with-label-container">
        <div className="input-label">{fromLabel}</div>
        <DateTimePicker
          closeOnSelect={false}
          slots={{
            openPickerIcon: () => <img src={DateTime} alt="date-time"></img>,
          }}
          label=" "
          format={format}
          sx={sx}
          value={fromDate}
          onChange={(dt) => {
            onChangeFrom(dt);
          }}
        />
      </div>
      <div className="input-with-label-container">
        <div className="input-label">{toLabel}</div>
        <DateTimePicker
          closeOnSelect={false}
          slots={{
            openPickerIcon: () => <img src={DateTime} alt="date-time"></img>,
          }}
          label=" "
          sx={sx}
          format={format}
          value={toDate}
          onChange={(dt) => {
            onChangeTo(dt);
          }}
          minDate={fromDate}
        />
      </div>
    </div>
  );
};

export default DateTimeRangeFilter;
