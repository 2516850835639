import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetUsersParams, UsersSortFilterParamName } from "../../services/user.service";
import { PropsWithChildren } from "react";

import { Constants } from "../../constants";
import { USERS_CONTEXT } from "./queryParamContextObjects";

export function UsersQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetUsersParams = {
    filters: [],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return <QueryParamsContextProvider context={USERS_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useUsersQueryParamsContext = () =>
  useQueryParamsContext<UsersSortFilterParamName, UsersSortFilterParamName>(USERS_CONTEXT);
