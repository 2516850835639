import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../context/AuthContext";
import { loginMutation } from "../mutations/auth.mutation";
import Logger from "../util/logger.util";
import { useNavigate } from "react-router-dom";
import { HttpStatusCode } from "axios";
import { decodeUserName } from "../util/api.util";
import { useLastUpdateContext } from "../context/LastUpdateContext";
//import { acceptTermsMutation } from "../mutations/user.mutation";
//import { userQuery } from "../queries/user.query";

function useAuthentication() {
  const { set: setLoginDetails, remove: removeLoginDetails, loggedUser } = useAuthContext();
  const { invalidateQueries } = useLastUpdateContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: mutateLogin, isLoading, isError, error } = useMutation(loginMutation());
  //const { mutate: mutateAcceptTerms } = useMutation(acceptTermsMutation());

  async function login(email: string, password: string): Promise<void> {
    mutateLogin(
      { email, password },
      {
        onSuccess: async (response) => {
          Logger.info("login success...");
          const data = response?.data;
          if (!data?.token) {
            return;
          }

          const username = decodeUserName(data.token);

          //TODO: when `termsApproveDate` is a part of the token, we should update accordingly.
          setLoginDetails({ token: data.token, email, username, termsApproveDate: null });

          //const { data: user } = await queryClient.ensureQueryData(userQuery(email, true));
          
          navigate("/");
          // if (user.termsApproveDate) {
          //   navigate("/");
          // }

          queryClient.clear();
        },
        onError: (error) => {
          Logger.info("login fail " + error.toJSON());
        },
      }
    );
  }

  async function acceptTerms(): Promise<void> {
  //todo when term support remove next line
    return;

    // if (!loggedUser) {
    //   return;
    // }
    // mutateAcceptTerms(loggedUser?.email, {
    //   onSuccess: () => {
    //     navigate("/");
    //   },
    // });
  }

  function logout() {
    invalidateQueries();
    removeLoginDetails();
  }

  const badCredentials = isError && error.response?.status === HttpStatusCode.Unauthorized;

  return { loading: isLoading, login, badCredentials, logout, loggedUser, acceptTerms };
}

export default useAuthentication;
