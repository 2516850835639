import { CSSProperties, useCallback, useEffect, useState } from "react";
import { FilterOperator } from "../../models/requests/shared.request";
import { TextInput } from "../form/TextInput/TextInput";
import useFilter from "../../hooks/useFilter";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";

type TextInputFilterProps<TParamName extends string> = {
  context : React.Context<IQueryParamsContext<any, any>>,
  placeHolder: string;
  paramName: TParamName;
  operator: FilterOperator;
  inputLabel?: string;
  style?: CSSProperties;
  isNumeric?: boolean;
  includeInUrl?: boolean;
};

const TextInputFilter = <TParamName extends string>({
  context,
  placeHolder,
  paramName,
  operator,
  inputLabel,
  style,
  isNumeric,
  includeInUrl,
}: TextInputFilterProps<TParamName>) => {
  const onForcedClear = useCallback(() => {
    setValue(undefined);
  }, []);

  const { upsertOrRemoveFilter, contextValues } = useFilter<TParamName>(
    context,
    paramName,
    operator,
    onForcedClear,
    includeInUrl
  );
  const [value, setValue] = useState<string | undefined>(contextValues[0]);

  useEffect(() => {
    setValue(contextValues[0]);
  }, [contextValues]);

  return (
    <TextInput
      value={value}
      placeholder={placeHolder}
      onChange={(e) => {
        setValue(e.target.value);
        upsertOrRemoveFilter(e.target.value);
      }}
      inputLabel={inputLabel}
      style={style}
      isNumeric={isNumeric}
    />
  );
};

export default TextInputFilter;
