import { CellProps } from "react-table";
import { FleetEntry } from "../FleetView/FleetView";
import { PropsWithChildren } from "react";

interface CellContainerProps {
  cellProps: CellProps<FleetEntry>;
}

/* UI container for cell content of Fleet table */
function CellContainer({
  children,
  cellProps: { row, column, visibleColumns },
}: PropsWithChildren<CellContainerProps>) {
  const colId = column.id;
  const isFirstCol = visibleColumns[0].id === colId;
  const isLastCol = visibleColumns[visibleColumns.length - 1].id === colId;

  let wrapperClassName = `h-100`;
  let innerClassName = "p-1 cursor-pointer d-flex align-items-center w-100";
  if (isFirstCol) innerClassName += " ps-2";

  if (row.isExpanded) {
    wrapperClassName += " pt-2";
    innerClassName += " selected-row-bg";
    if (isFirstCol) {
      wrapperClassName += " ps-1";
      innerClassName += " rounded-top-left box-shadow-no-right";
    }
    if (isLastCol) {
      wrapperClassName += " pe-1";
      innerClassName += " rounded-top-right box-shadow-no-left";
    }
  }

  return (
    <div className={wrapperClassName}>
      <div className={innerClassName} style={{ height: "4.25rem" }}>
        <div className="text-truncate" style={{ maxWidth: "7rem", width: "100%" }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CellContainer;
