/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GetListResponseBillingReport } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Reports<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description The API retrieves a list of billing report.
   *
   * @tags Reports
   * @name GetBillingReport
   * @summary Get billing report
   * @request GET:/reports/billing
   * @secure
   */
  getBillingReport = (
    query: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
      /**
       * start date
       * @format date-time
       */
      from: string;
      /**
       * end date
       * @format date-time
       */
      to?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseBillingReport, void>({
      path: `/reports/billing`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
