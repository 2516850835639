import { FilterParam, QueryAction, QueryParams, SortParam } from "../../models/requests/shared.request";
import { areFiltersDistinct, compareFiltersUniqueConstraint, getFormattedFilterParam } from "../filtering.util";

export function commonQueryReducer<Sort extends SortParam, Filter extends FilterParam>(
  state: QueryParams<Sort, Filter>,
  action: QueryAction<Sort, Filter>
): QueryParams<Sort, Filter> {
  switch (action.type) {
    case "SET_PAGE_INDEX":
      return {
        ...state,
        paging: { ...state.paging, pageIndex: action.payload },
      };
    case "SET_PAGESIZE":
      return {
        ...state,
        paging: { ...state.paging, pageSize: action.payload },
      };
    case "SET_SORT":
      return { ...state, sorts: action.payload };
    case "UPSERT_FILTER":
      const newFilterParams = state.filters.filter((filterParam) => areFiltersDistinct(filterParam, action.payload));
      newFilterParams.push(getFormattedFilterParam(action.payload));
      newFilterParams.sort((filter1, filter2) => compareFiltersUniqueConstraint(filter1, filter2));
      return {
        ...state,
        filters: newFilterParams,
        paging: { ...state.paging, pageIndex: 1 },
      };
    case "REMOVE_FILTER":
      const newFilters = state.filters.filter((filterParam) => areFiltersDistinct(filterParam, action.payload));
      return {
        ...state,
        filters: newFilters,
        paging: { ...state.paging, pageIndex: 1 },
      };
    case "CLEAR_FILTERS":
      const { exceptions } = action.payload;
      return {
        ...state,
        filters: state.filters.filter((filterParam) =>
          exceptions.some((exceptFilter) => !areFiltersDistinct(filterParam, exceptFilter))
        ),
        paging: { ...state.paging, pageIndex: 1 },
      };
    default:
      return state;
  }
}
