import { FormattedMessage } from "react-intl";
import { ReactComponent as DeviceIcon } from "../../../assets/icons/MuteIcon.svg";
import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { Color } from "../../../constants";

interface UnassignModalProps {
  show: boolean;
  multiple: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
}

function MuteModal({ show, multiple, onClickNo, onClickYes }: UnassignModalProps) {
  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={onClickYes}
      onHide={onClickNo}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <DeviceIcon fill={Color.CIPIA_BLUE} />
          <FormattedMessage
            id="Vehicles.MUTE_QUESTION"
            values={{ multiple: multiple ? "devices" : "device" }}
            defaultMessage="Are you sure you want to mute selected device?"
          />
        </div>
      }
    />
  );
}

export default MuteModal;
