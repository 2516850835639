import Pagination from "../Table/Pagination";
import { useDevicesQueryParamsContext } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import useQueryDevices from "../../hooks/useQueryDevices";
import { devicesToDevicesEntries, generateColumns } from "./devices-table.util";
import { useMemo, useReducer, useState } from "react";
import { Row } from "react-table";
import Table from "../Table/Table";
import { EMPTY_ARR } from "../../util/shared.util";
import styles from "./DevicesTable.module.scss";
import { ReactComponent as EnableIcon } from "../../assets/icons/Enable.svg";
import { ReactComponent as DisableIcon } from "../../assets/icons/Disable.svg";
import DevicesTableHeader from "./DevicesTableHeader";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import ModalYesNo from "../ModalYesNo/ModalYesNo";
import { FormattedMessage } from "react-intl";
import useMutationDevice from "../../hooks/useMutationDevice";
import { Status } from "../../icons/StatusIcon";
import MuteModal from "../VehiclesTable/modals/MuteModal";
import UnpairModal from "../VehiclesTable/modals/UnpairModal";
import { useAuthContext } from "../../context/AuthContext";

type ModalName = "ENABLE" | "DISABLE" | "MUTE" | "UNPAIR";
type ModalData = DeviceEntry | DeviceEntry[];

export interface DeviceEntry {
  deviceId?: string;
  deviceType?: string;
  assignmentDate?: string | null;
  configutarionVersion?: string;
  licensePlate?: string;
  vehicleType?: string;
  status?: Status;
  lastTransmittionDate?: string;
  fullData?: object;
  fleetName?: string;
  isActive?: boolean;
  vehicleId?: string;
  lastVehicleId?: string;
}

function DevicesTable() {
  const [modalState, modalDispatch] = useReducer(modalReducer<ModalName, ModalData>, modalInitialState);
  const showEnableModal = (device: DeviceEntry) =>
    modalDispatch({ type: "SET_SHOW", payload: { modalName: "ENABLE", data: device } });
  const showDisableModal = (device: DeviceEntry) =>
    modalDispatch({ type: "SET_SHOW", payload: { modalName: "DISABLE", data: device } });
  const showUnpairModal = (device: DeviceEntry) =>
    modalDispatch({
      type: "SET_SHOW",
      payload: { modalName: "UNPAIR", data: device },
    });
  const closeModal = () => modalDispatch({ type: "CLEAR" });

  const { queryParams, setPageIndex } = useDevicesQueryParamsContext();
  const { data, isError, isLoading, isFetching } = useQueryDevices(devicesToDevicesEntries, false);
  const [selectedRows, setSelectedRows] = useState<Row<DeviceEntry>[]>([]);
  const { activate, isLoadingActivate, deactivate, isLoadingDeactivate } = useMutationDevice({
    onSuccessActivate: closeModal,
    onSuccessDeactivate: closeModal,
  });
  const { mute } = useMutationDevice();
  const { loggedUser } = useAuthContext();

  const columns = useMemo(() => generateColumns(showEnableModal, showDisableModal, showUnpairModal, loggedUser?.role), [loggedUser?.role]);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        <DevicesTableHeader
          devicesCount={data?.totalCount}
          isMuteDisabled={!selectedRows.length || selectedRows.some((row) => !row.original.vehicleId)}
          onClickMute={() =>
            modalDispatch({
              type: "SET_SHOW",
              payload: { modalName: "MUTE", data: selectedRows.map((row) => row.original) },
            })
          }
        />
        <Table
          data={data?.data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${styles.tr} ${row.isSelected ? styles["selected-row"] : ""}`,
            td: () => `${styles.td}`,
          }}
          isLoading={isLoading}
          isError={isError}
          onChangeSelectedRows={setSelectedRows}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      />
      <ModalYesNo
        show={modalState.shownModal === "ENABLE"}
        onClickYes={() => activate((modalState.data as DeviceEntry)?.deviceId!)}
        onClickNo={closeModal}
        onHide={closeModal}
        isLoading={isLoadingActivate}
        body={
          <div className="d-flex flex-column align-items-center gap-5">
            <EnableIcon transform="scale(2.66)" />
            <FormattedMessage
              id="DEVICES.ENABLE_QUESTION"
              defaultMessage="Enabling this device will continue the data flow. Are you sure you want to enable this Device?"
            />
          </div>
        }
      />
      <ModalYesNo
        show={modalState.shownModal === "DISABLE"}
        onClickYes={() => deactivate((modalState.data as DeviceEntry)?.deviceId!)}
        onClickNo={closeModal}
        onHide={closeModal}
        isLoading={isLoadingDeactivate}
        body={
          <div className="d-flex flex-column align-items-center gap-5">
            <DisableIcon transform="scale(2.66)" />
            <FormattedMessage
              id="DEVICES.DISABLE_QUESTION"
              defaultMessage="Disable device will stop the data from flow. Are you sure you want to disable this Device?"
            />
          </div>
        }
      />
      <MuteModal
        show={modalState.shownModal === "MUTE"}
        multiple={(modalState.data as DeviceEntry[])?.length > 1}
        onClickNo={() => modalDispatch({ type: "CLEAR" })}
        onClickYes={() => {
          (modalState.data as DeviceEntry[])
            .filter((device) => device.isActive && device.vehicleId && !device.lastVehicleId)
            .forEach((entry) => mute({ deviceId: entry.deviceId! }));
          modalDispatch({ type: "CLEAR" });
        }}
      />
      <UnpairModal
        show={modalState.shownModal === "UNPAIR"}
        onClickNo={() => modalDispatch({ type: "CLEAR" })}
        onClickYes={() => {
          mute({ deviceId: (modalState.data as DeviceEntry).deviceId!, unpair: true });
          modalDispatch({ type: "CLEAR" });
        }}
      />
    </div>
  );
}

export default DevicesTable;
