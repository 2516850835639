import { useCallback, useEffect, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Constants } from "../constants";
import { IQueryParamsContext, useQueryParamsContext } from "../context/QueryParamsContext/QueryParamsContext";
import { FilterOperator } from "../models/requests/shared.request";
import { areFiltersDistinct } from "../util/filtering.util";
import { EMPTY_ARR } from "../util/shared.util";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

/* onForcedClear should have a stable reference, use useCallback */
function useFilter<ParamName extends string>(
  context : React.Context<IQueryParamsContext<any, any>>,
  paramName: ParamName,
  operator: FilterOperator,
  onForcedClear?: () => void,
  includeInUrl?: boolean,
  urlParamName?: string
) {
  
  const {
    upsertFilter,
    removeFilter,
    queryParams: { filters: contextFilters },
    registerFilter,
    unregisterFilter,
  } = useQueryParamsContext<string, ParamName>(context);
  const upsertOrRemoveFilter = useCallback(
    (value: string | string[] | undefined) => {
      if (Array.isArray(value)) {
        if (value.length) {
          upsertFilter(paramName, operator, value);
        } else {
          removeFilter(paramName, operator);
        }
      } else {
        if (value) {
          upsertFilter(paramName, operator, [value]);
        } else {
          removeFilter(paramName, operator);
        }
      }
    },
    [upsertFilter, removeFilter, paramName, operator]
  );
  const debouncedUpsertOrRemoveFilter = useDebouncedCallback(upsertOrRemoveFilter, Constants.FilterDebounceDelay);

  const contextValues: readonly string[] = useMemo(() => {
    const filter = contextFilters.find((filter) => !areFiltersDistinct(filter, { paramName, operator }));
    return filter?.values ?? EMPTY_ARR;
  }, [paramName, operator, contextFilters]);

  useEffect(() => {
    const filterId = registerFilter({
      distinctors: { paramName, operator, includeInUrl, urlParamName },
      onForcedClear: () => {
        debouncedUpsertOrRemoveFilter.cancel();
        onForcedClear?.();
      },
    });

    return () => unregisterFilter(filterId);
  }, [
    paramName,
    operator,
    registerFilter,
    unregisterFilter,
    debouncedUpsertOrRemoveFilter,
    onForcedClear,
    includeInUrl,
    urlParamName,
  ]);

  let [searchParams] = useSearchParams();

  useEffectOnce(() => {
    const filterInitVal = contextValues[0];

    if (filterInitVal) return;

    // Init filter value from url if no value exists
    const urlFilterInitVal = searchParams.get(urlParamName ?? paramName) ?? "";

    if (!urlFilterInitVal) return;

    upsertFilter(paramName, operator, urlFilterInitVal.split("|"));
  });

  return {
    upsertOrRemoveFilter: debouncedUpsertOrRemoveFilter,
    contextValues,
  };
}

export default useFilter;
