/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChangePassword,
  GetListResponseUser,
  PostUser,
  SetPassword,
  User,
  UserProfile,
  UserWithMoreData,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class UserManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User Management
   * @name GetUsers
   * @summary Get all users- (admin use it)
   * @request GET:/user
   * @secure
   */
  getUsers = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseUser, any>({
      path: `/user`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name ChangePassword
   * @summary Change user password
   * @request PUT:/user/me/password
   * @secure
   */
  changePassword = (data: ChangePassword, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/me/password`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name GetMyUser
   * @summary Get my user
   * @request GET:/user/me
   * @secure
   */
  getMyUser = (params: RequestParams = {}) =>
    this.request<UserWithMoreData, any>({
      path: `/user/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name AddFmUser
   * @summary add or update middleware user. user can update himself or his managed users data.
   * @request POST:/user/{userEmail}
   * @secure
   */
  addFmUser = (userEmail: string, data: PostUser, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/user/${userEmail}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name UpdateFmUser
   * @summary add middleware user. user can update himself or his managed users data.
   * @request PUT:/user/{userEmail}
   * @secure
   */
  updateFmUser = (userEmail: string, data: PostUser, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/user/${userEmail}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name GetUser
   * @summary Get user by Email
   * @request GET:/user/{userEmail}
   * @secure
   */
  getUser = (userEmail: string, params: RequestParams = {}) =>
    this.request<UserWithMoreData, any>({
      path: `/user/${userEmail}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description delete user from middleware
   *
   * @tags User Management
   * @name DeleteUser
   * @request DELETE:/user/{userEmail}
   * @secure
   */
  deleteUser = (userEmail: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${userEmail}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name ResetPassword
   * @summary Set user password
   * @request PUT:/user/{userEmail}/password
   * @secure
   */
  resetPassword = (userEmail: string, data: SetPassword, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${userEmail}/password`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name UpdateProfile
   * @summary Set user profile, user can update his DateTimeFormat
   * @request PUT:/user/{userEmail}/profile
   * @secure
   */
  updateProfile = (userEmail: string, data: UserProfile, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${userEmail}/profile`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User Management
   * @name ApproveTerms
   * @summary User approved terms and condition
   * @request PUT:/user/{userEmail}/termsApproval
   * @secure
   */
  approveTerms = (userEmail: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/${userEmail}/termsApproval`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
