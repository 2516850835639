import { LocaleType } from "../context/IntlContext";

export const toIntlId = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toUpperCase();
};

export const itnlIdToString = (str: string) => {
  const formattedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
};
export const numberFormatter = ( number: number, locale: LocaleType) => {
  return new Intl.NumberFormat(locale).format(number);
};
