import { SVGProps } from "react";
import { Color } from "../constants";

type SortArrowProps = {
  direction?: "UP" | "DOWN";
} & SVGProps<SVGSVGElement>;

function SortArrow({ direction, ...svgProps }: SortArrowProps) {
  const rotation = direction === "UP" ? 180 : 0;
  return (
    <svg
      transform={`rotate(${rotation})`}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M9.36969 10.3588V2.4398C9.36969 2.39801 9.36138 2.35663 9.34524 2.31808C9.3291 2.27954 9.30545 2.24459 9.27567 2.21527C9.24588 2.18595 9.21057 2.16286 9.17177 2.14732C9.13297 2.13179 9.09147 2.12413 9.04969 2.1248H7.58169C7.49814 2.1248 7.41802 2.15798 7.35895 2.21706C7.29988 2.27613 7.26669 2.35625 7.26669 2.4398V10.3588H6.05869C5.93401 10.3585 5.81206 10.3953 5.70832 10.4644C5.60458 10.5336 5.52374 10.632 5.47605 10.7472C5.42836 10.8624 5.41599 10.9892 5.44049 11.1114C5.465 11.2337 5.52528 11.3459 5.61369 11.4338L7.86969 13.6908C7.9281 13.7493 7.99747 13.7957 8.07383 13.8273C8.15019 13.859 8.23203 13.8753 8.31469 13.8753C8.39734 13.8753 8.47919 13.859 8.55555 13.8273C8.6319 13.7957 8.70127 13.7493 8.75969 13.6908L11.0167 11.4338C11.1052 11.3458 11.1655 11.2334 11.19 11.1109C11.2144 10.9885 11.2019 10.8616 11.154 10.7463C11.106 10.631 11.0249 10.5326 10.9208 10.4636C10.8168 10.3946 10.6945 10.3581 10.5697 10.3588H9.36969Z"
        fill={Color.CIPIA_BLUE}
      />
    </svg>
  );
}

export default SortArrow;
