import { AxiosResponse } from "axios";
import { FilterParam, QueryParams, ServiceGetResult } from "../models/requests/shared.request";
import { DeviceConfigs } from "../api/DeviceConfigs";
import { addAuthHandling } from "../util/api.util";
import { AddDeviceConfig, GetDeviceConfig } from "../api/data-contracts";
import { sortParamsToString } from "../util/sorting.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";

export type ConfigSortFilterParamName = "name" | "version" | "creationDate" | "tspId";

  const configurationApi = new DeviceConfigs();
  addAuthHandling(configurationApi);
 
export type ConfigurationSortParam = {
  param: ConfigSortFilterParamName;
  descending?: boolean;
};
export type GetConfigurationParams = QueryParams<ConfigurationSortParam, FilterParam<ConfigSortFilterParamName>>;
export type GetConfigResult = ServiceGetResult<GetDeviceConfig[]>;

export const configurationService = {
  getConfiguration: async ({ paging, sorts, filters }: GetConfigurationParams): Promise<GetConfigResult> => {
     const result = await configurationApi.getDeviceConfigList({
       sorts: sorts.length ? sortParamsToString(sorts) : undefined,
       filters: filters.length ? filterParamsToString(filters) : undefined,
       page: paging.pageIndex,
       pageSize: paging.pageSize,
     });

     const header = result.data.header;
     let pageCount: number | undefined = undefined;
     if (header && header.pageSize && header.totalCount !== undefined) {
       pageCount = getNumberOfPages(header.totalCount, header.pageSize);
     }
    return {
     pageCount,
     data: result.data.data,
     totalCount: header?.totalCount,
    };
  },

  deleteConfig: async (name:string,version:string,tspId:number): Promise<AxiosResponse> => await configurationApi.deleteDeviceConfig(name,version,tspId),
  addConfig: async (devConfig:AddDeviceConfig): Promise<AxiosResponse> => await configurationApi.addDeviceConfig(devConfig),
  disableConfig: async (name:string,version:string,tspId:number): Promise<AxiosResponse> => await configurationApi.disableDeviceConfig(name,version,tspId),
  enableConfig: async (name:string,version:string,tspId:number): Promise<AxiosResponse> => await configurationApi.enableDeviceConfig(name,version,tspId),
};
