/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetEventTypesResponse,
  GetListResponseEventMessage,
  MediaResponse,
  MediaSource,
  MediaType,
  MessageId,
  MessagesId,
  TargetUnit,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Messages<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Messages
   * @name MarkAsRead
   * @summary Mark list of events as read
   * @request POST:/message/read
   * @secure
   */
  markAsRead = (data: MessagesId, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/message/read`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Messages
   * @name GetEventTypes
   * @summary Get list of all event types that are currently stored in server
   * @request GET:/message/eventTypes
   * @secure
   */
  getEventTypes = (params: RequestParams = {}) =>
    this.request<GetEventTypesResponse, void>({
      path: `/message/eventTypes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Messages
   * @name GetEvents
   * @summary Get all events from server according to filter
   * @request GET:/message/events
   * @secure
   */
  getEvents = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
      /**
       * A boolean indicating whether the server should resolve geolocation information
       * to addresses. If true, the server will include address information for each
       * device in the list.
       */
      geocoding?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseEventMessage, void>({
      path: `/message/events`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Messages
   * @name DeleteEvents
   * @summary Delete events (and return data)
   * @request DELETE:/message/events
   * @secure
   */
  deleteEvents = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
      /**
       * A boolean indicating whether the server should resolve geolocation information
       * to addresses. If true, the server will include address information for each
       * device in the list.
       */
      geocoding?: boolean;
      /** By set it to true, delete all the messages, include last one, that match to other conditions. */
      deleteall?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseEventMessage, void>({
      path: `/message/events`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Messages
   * @name GetMediaByDeviceIdAndMessageId
   * @summary Get media by device id and message id
   * @request GET:/message/{type}/{targetUnit}/{unitId}/{messageId}
   * @secure
   */
  getMediaByDeviceIdAndMessageId = (
    messageId: MessageId,
    targetUnit: TargetUnit,
    unitId: string,
    type: MediaType,
    query?: {
      /** indicate if the media is from road or from cabin */
      source?: MediaSource;
      /** message index */
      index?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<MediaResponse, void>({
      path: `/message/${type}/${targetUnit}/${unitId}/${messageId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags Messages
   * @name DeleteMediaItemByUnitAndMessageId
   * @summary Delete image / video by unit id, message id, media source (dms/adas) and number - if there are multiple
   * @request DELETE:/message/{type}/{targetUnit}/{unitId}/{messageId}
   * @secure
   */
  deleteMediaItemByUnitAndMessageId = (
    messageId: MessageId,
    targetUnit: TargetUnit,
    unitId: string,
    type: MediaType,
    query?: {
      /** indicate if the media is from road or from cabin */
      source?: MediaSource;
      /** message index */
      index?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<MediaResponse, void>({
      path: `/message/${type}/${targetUnit}/${unitId}/${messageId}`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "blob",
      ...params,
    });
}
