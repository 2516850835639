interface PhotoProps {
  color?: string;
}

function Photo({ color = "black" }: PhotoProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4446 19.583H4.81958C4.37203 19.583 3.9428 19.4052 3.62634 19.0887C3.30987 18.7723 3.13208 18.3431 3.13208 17.8955V7.77051C3.13208 7.32295 3.30987 6.89373 3.62634 6.57727C3.9428 6.2608 4.37203 6.08301 4.81958 6.08301H19.4446C19.8921 6.08301 20.3214 6.2608 20.6378 6.57727C20.9543 6.89373 21.1321 7.32295 21.1321 7.77051V17.8955C21.1321 18.3431 20.9543 18.7723 20.6378 19.0887C20.3214 19.4052 19.8921 19.583 19.4446 19.583ZM7.06958 8.05221C6.68007 8.05221 6.29931 8.16772 5.97546 8.38414C5.6516 8.60056 5.39921 8.90816 5.25019 9.26804C5.10117 9.62791 5.06223 10.0239 5.13828 10.4059C5.21434 10.7879 5.40198 11.1388 5.67746 11.4142C5.95295 11.6895 6.30391 11.877 6.68596 11.9529C7.06801 12.0288 7.46398 11.9896 7.82378 11.8405C8.18359 11.6913 8.49108 11.4387 8.70735 11.1148C8.92362 10.7908 9.03896 10.41 9.03878 10.0205C9.03878 9.76195 8.98784 9.50593 8.88887 9.26706C8.78989 9.0282 8.64483 8.81118 8.46196 8.62839C8.27909 8.44561 8.062 8.30064 7.82309 8.20178C7.58418 8.10291 7.32814 8.05209 7.06958 8.05221ZM5.38208 17.333H18.8821V13.3955L15.805 10.3184C15.7258 10.2393 15.6185 10.1949 15.5066 10.1949C15.3947 10.1949 15.2874 10.2393 15.2083 10.3184L10.4446 15.083L8.49248 13.1309C8.41333 13.0518 8.30602 13.0074 8.19413 13.0074C8.08224 13.0074 7.97493 13.0518 7.89578 13.1309L5.38208 15.6455V17.333Z"
        fill={color}
      />
    </svg>
  );
}

export default Photo;
