import { CellProps, Column } from "react-table";
import { Color } from "../../constants";
import { UserTableEntry } from "./UsersTable";
import { GetUsersResult, UsersSortFilterParamName } from "../../services/user.service";
import { FormattedMessage } from "react-intl";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import RoleIcon from "../../icons/RoleIcon";
import { USERS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

export function queryResultToTableData(users: GetUsersResult) {
  return {
    pageCount: users.pageCount,
    totalCount: users.totalCount,
    data: users.data?.map(
      (user): UserTableEntry => ({
        fullName: user.fullName,
        email: user.email,
        phone: user.phoneNumber,
        company: user.company,
        description: user.description,
        role: user.role,
        lastAccessed: user.lastAccessed || undefined,
        tspId: user.tspId,
        fleetId: user.fleetId,
      })
    ),
  };
}

export function generateUsersColumns(): Column<UserTableEntry>[] {
  return [
    {
      id: "checkbox",
      Cell: ({ row }: CellProps<UserTableEntry>) => (
        <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
      ),
    },
    {
      accessor: "fullName",
      Header: (
        <SortingHeaderContainer<UsersSortFilterParamName> context={USERS_CONTEXT} paramName="fullName">
          <FormattedMessage id="NAME" defaultMessage="Name" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "email",
      Header: (
        <SortingHeaderContainer<UsersSortFilterParamName> context={USERS_CONTEXT} paramName="email">
          <FormattedMessage id="USERS_TABLE.EMAIL" defaultMessage="Email" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<UserTableEntry>) => {
        return <div style={{ color: Color.CIPIA_BLUE }}>{value}</div>;
      },
    },
    {
      accessor: "role",
      Header: (
        <SortingHeaderContainer<UsersSortFilterParamName> context={USERS_CONTEXT} paramName="role">
          <FormattedMessage id="USERS_TABLE.ROLE" defaultMessage="Role" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) =>
        !value ? null : (
          <div className="d-flex align-items-center gap-1">
            <RoleIcon role={value} />
            <FormattedMessage id={value} defaultMessage={value} />
          </div>
        ),
    },
    {
      accessor: "phone",
      Header: (
        <SortingHeaderContainer<UsersSortFilterParamName> context={USERS_CONTEXT} paramName="phoneNumber">
          <FormattedMessage id="USERS_TABLE.PHONE" defaultMessage="Phone" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "company",
      Header: (
        <SortingHeaderContainer<UsersSortFilterParamName> context={USERS_CONTEXT} paramName="company">
          <FormattedMessage id="USERS_TABLE.COMPANY" defaultMessage="Company" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "lastAccessed",
      Header: (
        <SortingHeaderContainer<UsersSortFilterParamName> context={USERS_CONTEXT} paramName="lastAccessed">
          <FormattedMessage id="USERS_TABLE.LAST_LOGIN" defaultMessage="Last Login" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return !value ? null : <div>{getFormattedLocalDate(value)}</div>;
      },
    },
  ];
}
