import { SVGProps } from "react";

export enum Direction {
  Down = 0,
  Left = 1,
  Up = 2,
  Right = 3,
}

type ArrowProps = {
  direction?: Direction;
  color?: string;
  width?: string;
} & SVGProps<SVGSVGElement>;

function Arrow({ direction = Direction.Down, color = "#FFFFFF", width = "13", ...rest }: ArrowProps) {
  return (
    <svg
      transform={`rotate(${direction * 90})`}
      width={width}
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M-0.00019555 1.525L1.0748 0.449996L6.0248 5.4L10.9748 0.449997L12.0498 1.525L6.0248 7.55L-0.00019555 1.525Z"
        fill={color}
      />
    </svg>
  );
}

export default Arrow;
