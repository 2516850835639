import { AxiosResponse } from "axios";
import { DriverManagement } from "../api/DriverManagement";
import { Driver, DriverWithMoreData } from "../api/data-contracts";

import { FilterParam, QueryParams, ServiceGetResult } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

export type DriversSortFilterParamName = "driverId"
 | "name" 
 | "lastEventType" 
 | "eventMessageTime"
 | "lastTripId" 
 | "fleetName"
 | "fleetId"
 | "tspId"
 | "(name|driverId)";

const driverApi = new DriverManagement();
addAuthHandling(driverApi);

export type DriversSortParam = {
  param: DriversSortFilterParamName;
  descending?: boolean;
};


export type GetDriversParams = QueryParams<DriversSortParam, FilterParam<DriversSortFilterParamName>>;
export type GetDriverParams = BaseDriverOptions & {includeDevices: boolean}
export type BaseDriverOptions= {driverId:string,tspId:number,fleetId:number}

export type AddDriverParams =BaseDriverOptions & Driver ;
export type UpdateDriverParams =BaseDriverOptions & Driver ;
export type DeleteDriverParams = BaseDriverOptions;
export type GetDriversResult = ServiceGetResult<DriverWithMoreData[]>;
export type DriverDeviceParams = BaseDriverOptions & {deviceId: string};

export const driversService = {
  getDrivers: async ({ paging, sorts, filters }: GetDriversParams): Promise<GetDriversResult> => {
    const result = await driverApi.getDrivers({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging.pageIndex,
      pageSize: paging.pageSize,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }
    return {
      pageCount,
      data: result.data.data,
      totalCount: header?.totalCount,
    };
  },

  addDriver: async ({driverId,tspId,fleetId,...driverData }:AddDriverParams): Promise<AxiosResponse> =>
    await driverApi.addDriver(driverId,tspId,fleetId, driverData),

  updateDriver: async ({driverId,tspId,fleetId,...driverData }:UpdateDriverParams): Promise<AxiosResponse> =>
    await driverApi.updateDriver(driverId,tspId,fleetId, driverData),

  deleteDriver: async ({driverId,tspId,fleetId }:DeleteDriverParams): Promise<AxiosResponse> => await driverApi.deleteDriver(driverId,tspId,fleetId),


  getDriver: async ({driverId,tspId,fleetId,includeDevices}:GetDriverParams): Promise<AxiosResponse<DriverWithMoreData, any>> => 
  await driverApi.getDriver(driverId,tspId,fleetId, {includeDevices:includeDevices }),

  addDriverToDevice: async ({deviceId, driverId, fleetId, tspId}:DriverDeviceParams): Promise<AxiosResponse> =>
    await driverApi.addDriverToDevice(deviceId, driverId,fleetId,tspId),

  removeDriverFromDevice: async ({deviceId, driverId, fleetId, tspId}:DriverDeviceParams): Promise<AxiosResponse> =>
    await driverApi.removeDriverFromDevice(deviceId, driverId,fleetId,tspId)
};
