import { FleetEntry } from "../components/FleetView/FleetView";
import { MapEvent } from "../models/map-event.model";

export function fleetEntryToMapEvent(data: FleetEntry[]): MapEvent[] {
  return data.map(({ id, fleetName, lastEvent, licensePlate, vehicleType }) => {
    const {
      id: eventId,
      location,
      mediaArr,
      driverId,
      eventAttr1,
      heading,
      speed,
      time,
      tripId,
      type,
      category
    } = lastEvent ?? {};
    return {
      deviceId: id,
      fleetName,
      lastEvent,
      licensePlate,
      vehicleType,
      eventId,
      driverId,
      tripId,
      time,
      type,
      speed,
      heading,
      mediaArr: mediaArr ?? [],
      location: {
        address: location?.address,
        time: location?.time,
        lat: location?.lat || 0,
        lng: location?.lng || 0,
      },
      eventAttr1,
      category : category
    };
  });
}
