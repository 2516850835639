import { QueryFunctionContext, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { GetFleetsParams, GetFleetsResult, fleetService } from "../services/fleet.service";
import { Fleet } from "../api/data-contracts";

export function fleetsQuery(
  params: GetFleetsParams,
  enabled: boolean
): UseQueryOptions<GetFleetsResult, AxiosError, GetFleetsResult, readonly ["fleet", GetFleetsParams]> {
  return {
    queryKey: ["fleet", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["fleet", GetFleetsParams]>) =>
      await fleetService.getFleets(context.queryKey[1]),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: enabled,
  };
}

export function fleetQuery(
  fleetId: number,
  enabled: boolean
): UseQueryOptions<Fleet, AxiosError, Fleet, readonly ["fleet", number]> {
  return {
    queryKey: ["fleet", fleetId] as const,
    queryFn: async () => await fleetService.getFleet(fleetId),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: enabled,
  };
}
