import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Driver } from "../api/data-contracts";
import { AddDriverParams, DeleteDriverParams, DriverDeviceParams, UpdateDriverParams, driversService } from "../services/driver.service";

type AddDriverMutationOptions = UseMutationOptions<AxiosResponse<Driver>, AxiosError, AddDriverParams>;
type UpdateDriverMutationOptions = UseMutationOptions<AxiosResponse<Driver>, AxiosError, UpdateDriverParams>;
type DeleteDriverMutationOptions = UseMutationOptions<AxiosResponse<Driver>, AxiosError, DeleteDriverParams>;
type DeviceDriverMutationOptions = UseMutationOptions<AxiosResponse<void>, AxiosError, DriverDeviceParams>;


export const addDriverMutation = (
  useMutationProps: Omit<AddDriverMutationOptions, "mutationFn">
): AddDriverMutationOptions =>  ({
  mutationKey: ["addDriver"],
  mutationFn: (params:AddDriverParams ) =>
    driversService.addDriver(params),
    ...useMutationProps,
});

export const updateDriverMutation = (
  useMutationProps: Omit<UpdateDriverMutationOptions, "mutationFn">
): AddDriverMutationOptions =>  ({
  mutationKey: ["updateDriver"],
  mutationFn: (parama:UpdateDriverParams) =>
    driversService.updateDriver(parama),
    ...useMutationProps
});

export const deleteDriverMutation = (
  useMutationProps: Omit<DeleteDriverMutationOptions, "mutationFn">
): DeleteDriverMutationOptions =>  ({
  mutationKey: ["deleteDriver"],
  mutationFn: (params:DeleteDriverParams) => driversService.deleteDriver(params),
  ...useMutationProps
});

export const addDriverToDeviceMutation =  (
  useMutationProps: Omit<DeviceDriverMutationOptions, "mutationFn">
): DeviceDriverMutationOptions =>   ({
  mutationKey: ["addDriverToDevice"],
  mutationFn: (driverDevice: DriverDeviceParams) =>
    driversService.addDriverToDevice(driverDevice),
    ...useMutationProps
});

export const removeDriverFromDeviceMutation = (
  useMutationProps: Omit<DeviceDriverMutationOptions, "mutationFn">
): DeviceDriverMutationOptions =>   ({
  mutationKey: ["removeDriverFromDevice"],
  mutationFn: (driverDevice: DriverDeviceParams) =>
    driversService.removeDriverFromDevice(driverDevice),
    ...useMutationProps
});
