import { Outlet } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import NavBar from "../../components/NavBar/NavBar";
import { useEffect, useState } from "react";
import ToastRenderer from "../../components/ToastRenderer/ToastRenderer";
import { useAuthContext } from "../../context/AuthContext";
import { getIsFleetRole } from "../../util/roles.util";

function MainLayout() {
  const userRole = useAuthContext().loggedUser?.role;
  const [sideBarOpened, setSideBarOpened] = useState<boolean>(false);

  useEffect(() => {
    setSideBarOpened(!!userRole && !getIsFleetRole(userRole));
  }, [userRole]);

  return (
    <div className="d-flex p-0 h-100">
      <div
        className="p-0 h-100"
        style={{
          width: sideBarOpened ? "17.5rem" : "2rem",
          transition: "width 0.3s",
        }}
      >
        <SideBar
          opened={sideBarOpened}
          setOpened={setSideBarOpened}
          showExpander={!!userRole && !getIsFleetRole(userRole)}
        />
      </div>
      <div
        className={`h-100 d-flex flex-column`}
        style={{
          width: `calc(100% - ${sideBarOpened ? "17.5rem" : "2rem"})`,
          transition: "width 0.3s",
        }}
      >
        <div className="flex-grow-0">
          <NavBar />
        </div>
        <main className="flex-grow-1 position-relative overflow-auto">
          <Outlet />
          <ToastRenderer />
        </main>
      </div>
    </div>
  );
}

export default MainLayout;
