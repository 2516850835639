import { FormattedMessage } from "react-intl";
import styles from "./Pagination.module.scss";
import Chevron from "../../icons/pagination/Chevron";
import ChevronWithLine from "../../icons/pagination/ChevronWithLine";
import { ChangeEvent, useEffect, useState } from "react";

interface PaginationProps {
  debouncedPageIndex: number;
  onChangePageIndex: (page: number) => void;
  pageCount?: number;
  totalEntries?: number;
  className?: string;
  allDisabled?: boolean;
}

function Pagination({
  debouncedPageIndex,
  onChangePageIndex,
  pageCount,
  totalEntries,
  className,
  allDisabled,
}: PaginationProps) {
  const [uiPageIndex, setUiPageIndex] = useState<number | null>(
    debouncedPageIndex
  );

  useEffect(() => {
    uiPageIndex !== null && setUiPageIndex(debouncedPageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPageIndex]);

  function updateInputValueOnBlur() {
    uiPageIndex === null && setUiPageIndex(debouncedPageIndex);
  }

  function setPage(index: number) {
    setUiPageIndex(index);
    onChangePageIndex(index);
  }

  function onChangeInputValue(e: ChangeEvent<HTMLInputElement>) {
    const value = Number.parseInt(e.target.value);
    if (isNaN(value)) {
      setUiPageIndex(null);
    }
    if (pageCount !== undefined && value >= 1 && value <= pageCount) {
      setPage(value);
    }
  }

  const isFirstAndPrevDisabled =
    allDisabled || uiPageIndex === null || uiPageIndex <= 1;
  const isLastAndNextDisabled =
    allDisabled ||
    pageCount === undefined ||
    uiPageIndex === null ||
    uiPageIndex >= pageCount;

  return (
    <div
      className={`${styles.container} w-100 d-flex align-items-center justify-content-center ${className}`}
    >
      <ChevronWithLine
        direction="LEFT"
        onClick={() => !isFirstAndPrevDisabled && setPage(1)}
        disabled={isFirstAndPrevDisabled}
      />
      <Chevron
        direction="LEFT"
        onClick={() => !isFirstAndPrevDisabled && setPage(uiPageIndex - 1)}
        disabled={isFirstAndPrevDisabled}
      />
      <div className="d-flex align-items-center gap-2">
        <FormattedMessage id="PAGE" defaultMessage="Page" />
        <input
          onBlur={updateInputValueOnBlur}
          className={styles.input}
          type="number"
          value={uiPageIndex ?? ""}
          onChange={onChangeInputValue}
        />
        <FormattedMessage id="Pagination.of" />
        <div>{` ${pageCount ?? ""}`}</div>
      </div>

      <Chevron
        direction="RIGHT"
        onClick={() => !isLastAndNextDisabled && setPage(uiPageIndex + 1)}
        disabled={isLastAndNextDisabled}
      />
      <ChevronWithLine
        direction="RIGHT"
        onClick={() =>
          !isLastAndNextDisabled && pageCount && setPage(pageCount)
        }
        disabled={isLastAndNextDisabled}
      />
      <FormattedMessage id="TOTAL" defaultMessage="Total" />
      {`: ${totalEntries ?? ""}`}
    </div>
  );
}

export default Pagination;
