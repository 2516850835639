import FormModal from "../form/FormModal/FormModal";
import { ReactComponent as SecurityIcon } from "../../assets/icons/Security.svg";
import { ReactComponent as CheckVIcon } from "../../assets/icons/CheckV.svg";
import { Color } from "../../constants";
import { FormattedMessage } from "react-intl";
import ModalSecondaryButton from "../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ModalMainButton from "../Buttons/ModalMainButton/ModalMainButton";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";
import { FieldErrors, useForm } from "react-hook-form";
import useUser from "../../hooks/useUser";
import { isPasswordValid } from "../../util/password.util";

const formModalClasses = {
  header: "modal-form-header",
  footer: "modal-form-footer",
  dialog: "modal-form-dialog",
  body: "modal-form-body",
};

interface ChangePasswordInputs {
  oldPassword: string;
  newPassword: string;
  newPasswordRetyped: string;
}

interface ChangePasswordModalProps {
  show: boolean;
  handleClose: () => void;
}

function ChangePasswordModal({ show, handleClose }: ChangePasswordModalProps) {
  const { changePassword, isLoadingChangePassword: isLoading } = useUser({ onSuccessChangePassword: handleClose });

  const { control, handleSubmit } = useForm<ChangePasswordInputs>({
    resolver: (inputs) => {
      const errors: FieldErrors<ChangePasswordInputs> = {};
      if (!inputs.oldPassword) errors.oldPassword = { type: "required" };
      if (!inputs.newPassword) {
        errors.newPassword = { type: "required" };
      } else if (!isPasswordValid(inputs.newPassword)) {
        errors.newPassword = { type: "value" };
      }
      if (inputs.newPasswordRetyped !== inputs.newPassword) errors.newPasswordRetyped = { type: "value" };
      return {
        values: Object.keys(errors).length ? {} : inputs,
        errors,
      };
    },
  });

  function submit({ oldPassword, newPassword }: ChangePasswordInputs) {
    changePassword({ oldPassword, newPassword });
  }

  return (
    <FormModal
      id="change-password"
      classes={formModalClasses}
      show={show}
      handleClose={handleClose}
      onSubmit={isLoading ? undefined : handleSubmit(submit)}
      header={
        <div className="d-flex align-items-center gap-2">
          <SecurityIcon fill={Color.GRAY_2} />
          <FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change Password" />
        </div>
      }
      footer={
        <div className="d-flex align-items-center gap-2">
          <ModalSecondaryButton disabled={isLoading} type="button" onClick={handleClose}>
            <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
          </ModalSecondaryButton>
          <ModalMainButton icon={<CheckVIcon fill="#04C464" />} type="submit" disabled={isLoading}>
            <FormattedMessage id="SAVE" defaultMessage="Save" />
          </ModalMainButton>
        </div>
      }
    >
      <div className="d-flex flex-column gap-1">
        <FormGroup
          label={<FormattedMessage id="OLD_PASSWORD" defaultMessage="Old Password" />}
          input={
            <FormInput
              type="text"
              name="oldPassword"
              control={control}
              input={{
                type: "password",
                disabled: isLoading,
              }}
            />
          }
        />
        <FormGroup
          label={<FormattedMessage id="NEW_PASSWORD" defaultMessage="New Password" />}
          input={
            <FormInput
              type="text"
              name="newPassword"
              control={control}
              input={{
                type: "password",
                disabled: isLoading,
              }}
            />
          }
        />
        <FormGroup
          label={<FormattedMessage id="RETYPE_NEW_PASSWORD" defaultMessage="Retype New Password" />}
          input={
            <FormInput
              type="text"
              name="newPasswordRetyped"
              control={control}
              input={{
                type: "password",
                disabled: isLoading,
              }}
            />
          }
        />
      </div>
      <div style={{ backgroundColor: Color.LIGHT_LIGHT_BLUE, borderRadius: "0.5rem" }} className="p-3 my-3">
        <ul className="d-flex flex-column gap-3">
          <li>
            &bull;&nbsp;
            <FormattedMessage
              id="PASSWORD_REQ.MIN_CHARS"
              defaultMessage="At least 8 characters - the more characters, the better"
            />
          </li>
          <li>
            &bull;&nbsp;
            <FormattedMessage
              id="PASSWORD_REQ.UPPER_LOWER"
              defaultMessage="A mixture of both uppercase and lowercase letters"
            />
          </li>
          <li>
            &bull;&nbsp;
            <FormattedMessage id="PASSWORD_REQ.LETTERS_NUMBERS" defaultMessage="A mixture of letters and numbers" />
          </li>
          <li>
            &bull;&nbsp;
            <FormattedMessage
              id="PASSWORD_REQ.SPECIAL_CHAR"
              defaultMessage="Inclusion of at least one special character, e.g., ! @ # ? ]"
            />
          </li>
          <li>
            &bull;&nbsp;
            <FormattedMessage id="PASSWORD_REQ.NO_LT_GT" defaultMessage="do not use < or > in your password" />
          </li>
        </ul>
      </div>
    </FormModal>
  );
}

export default ChangePasswordModal;
