import { CellProps, Column } from "react-table";
import { BillingTableEntry, currencySigns } from "./BillingTable";
import { FormattedMessage } from "react-intl";
import { BillingSortAndFilterParamNames, GetBillingResult } from "../../services/billing.service";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import StatusIcon from "../../icons/StatusIcon";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import { numberFormatter } from "../../util/text.util";
import { LocaleType } from "../../context/IntlContext";
import { FleetSenseDeviceBilling } from "../../api/data-contracts";
import { getStatus } from "../../util/device-status.util";
import { BILLING_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

export function queryResultToTableData(queryResult: GetBillingResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    data: billingResultToBillingEntries(queryResult?.data || []),
    currencyISO: queryResult?.currencyISO,
    totalCost: queryResult?.total
  };
}

function billingResultToBillingEntries(devices: FleetSenseDeviceBilling[]): BillingTableEntry[] {
  return devices.map(
    (msg): BillingTableEntry => {
      return {
        deviceId: msg.id!,
        deviceStatus: getStatus(msg),
        assignFleetDate: msg.assignFleetDate!,
        lastEventDateTime: msg.lastEventMessage?.eventDateTime,
        firstEventDate: msg.firstEventDate!,
        costs: msg.cost,
        fleetAssigned: msg.fleetAssigned,
        eventsCount: msg.eventsCount!,
        tspAssigned: msg.tspAssigned,
      }
    });
}


export function getCurrencySign(currencyISO: string) {
  return currencySigns[currencyISO];
};

export function generateBillingColumns(currencyISO: string, locale: LocaleType): Column<BillingTableEntry>[] {

  return [{
    accessor: "deviceId",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="deviceId">
        <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
      </SortingHeaderContainer>
    )
  },
  {
    accessor: "deviceStatus",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="deviceStatus">
        <FormattedMessage id="DEVICE_STATUS" defaultMessage="Device status" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) =>
      !value ? null : (
        <div className="d-flex align-items-center gap-1">
          <StatusIcon type={value} />
          {value.charAt(0) + value.slice(1).toLowerCase()}
        </div>
      ),
  },
  {
    accessor: "assignFleetDate",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="assignFleetDate">
        <FormattedMessage id="FLEET_ASSIGN_DATE" defaultMessage="Date of status changed" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) => {
      const { getFormattedLocalDate } = useFormattedLocalDate();
      return <div>{value ? getFormattedLocalDate(value) : "--"}</div>;
    },
  },
  {
    accessor: "lastEventDateTime",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="lastEventDateTime">
        <FormattedMessage id="LAST_EVENT_TIME" defaultMessage="Last event time" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) => {
      const { getFormattedLocalDate } = useFormattedLocalDate();
      return <div>{value ? getFormattedLocalDate(value) : "--"}</div>;
    },
  },
  {
    accessor: "firstEventDate",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="firstEventDate">
        <FormattedMessage id="FIRST_EVENT_TIME" defaultMessage="First time reporting" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) => {
      const { getFormattedLocalDate } = useFormattedLocalDate();
      return <div>{value ? getFormattedLocalDate(value) : "--"}</div>;
    },
  },
  {
    accessor: "eventsCount",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="eventsCount">
        <FormattedMessage id="EVENT_COUNT" defaultMessage="# of events" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) => {
      return <div>{value ? numberFormatter(Number(value), locale) : "--"}</div>;
    }
  },
  {
    accessor: "tspAssigned",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="tspAssigned">
        <FormattedMessage id="TSP_ASSINGED" defaultMessage="Assigned to TSP" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }: CellProps<BillingTableEntry>) => {
      return <div>{value ? value : "--"}</div>;
    }
  },
  {
    accessor: "fleetAssigned",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="fleetAssigned">
        <FormattedMessage id="FLEET_ASSINGED" defaultMessage="Assigned to fleet" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }: CellProps<BillingTableEntry>) => {
      return <div>{value ? value : "--"}</div>;
    }
  },
  {
    accessor: "costs",
    Header: (
      <SortingHeaderContainer<BillingSortAndFilterParamNames> context={BILLING_CONTEXT} paramName="costs">
        <FormattedMessage id="TOTAL_COST" defaultMessage="Total cost" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }: CellProps<BillingTableEntry>) => {
      return <div>{getCurrencySign(currencyISO)}{numberFormatter(Number(value), locale)}</div>;
    }
  },
  ]
}
