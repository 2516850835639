import { OverlayTrigger } from "react-bootstrap";

interface PhotoOverlayProps {
  src: string;
}

function PhotoWithOverlay({ src }: PhotoOverlayProps) {
  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <div
          style={{
            border: "2px solid rgba(255, 255, 255, 1)",
            borderRadius: "6px",
            boxShadow: "5px 5px 20px 0px rgba(0, 0, 0, 0.35)",
            marginLeft: "3rem",
            zIndex: "3",
          }}
        >
          <img
            alt="vehicle"
            style={{
              height: "30rem",
            }}
            src={src}
          ></img>
        </div>
      }
    >
      <img alt="vehicle" style={{ maxWidth: "3.5rem", maxHeight: "3.5rem" }} src={src} />
    </OverlayTrigger>
  );
}

export default PhotoWithOverlay;
