import { CSSProperties, ReactNode, useMemo, useState } from "react";
import Checkboxx from "../../assets/checkbox/Checkbox.svg";
import CheckboxActive from "../../assets/checkbox/CheckboxActive.svg";
import CheckboxActiveHalfSelected from "../../assets/checkbox/CheckboxActiveHalfSelected.svg";
import CheckboxActiveSelected from "../../assets/checkbox/CheckboxActiveSelected.svg";
import CheckboxFocusedHalfSelected from "../../assets/checkbox/CheckboxFocusedHalfSelected.svg";
import CheckboxFocusedSelected from "../../assets/checkbox/CheckboxFocusedSelected.svg";
import CheckboxHalfSelected from "../../assets/checkbox/CheckboxHalfSelected.svg";
import CheckboxHoverOrFocus from "../../assets/checkbox/CheckboxHoverOrFocus.svg";
import CheckboxHoverHalfSelected from "../../assets/checkbox/CheckboxHoverHalfSelected.svg";
import CheckboxHoverSelected from "../../assets/checkbox/CheckboxHoverSelected.svg";
import CheckboxSelected from "../../assets/checkbox/CheckboxSelected.svg";

export type CheckType = "FULL" | "HALF" | "EMPTY";

function getCheckBox(checked: CheckType, isActive: boolean, isHovered: boolean, isFocused?: boolean) {
  switch (checked) {
    case "EMPTY":
      if (isActive) return CheckboxActive;
      if (isFocused || isHovered) return CheckboxHoverOrFocus;
      return Checkboxx;
    case "FULL":
      if (isActive) return CheckboxActiveSelected;
      if (isFocused) return CheckboxFocusedSelected;
      if (isHovered) return CheckboxHoverSelected;
      return CheckboxSelected;
    case "HALF":
      if (isActive) return CheckboxActiveHalfSelected;
      if (isFocused) return CheckboxFocusedHalfSelected;
      if (isHovered) return CheckboxHoverHalfSelected;
      return CheckboxHalfSelected;
  }
}

interface CheckboxInputProps {
  checked: CheckType;
  isFocused?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  label?: ReactNode;
}

function CheckboxInput({ checked, isFocused, onClick, label }: CheckboxInputProps) {
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const checkboxProps = useMemo(
    () => ({
      onMouseDown: () => setIsActive(true),
      onMouseUp: () => setIsActive(false),
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => {
        setIsHovered(false);
        setIsActive(false);
      },
    }),
    []
  );

  return (
    <div
      className="d-flex align-items-center cursor-pointer fit-content"
      style={{ width: "fit-content", height: "fit-content" }}
      onClick={onClick}
    >
      <img alt="checkbox" src={getCheckBox(checked, isActive, isHovered, isFocused)} {...checkboxProps} />
      {label && <label className="cursor-pointer">{label}</label>}
    </div>
  );
}

export default CheckboxInput;
