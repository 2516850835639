import { Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import VehicleIcon from "../../icons/VehicleIcon";

export default function TripsHeader({ licensePlate, vehicleType, fleetName, deviceId }: any) {
  return (
    <>
      <Stack direction="horizontal" gap={2} className={`font-md px-2`}>
        <p className="m-0">
          <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />
        </p>
        <span style={{ marginLeft: "0.5rem", fontWeight: 500 }}>{licensePlate || "--"}</span>

        <VehicleIcon name={vehicleType} />
        <p className="m-0 ms-auto">{fleetName}</p>
      </Stack>
      <p className={`font-md px-2`}>
        <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
        <span style={{ marginLeft: "0.5rem", fontWeight: 500 }}>{deviceId}</span>
      </p>
    </>
  );
}
