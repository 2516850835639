import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Color } from "../../constants";
import { useFleetsLayoutContext } from "../../context/FleetsLayoutContext";
import Arrow, { Direction } from "../../icons/Arrow";
import { fleetEntryToMapEvent } from "../../util/events-map.util";
import { FleetEntry } from "../FleetView/FleetView";
import DriverPhotoPceholder from "../../assets/DriverPhotoPceholder.svg";

import CurrentTrip from "./CurrentTrip/CurrentTrip";
import TripTabs, { TripsTabOption } from "./TripsTabs/TripTabs";
import DriverTrips from "./DriverTrips/DriverTrips";
import VehicleTrips from "./VehicleTrips/VehicleTrips";
import TripsHeader from "./TripsHeader";
import { getInitialDriverTripsQueryParams, getInitialTripsEventQueryParams, getInitialTripsQueryParams, getInitialVehicleTripsQueryParams } from "./trips-utils";
import { TripsQueryParamsContextProvider } from "../../context/QueryParamsContext/TripsQueryParamsContextProvider";
import { EventsQueryParamsContextProvider } from "../../context/QueryParamsContext/EventsQueryParamsContextProvider";
import useQueryDrivers from "../../hooks/useQueryDrivers";
import { DriversQueryParamsContextProvider, useDriversQueryParamsContext } from "../../context/QueryParamsContext/DriversQueryParamsContextProvider";
import { addBase64Prefix } from "../../util/shared.util";
import { DriverWithMoreData } from "../../api/data-contracts";

type TripsViewState = {
  active: TripsTabOption;
  driverId?: string;
  tripId?: number;
};

export default function TripsView() {
  const navigate = useNavigate();
  
  const { selectedDeviceEvent, setMapEvents, selectedDataIndex, setSelectedDataIndex } = useFleetsLayoutContext();
  const { tripId, fleetName, vehicleType, licensePlate, deviceId } = selectedDeviceEvent ?? {};
  const [tabData, setTabData] = useState<TripsViewState>({ active: "current", driverId: "", tripId: tripId });

  const dataChangedHandler = useCallback(
    (data: FleetEntry[]) => {
      setMapEvents(fleetEntryToMapEvent(data));
    },
    [setMapEvents]
  );

  function navBack() {
    navigate(-1);
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div>
        <Button onClick={navBack} className="bg-transparent border-0 text-dark p-2">
          <Arrow color={Color.CIPIA_BLUE} direction={Direction.Left} />
          <span style={{ color: Color.CIPIA_BLUE, marginLeft: "0.25rem" }}>
            <FormattedMessage id="BACK_TO_LIST" defaultMessage="Back to List" />
          </span>
        </Button>
      </div>
      <div>
        {tabData.active !== "driver" ? (
          <TripsHeader
            licensePlate={licensePlate}
            vehicleType={vehicleType}
            fleetName={fleetName}
            deviceId={deviceId}
          />
        ) : (
          <DriversQueryParamsContextProvider>
            <DriverHeader fleetName={fleetName} driverId={tabData.driverId} />
          </DriversQueryParamsContextProvider>
        )}
        <TripTabs
          active={tabData.active}
          onChange={(active: TripsTabOption) => setTabData({ ...tabData, active })}
          rightTab={tabData.active === "driver" ? "driver" : "vehicle"}
          disabled={{
            current: !tripId,
            vehicle: !licensePlate,
            driver: !tabData.driverId,
          }}
        />
      </div>
      <Stack className="p-2 overflow-hidden">
        {tabData.active === "current" &&  tabData.tripId && (
          <EventsQueryParamsContextProvider initialParams={getInitialTripsEventQueryParams(tabData.tripId)}>
            <TripsQueryParamsContextProvider initialParams={getInitialTripsQueryParams(tabData.tripId)}>
              <CurrentTrip
                tripId={tabData.tripId}
                onDataChange={dataChangedHandler}
                onSetDriverTrips={
                  (driverId: string) => setTabData({ active: "driver", driverId })}
                selectedDataIndex={selectedDataIndex}
                onSelectDataIndex={setSelectedDataIndex}
              />
            </TripsQueryParamsContextProvider>
          </EventsQueryParamsContextProvider>
        )}
        {tabData.active === "vehicle" && (
          <TripsQueryParamsContextProvider initialParams={getInitialVehicleTripsQueryParams(licensePlate!)}>
            <VehicleTrips
              onDataChange={dataChangedHandler}
              selectedDataIndex={selectedDataIndex}
              onClickOnViewTrip={(tripId) => setTabData({ active:"current", tripId: tripId })}
            />
          </TripsQueryParamsContextProvider>
        )}
        {tabData.active === "driver" && tabData.driverId && (
          <TripsQueryParamsContextProvider initialParams={getInitialDriverTripsQueryParams(tabData.driverId)}>
            <DriverTrips
              onDataChange={dataChangedHandler}
              selectedDataIndex={selectedDataIndex}
              onClickOnTripView={(tripId) => setTabData({ active: "current", tripId: tripId })}
            />
          </TripsQueryParamsContextProvider>
        )}
      </Stack>
    </div>
  );
}

function DriverHeader({ fleetName, driverId }: any) {

  const { upsertFilter, queryParams } = useDriversQueryParamsContext();

  const { data: drivers } = useQueryDrivers(dr => dr, queryParams.filters!.length > 0);

  useEffect(() => {
    if (upsertFilter) {

      upsertFilter("driverId", "==", driverId)
    }

  }, [upsertFilter,driverId])

  const driverData = useMemo(() => {
    if (drivers?.data && drivers?.data[0]) {
      let dr = drivers?.data[0];
      return { ...dr, image: dr.image && addBase64Prefix(dr.image) }
    }
    return {}
  }, [drivers]) as DriverWithMoreData


  return (
    <>
      <Stack direction="horizontal" gap={2} className={`font-md px-2`}>
        <img className="img-fluid" src={driverData?.image || DriverPhotoPceholder} alt="driver" />
        <p className="m-0">
          <FormattedMessage id="DRIVER" defaultMessage="Driver" />
        </p>
        <span style={{ marginLeft: "0.5rem", fontWeight: 500 }}>{driverData?.name || "--"}</span>

        <p className="m-0 ms-auto">{fleetName}</p>
      </Stack>
    </>
  );
}
