import { useQuery } from "@tanstack/react-query";
import { GetDriverParams } from "../services/driver.service";
import { GetDriverDevicesResult, driverDevicesQuery } from "../queries/drivers.query";

function useQueryDriverDevices(param: GetDriverParams | undefined,
  enabled: boolean = true
):GetDriverDevicesResult  {
  const { data, isLoading, isError, isFetching } = useQuery(driverDevicesQuery(param!,  enabled));
   
  return {
    data: data,
    isLoading: isLoading && isFetching,
    isError,
    isFetching,
  };
}

export default useQueryDriverDevices;
