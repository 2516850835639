import { FormattedMessage } from "react-intl";
import { ReactComponent as MuteIcon } from "../../../assets/icons/Mute.svg";
import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { Color } from "../../../constants";

interface UnmuteModalProps {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
}

function UnmuteModal({ show, onClickNo, onClickYes }: UnmuteModalProps) {
  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={onClickYes}
      onHide={onClickNo}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <MuteIcon fill={Color.CIPIA_BLUE} height={82} width={82}/>
          <FormattedMessage
            id="Vehicles.UNMUTE_QUESTION"
            defaultMessage="Are you sure you want to Unmute this Device?"
          />
        </div>
      }
    />
  );
}

export default UnmuteModal;
