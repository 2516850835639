import { HTMLProps, ReactNode } from "react";
import ReactSelect, { BaseOption } from "../../ReactSelect/ReactSelect";
import { GroupBase, MultiValueProps, OptionProps, Props, ValueContainerProps, components } from "react-select";
import { Color, InputUi } from "../../../constants";
import Arrow, { Direction } from "../../../icons/Arrow";
import { useIntl } from "react-intl";
import CheckboxInput from "../../CheckboxInput/CheckboxInput";

export function isGrouped<Option>(
  options: readonly (Option | GroupBase<Option>)[]
): options is readonly GroupBase<Option>[] {
  return (options as readonly GroupBase<Option>[])[0]?.options !== undefined;
}

type MultiSelectInputProps<
  Option extends { value: unknown; label: ReactNode },
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  inputLabel?: string;
  controlWidth?: string;
  controlMinWidth?: string;
  controlMaxWidth?: string;
  selectWrapperProps?: HTMLProps<HTMLDivElement>;
  controlOnly?: boolean;
} & Omit<Props<Option, true, Group>, "hideSelectedOptions" | "closeMenuOnSelect" | "components" | "isMulti" | "styles">;

const MultiSelectInput = <Option extends BaseOption, Group extends GroupBase<Option> = GroupBase<Option>>({
  inputLabel,
  controlWidth,
  controlMinWidth,
  controlMaxWidth,
  selectWrapperProps,
  controlOnly = false,
  ...props
}: MultiSelectInputProps<Option, Group>) => {
  return (
    <div className="input-with-label-container">
      {inputLabel && <div className="input-label">{inputLabel}</div>}
      <div {...selectWrapperProps}>
        <ReactSelect
          {...props}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{
            ValueContainer: controlOnly ? CustomValueContainer : components.ValueContainer,
            MultiValue: CustomMultiValue,
            Option: CustomOption,
            IndicatorSeparator: undefined,
            ClearIndicator: undefined,
            DropdownIndicator: () => (
              <div className="px-2">
                <Arrow color={Color.BLUE_GRAY_2} direction={Direction.Down} />
              </div>
            ),
          }}
          styles={{
            menu: (base) => ({ ...base, zIndex: 2, marginTop: "0.1rem", minWidth: 'fit-content' }),
            container: (base) => ({ ...base }),
            control: (base) => ({
              ...base,
              minHeight: InputUi.HEIGHT,
              width: controlWidth,
              minWidth: controlMinWidth,
              maxWidth: controlMaxWidth,
              ":hover": { backgroundColor: "#EFF6FF", borderColor: "#3587EB" },
            }),
            valueContainer: (base) => ({
              ...base,
              paddingTop: "0",
              paddingBottom: "0",
              flexWrap: "nowrap",
              whiteSpace: "nowrap",
            }),
            input: (base) => ({
              ...base,
              margin: "0.0625rem",
            }),
            groupHeading: (base) => ({
              ...base,
              color: Color.BLACK,
              font: "inherit",
              textTransform: "none",
            }),
            option: (base) => ({
              ...base,
              backgroundColor: Color.WHITE,
              ":active": { backgroundColor: Color.WHITE },
              color: Color.BLACK,
              cursor: "pointer",
              padding: "0 0.25rem 0 0.25rem",
            }),
          }}
          isMulti
        />
      </div>
    </div>
  );
};

export default MultiSelectInput;

function CustomMultiValue<
  Option_14 extends BaseOption,
  IsMulti_14 extends boolean,
  Group_14 extends GroupBase<Option_14>
>({
  components: innerComponents,
  index,
  children,
  getValue,
  data,
  ...props
}: MultiValueProps<Option_14, IsMulti_14, Group_14>) {
  const { formatMessage } = useIntl();
  if (index > 0) return null;

  const renderedChildren = () => {
    if (getValue().length === 1) return getValue()[0]?.label;

    return `${formatMessage({
      id: "MULTI",
      defaultMessage: "Multi",
    })} +${getValue().length}`
  }
  return (
    <components.MultiValue<Option_14, IsMulti_14, Group_14>
      {...props}
      components={{
        ...innerComponents,
        Label: ({ children }) => <div>{children}</div>,
        Remove: () => null,
        Container: ({ children }) => <div>{children}</div>,
      }}
      index={index}
      getValue={getValue}
      data={data}
    >
      {renderedChildren()}
    </components.MultiValue>
  );
}

function CustomOption<Option_16, IsMulti_16 extends boolean, Group_16 extends GroupBase<Option_16>>(
  props: OptionProps<Option_16, IsMulti_16, Group_16>
) {
  return (
    <components.Option {...props}>
      <CheckboxInput checked={props.isSelected ? "FULL" : "EMPTY"} label={props.label} />
    </components.Option>
  );
}

function CustomValueContainer<
  Option_20 extends BaseOption,
  IsMulti_20 extends boolean,
  Group_20 extends GroupBase<Option_20>
>({ children, ...props }: ValueContainerProps<Option_20, IsMulti_20, Group_20>) {
  let str = "";
  for (const { label } of props.getValue()) {
    str += label + ", ";
  }
  if (str.length) {
    str = str.substring(0, str.length - 2);
  }
  return (
    <components.ValueContainer {...props}>
      {str.length === 0 ? children : <div className="text-truncate">{str}</div>}
    </components.ValueContainer>
  );
}
