import { Row, TableInstance } from "react-table";
import { FormattedMessage } from "react-intl";
import PhotoWithOverlay from "../VehiclesTable/PhotoOverlay";
import { addBase64Prefix } from "../../util/shared.util";
import DriverOhotoPlaceHolder from "../../assets/DriverPhotoPceholder.svg";
import { VehicleEntry } from "./VehiclesTable";
import { useGetDevice } from "../../hooks/useQueryDevices";

interface ExpandedRowProps {
  row: Row<VehicleEntry>;
  table: TableInstance<VehicleEntry>;
}

const ExpandedRow = ({ row, table }: ExpandedRowProps) => {
  const { data } = useGetDevice({ deviceId: row.original.deviceId!, includeDrivers: true });
 
  return (
    <tr style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} className="selected-row-bg">
      <td colSpan={table.visibleColumns.length}>
        {!!data?.drivers?.length && (
          <>
            <div className="d-flex gap-1 ms-4 align-center">
              <FormattedMessage id="ASSIGN_DRIVERS" defaultMessage={"Assign drivers"} />
              <div>({data?.drivers?.length})</div>
            </div>
            <div className="d-flex gap-4 ms-4 align-center">
              {data?.drivers?.map((d) => (
                <div key={d.driverId} className="d-flex gap-2 justify-content-center">
                  <PhotoWithOverlay src={(d.image && addBase64Prefix(d.image)) || DriverOhotoPlaceHolder} />
                  <div className="align-self-center">{d.driverId}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default ExpandedRow;
