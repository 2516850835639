import { PropsWithChildren, createContext, useContext, useState } from "react";
import { MapEvent } from "../models/map-event.model";

interface IFleetsLayoutContext {
  isMapExpanded: boolean;
  toggleMapExpand: () => void;
  showEventInfo: boolean;
  setShowEventInfo: (show: boolean) => void;
  selectedDataIndex?: number;
  setSelectedDataIndex: (index: number) => void;
  mapEvents?: MapEvent[];
  setMapEvents: (events: MapEvent[]) => void;
  selectedEvent?: MapEvent;
  selectedDeviceEvent?: MapEvent;
  setSelectedDeviceEvent: (device?: MapEvent) => void;
}

const FleetsLayoutContext = createContext<IFleetsLayoutContext>({
  isMapExpanded: false,
  showEventInfo: false,
  toggleMapExpand: () => {},
  setShowEventInfo: () => {},
  setSelectedDataIndex: () => {},
  setMapEvents: () => {},
  setSelectedDeviceEvent: () => {},
});

export function FleetsLayoutProvider({ children }: PropsWithChildren) {
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [showEventInfo, setShowEventInfo] = useState(false);
  const [selectedDataIndex, setSelectedDataIndex] = useState<number>();
  const [mapEvents, setMapEvents] = useState<MapEvent[]>();
  const [selectedDeviceEvent, setSelectedDeviceEvent] = useState<MapEvent>();

  const selectedEvent = selectedDataIndex !== undefined ? mapEvents?.[selectedDataIndex] : undefined;

  return (
    <FleetsLayoutContext.Provider
      value={{
        isMapExpanded,
        toggleMapExpand: () => setIsMapExpanded(!isMapExpanded),
        showEventInfo,
        setShowEventInfo,
        selectedDataIndex,
        setSelectedDataIndex,
        mapEvents,
        setMapEvents,
        selectedEvent,
        selectedDeviceEvent,
        setSelectedDeviceEvent,
      }}
    >
      {children}
    </FleetsLayoutContext.Provider>
  );
}

export const useFleetsLayoutContext = () => useContext(FleetsLayoutContext);
