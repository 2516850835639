import { Fragment, ReactNode, useEffect } from "react";
import { Table as BsTable, Spinner } from "react-bootstrap";
import { useTable, Column, Row, useExpanded, useRowSelect, TableInstance, HeaderGroup } from "react-table";

interface TableProps<T extends Object> {
  data: readonly T[];
  columns: Column<T>[];
  classes?: {
    wrapper?: (table: TableInstance<T>) => string;
    table?: (table: TableInstance<T>) => string;
    thead?: (table: TableInstance<T>) => string;
    th?: (header: HeaderGroup<T>) => string;
    tr?: (row: Row<T>) => string;
    td?: (row: Row<T>) => string;
  };
  isLoading?: boolean;
  isError?: boolean;
  renderRowSubComponent?: (row: Row<T>, table: TableInstance<T>) => ReactNode;
  onClickRow?: (row: Row<T>, table: TableInstance<T>) => void;
  selectedRow?: number;
  setSelectedRow?: (row: number) => void;
  onChangeSelectedRows?: (rows: Row<T>[]) => void;
  hideHeader?: boolean;
}

const Table = <T extends Object>({
  data,
  columns,
  classes,
  isLoading,
  isError,
  renderRowSubComponent,
  onClickRow,
  selectedRow,
  setSelectedRow,
  onChangeSelectedRows,
  hideHeader,
}: TableProps<T>) => {
  const tableInstance = useTable<T>(
    {
      columns,
      data,
      useControlledState: (state) => ({
        ...state,
        selectedRow,
      }),
    },
    useExpanded,
    useRowSelect
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = tableInstance;

  useEffect(() => {
    onChangeSelectedRows?.(selectedFlatRows);
  }, [selectedFlatRows, onChangeSelectedRows]);

  const TableContent = () => (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.getRowProps().key}>
            <tr
              {...row.getRowProps()}
              onClick={() => {
                onClickRow && onClickRow(row, tableInstance);
                setSelectedRow && setSelectedRow(row.index);
              }}
              className={classes?.tr?.(row)}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className={`${classes?.td?.(row)}`}>
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
            {row.isExpanded && renderRowSubComponent?.(row, tableInstance)}
          </Fragment>
        );
      })}
    </tbody>
  );

  return (
    <div className={classes?.wrapper?.(tableInstance)}>
      <BsTable className={`table w-100 ${classes?.table?.(tableInstance)} `} {...getTableProps()}>
        {!hideHeader && (
          <thead className={classes?.thead?.(tableInstance)}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={`text-nowrap ${classes?.th?.(column)}`}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        {data && <TableContent />}
      </BsTable>
      {isError ? (
        <div>Error With Receiving Data</div>
      ) : (
        isLoading && (
          <div>
            <Spinner as="span" animation="border" size="sm" className="m-auto" />
            Loading...
          </div>
        )
      )}
    </div>
  );
};

export default Table;
