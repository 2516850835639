import { Outlet } from "react-router-dom";
import RootProviders from "./providers/RootProviders";

function App() {
  return (
    <RootProviders>
      <Outlet />
    </RootProviders>
  );
}

export default App;
