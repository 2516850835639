import { CSSProperties, ReactNode, useState } from "react";
import ReactImageUploading, { ImageType } from "react-images-uploading";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CompanyLogoPlaceholder } from "../../assets/CompanyLogoPlaceholder.svg";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import styles from "./ImageUpload.module.css";
import { Color } from "../../constants";

interface ImageUploadProps {
  placeHolderImg: ReactNode;
  onChange?: (img: ImageType) => void;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

/* Allows uploading images and shows the uploaded image if there is one, else shows placeHolderImg */
function ImageUpload({ placeHolderImg, onChange, disabled, className, style }: ImageUploadProps) {
  const [img, setImg] = useState<ImageType>();

  return (
    <ReactImageUploading
      value={img ? [img] : []}
      onChange={(images) => {
        const image = images[0];
        setImg(image);
        onChange?.(image);
      }}
    >
      {({ imageList, onImageUpload }) => {
        return (
          <div className="align-self-end" style={{ height: "12rem", width: "12rem", ...style }}>
            <div className="d-flex justify-content-center h-100">
              {imageList[0] || placeHolderImg ? (
                <div className="position-relative">
                  {imageList[0] ? (
                    <img className="mh-100 mw-100" alt="img-upload" src={imageList[0]?.dataURL} />
                  ) : (
                    placeHolderImg
                  )}
                  <ActionButton
                    type="button"
                    disabled={disabled}
                    onClick={onImageUpload}
                    className={`${styles.uploadBtn} position-absolute top-50 start-50`}
                  >
                    <FormattedMessage id="CHANGE_LOGO" defaultMessage={"Change Logo"} />
                  </ActionButton>
                </div>
              ) : (
                <button
                  type="button"
                  style={{ backgroundColor: Color.LIGHT_LIGHT_GRAY, border: `1px solid #ced4da`, borderRadius:"6px"}}
                  className={`p-0 ${className} position-relative d-flex align-items-center w-100 justify-content-center`}
                  onClick={onImageUpload}
                  disabled={disabled}
                >
                  <div className="position-absolute w-75" style={{ fontWeight: "700" ,color: Color.CIPIA_BLUE}}>
                    <FormattedMessage id="ADD_COMPANY_IMAGE" defaultMessage="Click to Add Company Logo" />
                  </div>
                  <CompanyLogoPlaceholder width="140px" />
                </button>
              )}
            </div>
          </div>
        );
      }}
    </ReactImageUploading>
  );
}

export default ImageUpload;
