import CheckboxEmptyIcon from "../../assets/icons/CheckboxEmpty.svg";
import ChboxFullIcon from "../../assets/icons/CheckboxFull.svg";
import RadioButtonFullIcon from "../../assets/icons/RadioButtonFull.svg";
import RadioButtonIcon from "../../assets/icons/RadioButtonEmpty.svg";

const CheckBoxIcon = ({ variant, ...rest }: any) => {
  switch (variant) {
    case "all":
      return <img src={ChboxFullIcon} {...rest} alt="chf" />;
    case "none":
      return <img src={CheckboxEmptyIcon} {...rest} alt="chn" />;
    case "rb_sel":
      return <img src={RadioButtonFullIcon} {...rest} alt="rbf" />;
    case "rb_none":
      return <img src={RadioButtonIcon} {...rest} alt="rbe" />;

    default:
      return null;
  }
};

export default CheckBoxIcon;
