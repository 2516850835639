export const eventsSchema = [
  { label: "licenseplate", key: "vehicleId" },
  { label: "deviceID", key: "deviceID" },
  { label: "driverID", key: "driverID" },
  { label: "tripID", key: "tripId" },
  { label: "messageID", key: "messageID" },
  { label: "selfIP", key: "selfIP" },
  { label: "eventDateTime", key: "eventDateTime" },
  { label: "lastKnownLocationLong", key: "lastKnownLocationLong" },
  { label: "lastKnownLocationLat", key: "lastKnownLocationLat" },
  { label: "lastKnownStreetAddress", key: "lastKnownStreetAddress" },
  { label: "locationTime", key: "locationTime" },
  { label: "speed (km/h)", key: "speed" },
  { label: "headingAngle", key: "headingAngle" },
  { label: "locationQuality", key: "locationQuality" },
  { label: "extPower (mV)", key: "extPower" },
  { label: "internalBatteryPower (mV)", key: "internalBatteryPower" },
  { label: "iOArrayStatus (Volt) - IGN", key: "iOArrayStatus.IGN" },
  { label: "iOArrayStatus (Volt) - GPI", key: "iOArrayStatus.GPI" },
  { label: "iOArrayStatus (Volt) - GPO", key: "iOArrayStatus.GPO" },
  { label: "iOArrayStatus (Volt) - GPIO", key: "iOArrayStatus.GPIO" },
  { label: "eventType", key: "eventType" },
  { label: "eventAttr1", key: "eventAttr1" },
  { label: "cabinImageCount", key: "cabinImageCount" },
  { label: "cabinVideoCount", key: "cabinVideoCount" },
  { label: "roadVideoCount", key: "roadVideoCount" },
  { label: "roadImageCount", key: "roadImageCount" }
];