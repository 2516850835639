import { RouteConstants } from "../../constants";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import LoginPage from "../../pages/LoginPage";

export const authRoutes = [
  {
    path: RouteConstants.AUTH,
    element: <AuthLayout />,
    children: [
      {
        path: RouteConstants.LOGIN,
        element: <LoginPage />,
      },
    ],
  },
];
