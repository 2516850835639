import { QueryFunctionContext } from "@tanstack/react-query";
import { GetEventsResult, NullablePagingGetEventParams } from "../services/events.service";
import { eventsService } from "../services/events.service";

export function eventsQuery<TResult>(
  params: NullablePagingGetEventParams,
  selector: (queryResult: GetEventsResult) => TResult,
  enabled: boolean
) {
  
  return {
    queryKey: ["events", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["events", NullablePagingGetEventParams]>) =>
      await eventsService.getEvents(context.queryKey[1]),
    select: selector,
    keepPreviousData: true,
    enabled: enabled,
  };
}
