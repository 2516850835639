import moment from "moment-timezone";
import { Constants } from "../constants";
import { DateTimeFormat } from "../api/data-contracts";

export const ONE_MIN_IN_MS = 60000;

export const dateTimeFormats = [
  { time: "HH:mm", date: "DD MMM YYYY", timeDate: "HH:mm, DD MMM YYYY" },
  { time: "hh:mm A", date: "MMM DD, YYYY", timeDate: "hh:mm A, MMM DD, YYYY" },
  { time: "HH:mm", date: "YYYY-MM-DD", timeDate: "YYYY-MM-DD HH:mm" },
  { time: "HH:mm", date: "YYYY-MM-DD", timeDate: "HH:mm YYYY-MM-DD" },
  { time: "HH:mm", date: "DD-MMM-YYYY", timeDate: "DD-MMM-YYYY HH:mm" },
] as const;

const backDtFormatToFrontDtFormat = new Map<DateTimeFormat, (typeof dateTimeFormats)[number]>([
  [DateTimeFormat.TwentyFourHoursLongMonthDayYear, dateTimeFormats[0]],
  [DateTimeFormat.ShourtHoursLongMonthDayYear, dateTimeFormats[1]],
  [DateTimeFormat.YearMonthDayTwentyFourHours, dateTimeFormats[2]],
  [DateTimeFormat.TwentyFourHoursYearMonthDay, dateTimeFormats[3]],
  [DateTimeFormat.DayLongMonthYearTwentyFourHours, dateTimeFormats[4]],
]);

const frontDtFormatToBackDtFormat = new Map<(typeof dateTimeFormats)[number], DateTimeFormat>(
  Array.from(backDtFormatToFrontDtFormat.entries()).map(([k, v]) => [v, k])
);

export function getFrontDtFormat(
  backDtFormat: DateTimeFormat | undefined
): (typeof dateTimeFormats)[number] | undefined {
  return backDtFormat ? backDtFormatToFrontDtFormat.get(backDtFormat) : undefined;
}

export function getBackDtFormat(
  frontDtFormat: (typeof dateTimeFormats)[number] | undefined
): DateTimeFormat | undefined {
  return frontDtFormat ? frontDtFormatToBackDtFormat.get(frontDtFormat) : undefined;
}

export const dateUtils = {
  /* Adds or subtracts "dayDiff" days to the given date */
  addDays: (date: Date, dayDiff: number): Date => {
    date.setDate(date.getDate() + dayDiff);
    return date;
  },
  getFormattedLocalDate: (date: Date | string, format: string = Constants.DEFAULT_FRONT_DT_FORMAT.timeDate) => {
    return moment(date).tz(moment.tz.guess()).format(format);
  },
  getFormattedDate: (date: Date | string, format: string) => {
    return moment(date).format(format);
  },
};
