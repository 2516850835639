import { AxiosResponse } from "axios";
import { DriverManagement as DriverManagementApi } from "../api/DriverManagement";
import { FleetSenseDriver, ImageData } from "../api/data-contracts";
import { addAuthHandling } from "../util/api.util";

const driverManagementApi = new DriverManagementApi();
addAuthHandling(driverManagementApi);

export interface ITakeDriverFacePhoto {
  deviceId: string;
  driverId: string;
}

export interface IGiveDriverFacePhoto {
  deviceId: string;
  driverId: string;
  data: FleetSenseDriver;
}

export const driverManagementService = {
  takeDriverFacePhoto: async ({ deviceId, driverId }: ITakeDriverFacePhoto): Promise<AxiosResponse<ImageData, any>> => {
    const response = await driverManagementApi.takeDriverFacePhoto(deviceId, driverId);
    return response;
  },

  giveDriverFacePhoto: async ({ deviceId, driverId, data }: IGiveDriverFacePhoto): Promise<AxiosResponse> => {
    return driverManagementApi.giveDriverFacePhoto(deviceId, driverId, data);
  },
};
