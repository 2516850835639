import { FormattedMessage } from "react-intl";
import { ReactComponent as TrashBigIcon } from "../../../assets/icons/TrashBig.svg";

import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { ConfigTableEntry } from "../ConfigTable";
import useConfiguration from "../../../hooks/useConfiguration";

type DeleteModalProps = {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  configData: ConfigTableEntry[];
};

function DeleteConfigModal({ show, onClickNo, onClickYes, configData }: DeleteModalProps) {

  const { deleteConfigs } = useConfiguration();

  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={async () => {
        const configs = configData!.map((config) => {
          return { name: config.name!, version: config.version!, tspId: config.tspId! }
        });
        await deleteConfigs(configs);
        onClickYes();
      }}
      onHide={onClickNo}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <TrashBigIcon />
          <FormattedMessage id="Config.DELETE_QUESTION" defaultMessage="Are you sure you want to delete this config?" />
        </div>
      }
    />
  );
}

export default DeleteConfigModal;
