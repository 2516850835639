import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetConfigurationParams, ConfigSortFilterParamName } from "../../services/configuration.service";
import { PropsWithChildren } from "react";
import { Constants } from "../../constants";
import { CONFIGS_CONTEXT } from "./queryParamContextObjects";


export function ConfigQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetConfigurationParams = {
    filters: [],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return <QueryParamsContextProvider context={CONFIGS_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useConfigQueryParamsContext = () =>
  useQueryParamsContext<ConfigSortFilterParamName, ConfigSortFilterParamName>(CONFIGS_CONTEXT);
