import { PropsWithChildren } from "react";
import styles from "./VideoModal.module.scss";
import { Modal } from "react-bootstrap";

interface VideoModalProps {
  show: boolean;
  setVideoModalOpened: (show: boolean) => void;
  headerColor: string;
  headerStr: string;
}

function MediaModal({
  show,
  setVideoModalOpened,
  headerColor,
  headerStr,
  children,
}: PropsWithChildren<VideoModalProps>) {
  return (
    <Modal dialogClassName={styles["modal-dialog"]} show={show} onHide={() => setVideoModalOpened(false)} centered>
      <Modal.Header closeButton style={{ backgroundColor: headerColor }}>
        <strong>{headerStr}</strong>
      </Modal.Header>
      <Modal.Body style={{ padding: 0, border: "thin solid white" }}>{children}</Modal.Body>
    </Modal>
  );
}

export default MediaModal;
