import { FormattedMessage } from "react-intl";
import { Button } from "../../Button/Button";
import greenCirclePath from "../../../assets/green_circle.svg";
import styles from "./TripTabs.module.css";
import { Color } from "../../../constants";
import { Stack } from "react-bootstrap";

export type TripsTabOption = "current" | "vehicle" | "driver";

type TripsTabsProps = {
  active: TripsTabOption;
  onChange: (tab: TripsTabOption) => void;
  rightTab: TripsTabOption;
  disabled?: {
    current?: boolean;
    vehicle?: boolean;
    driver?: boolean;
  };
};

export default function TripTabs({ active, onChange, rightTab, disabled }: TripsTabsProps) {
  // const { current: disableCurrent, vehicle: disableVehicle, driver: disableDriver } = disabled ?? {};

  return (
    <div className="d-flex p-3 justify-content-center" style={{ borderBottom: `2px solid ${Color.GRAY_5}` }}>
      <Stack direction="horizontal" gap={0} className="w-75">
        <Button
          size="sm"
          className={`d-flex justify-content-center toggle-button w-50 p-1 ${styles["left-button"]} ${
            active === "current" ? "active" : ""
          }`}
          style={{ height: "2rem" }}
          onClick={() => onChange("current")}
        >
          {active === "current" && <img className="green-circle" src={greenCirclePath} alt="gs" />}
          <span style={{ marginLeft: "0.25rem" }}>
            <FormattedMessage id="CURRENT_TRIP" defaultMessage="Current trip" />
          </span>
        </Button>
        <Button
          size="sm"
          className={`d-flex justify-content-center toggle-button w-50 p-1 ${styles["right-button"]} ${
            active === rightTab ? "active" : ""
          }`}
          style={{ height: "2rem" }}
          onClick={() => onChange(rightTab)}
        >
          {active === rightTab && <img className="green-circle" src={greenCirclePath} alt="gs" />}
          <span style={{ marginLeft: "0.25rem" }}>
            {rightTab === "vehicle" ? (
              <FormattedMessage id="VEHICLE_MORE_TRIPS" defaultMessage="Vehicle more trips" />
            ) : (
              <FormattedMessage id="DRIVER_MORE_TRIPS" defaultMessage="Driver more trips" />
            )}
          </span>
        </Button>
      </Stack>
    </div>
  );
}
