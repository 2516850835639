import { QueryFunctionContext } from "@tanstack/react-query";
import { GetConfigResult, GetConfigurationParams, configurationService } from "../services/configuration.service";

export function configurationQuery<selectedT>(
  params: GetConfigurationParams,
  selector: (queryResult: GetConfigResult) => selectedT,
  enabled: boolean
) {
  return {
    queryKey: ["configuration", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["configuration", GetConfigurationParams]>) =>
      await configurationService.getConfiguration(context.queryKey[1]),
    select: selector,
    enabled: enabled,
  };
}
