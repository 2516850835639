import { Reports } from "../api/Reports";
import {  FleetSenseDeviceBilling, GetBillingReportHeader } from "../api/data-contracts";
import {
  FilterParam,
  NullablePagingQueryParams,
  QueryParams,
  ServiceGetResult,
} from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { dateUtils } from "../util/date.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

export const billingReportApi = new Reports();
addAuthHandling(billingReportApi);

export type BillingSortAndFilterParamNames =
  | "deviceId"
  | "fleetId"
  | "tspId"
  | "deviceStatus"
  | "assignFleetDate"
  | "lastEventDateTime"
  | "firstEventDate"
  | "tspAssigned"
  | "fleetAssigned"
  | "costs"
  | "eventsCount"
  | "from"
  | "to"




export type BillingSortParam = {
  param: BillingSortAndFilterParamNames;
  descending?: boolean;
};

export type BillingFilterParam = FilterParam<BillingSortAndFilterParamNames>;

export type GetBillingParams = QueryParams<BillingSortParam, BillingFilterParam>;

export type GetBillingResult = ServiceGetResult<FleetSenseDeviceBilling[]>  & GetBillingReportHeader;


export type NullablePagingGetBillingParams = NullablePagingQueryParams<BillingSortParam, BillingFilterParam> ;

export function extractFromAndTo(filters: FilterParam<BillingSortAndFilterParamNames>[])
{
  let from = filters?.find(x => x.paramName === "from" && x.operator === ">=")
  let to = filters?.find(x => x.paramName === "to" && x.operator === "<=")
  return { from: from?.values[0] ?? dateUtils.getFormattedDate( new Date(),"YYYY-MM-DD"), to: to?.values[0] }
}

export const billingService = {
  getBillingReport: async ({ paging, sorts, filters }: NullablePagingGetBillingParams): Promise<GetBillingResult> => {
    const {from, to} = extractFromAndTo(filters)
    const result = await billingReportApi.getBillingReport({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters.filter(x=> x.paramName !== "from" && x.paramName !== "to")) : undefined,
      page: paging?.pageIndex,
      pageSize: paging?.pageSize,
      from,
      to
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }

    return {
      data: result.data.data,
      pageCount,
      totalCount: header?.totalCount,
      currencyISO: header?.currencyISO,
      total: header?.total
    };
  },

}
