import { Row, TableInstance } from "react-table";
import { EventsTableEntry } from "./EventsTable";
import Media from "../Media/Media";
import { eventsService } from "../../services/events.service";
import { useMemo } from "react";
import { sortMedia } from "../FleetView/LastEvent/LastEventMedia";

interface ExpandedRowProps {
  row: Row<EventsTableEntry>;
  table: TableInstance<EventsTableEntry>;
}

const ExpandedRow = ({ row, table }: ExpandedRowProps) => {
  const sortedMediaArr = useMemo(() => [...row.original.mediaArr].sort(sortMedia), [row.original.mediaArr]);

  const deviceId = row.original.deviceId;
  const messageId = row.original.messageId;

  if (!(deviceId && messageId)) return null;

  return (
    <tr style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} className="selected-row-bg">
      <td colSpan={table.visibleColumns.length}>
        <div className="d-flex gap-4 ms-4 align-center">
          {sortedMediaArr.map((mediaDetails) => (
            <div
              style={{ height: "10rem" }}
              onClick={(e) => e.stopPropagation()}
              key={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`}
            >
              <Media
                urlMain={eventsService.getUrlForMedia({
                  messageId,
                  deviceId,
                  type: mediaDetails.type,
                  source: mediaDetails.source,
                  index: mediaDetails.index,
                })}
                width="fit-content"
                height="100%"
                eventType={row.original.type}
                subReason={row.original.eventAttr1}
                mediaDetails={mediaDetails}
                style={{ maxWidth: "18rem" }}
              />
            </div>
          ))}
        </div>
      </td>
    </tr>
  );
};

export default ExpandedRow;
