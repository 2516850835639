import { Reducer } from "react";
import { Fleet, Role, SP } from "../../api/data-contracts";
import { CheckType } from "../../components/CheckboxInput/CheckboxInput";
import { ActiveTsp, SelectedType, Tsp } from "./TspFleetContext";

export function isTsp(selected: SelectedType): selected is Tsp {
  return (selected as Tsp)?.children !== undefined;
}
export function isCipia(selected: SelectedType): selected is "CIPIA" {
  return selected === "CIPIA";
}
export function isFleet(selected: SelectedType): selected is Fleet {
  return (selected as Fleet).tspId !== undefined;
}

interface UpdateStateParams {
  tspIdToTsp: Map<number, Tsp>;
  fleetIdToFleet: Map<number, Fleet>;
  role: Role | undefined;
}

interface State {
  tspIdToTsp: Map<number, Tsp>;
  fleetIdToFleet: Map<number, Fleet>;
  activeTsp?: ActiveTsp;
  activeFleets: Fleet[];
  selected?: SelectedType;
}
type Action =
  | { type: "TOGGLE_CIPIA" }
  | { type: "TOGGLE_TSP"; payload: { tspId: number } }
  | {
      type: "TOGGLE_FLEET";
      payload: { fleetId: number; exclusiveCheckAndSelect?: boolean };
    }
  | { type: "UPDATE_STATE_ON_REFETCH"; payload: UpdateStateParams };

export const tspFleetContextReducer: Reducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case "TOGGLE_CIPIA": {
      return { ...state, selected: "CIPIA", activeFleets: [], activeTsp: undefined };
    }
    case "TOGGLE_TSP": {
      const tsp = state.tspIdToTsp.get(action.payload.tspId);
      if (!tsp) return state;
      return { ...state, activeTsp: { ...tsp, checkType: "FULL" }, activeFleets: tsp?.children, selected: tsp };
    }
    case "TOGGLE_FLEET": {
      const { fleetId, exclusiveCheckAndSelect } = action.payload;
      const fleet = state.fleetIdToFleet.get(fleetId);
      const tsp = state.tspIdToTsp.get(fleet?.tspId!);
      if (!tsp || !fleet) return state;

      let selected: SelectedType | undefined = state.selected;
      let activeFleets: Fleet[] = [];

      if (exclusiveCheckAndSelect) {
        selected = fleet;
        activeFleets = [fleet];
      } else {
        if (state.activeTsp?.id === tsp.id) {
          const isFleetActive = state.activeFleets.some((fleet) => fleet.id === fleetId);
          if (isFleetActive) {
            activeFleets = state.activeFleets.filter((fleet) => fleet.id !== fleetId);
          } else {
            activeFleets = [...state.activeFleets, fleet];
          }
        } else {
          activeFleets = [fleet];
          selected = fleet;
        }
      }

      return { ...state, activeFleets, selected, activeTsp: { ...tsp, checkType: getCheckType(tsp, activeFleets) } };
    }
    case "UPDATE_STATE_ON_REFETCH": {
      const { tspIdToTsp, fleetIdToFleet, role } = action.payload;

      if (!role) {
        return initializeState({ fleetIdToFleet: new Map(), role, tspIdToTsp: new Map() });
      }
      let tsp: Tsp | undefined = undefined;
      let activeFleets: Fleet[] = [];
      let selected: SelectedType | undefined = state.selected === "CIPIA" ? "CIPIA" : undefined;

      if (state.activeTsp) {
        tsp = tspIdToTsp.get(state.activeTsp.id!);
        for (const oldActiveFleet of state.activeFleets) {
          const newFleet = fleetIdToFleet.get(oldActiveFleet.id!);
          if (newFleet) activeFleets.push(newFleet);
        }
        if (state.selected && isFleet(state.selected)) {
          selected = fleetIdToFleet.get(state.selected.id!);
        }
        if (!selected) selected = tsp;
      }
      if (!selected) {
        if (role === Role.CipiaAdministrator) {
          selected = "CIPIA";
        } else if (role === Role.SpManager || role === Role.SpUser) {
          tsp = tspIdToTsp.size ? Array.from(tspIdToTsp.values())[0] : undefined;
          activeFleets = tsp?.children ?? [];
          selected = tsp;
        }
      }
      if (!selected && role === (Role.SpManager || role === Role.SpUser)) {
      }
      if (!selected && role === Role.CipiaAdministrator) selected = "CIPIA";

      return {
        ...state,
        tspIdToTsp,
        fleetIdToFleet,
        activeTsp: tsp ? { ...tsp, checkType: getCheckType(tsp, activeFleets) } : undefined,
        activeFleets: activeFleets,
        selected: selected,
      };
    }
  }
};

export function initializeState({ fleetIdToFleet, tspIdToTsp, role }: UpdateStateParams): State {
  if (role === Role.CipiaAdministrator) {
    return { tspIdToTsp, fleetIdToFleet, selected: "CIPIA", activeTsp: undefined, activeFleets: [] };
  }
  let firstTsp = undefined;
  if (tspIdToTsp.size) {
    firstTsp = Array.from(tspIdToTsp.values())[0];
  }
  let activeFleets = firstTsp?.children ?? [];

  const initialState: State = {
    tspIdToTsp,
    fleetIdToFleet,
    activeTsp: firstTsp ? { ...firstTsp, checkType: "FULL" } : undefined,
    activeFleets,
    selected: firstTsp,
  };

  return initialState;
}

export function getTspAndFleetMaps(tsps: SP[], fleets: Fleet[]): [Map<number, Tsp>, Map<number, Fleet>] {
  const tspIdToTspWithChildren = new Map<number, Tsp>();
  const fleetIdToFleet = new Map<number, Fleet>();

  for (const tsp of tsps) {
    const tspWithChildren = { ...tsp, children: [] };
    tspIdToTspWithChildren.set(tspWithChildren.id!, tspWithChildren);
  }

  for (const fleet of fleets) {
    fleetIdToFleet.set(fleet.id!, fleet);
    const tsp = tspIdToTspWithChildren.get(fleet.tspId!);
    tsp?.children.push(fleet);
  }

  return [tspIdToTspWithChildren, fleetIdToFleet];
}

export function getTspIds(tsps: SP[]): number[] {
  const ids = [];
  for (const tsp of tsps) {
    if (tsp.id !== undefined) {
      ids.push(tsp.id);
    }
  }
  return ids;
}

function getCheckType(tsp: Tsp, activeFleets: Fleet[]): CheckType {
  return activeFleets.length === tsp.children.length ? "FULL" : activeFleets.length > 0 ? "HALF" : "EMPTY";
}
