import { FormattedMessage } from "react-intl";
import { Row } from "react-table";
import { ReactComponent as MuteIcon } from "../../assets/icons/Mute.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";

import ActionButton from "../Buttons/ActionButton/ActionButton";
import { VehicleEntry } from "./VehiclesTable";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../api/data-contracts";

interface VehiclesControlProps {
  vehiclesCount?: number;
  selectedRows?: Row<VehicleEntry>[];
  onClickMute?: () => void;
  onClickEdit?: () => void;
  onClickAddDriver?: () => void;
}

const isMuteAllowd = (role?: Role) => {
  return false; 
  //temporary disabled because https://tandemg.atlassian.net/browse/FSCLOUD-537
  //if (role === Role.SpManager || role === Role.SpUser || role === Role.CipiaAdministrator) {
  //  return false;
  //}
  //return true
}

function VehiclesControl({ vehiclesCount, selectedRows, onClickMute,onClickEdit, onClickAddDriver }: VehiclesControlProps) {
  const { loggedUser } = useAuthContext();

  return (
    <div className="d-flex align-items-center gap-5 py-3">
      <div className="header-font">
        <FormattedMessage id="VEHICLES" defaultMessage="Vehicles" />
        {` (${vehiclesCount || 0})`}
      </div>

      <div className="d-flex align-items-center gap-3">
        <>
        {
            <ActionButton
              icon={<EditIcon fill="black"/>}
              onClick={onClickEdit}
              disabled={!selectedRows || selectedRows.length === 0 || selectedRows?.length > 1}
            >
              <FormattedMessage id="VEHICLE_CARD" defaultMessage="Vehicle card" />
            </ActionButton>
          }
          {
            isMuteAllowd(loggedUser?.role) && <ActionButton
              icon={<MuteIcon />}
              onClick={onClickMute}
              disabled={selectedRows?.filter((row) => row.original.vehicleId).length === 0}
            >
              <FormattedMessage id="MUTE_DEVICE" defaultMessage="Mute Device" />
            </ActionButton>
          }
        </>

      </div>
    </div>
  );
}

export default VehiclesControl;
