import dayjs from "dayjs";
import { Ref, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useFilter from "../../hooks/useFilter";
import { dateUtils } from "../../util/date.util";
import SingleSelectInput from "../form/SingleSelectInput/SingleSelectInput";
import DateTimeRangeFilter from "./DateTimeRangeFilter";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";

/* 
This component renders a select with 4 filter options: today, last week, last month and custom
If custom is chosen, DateTimeRangeFilter is rendered, and filters are changed accordingly 
*/


const selectOptions = {
  TODAY: { label: <FormattedMessage id="TODAY" defaultMessage="Today" />, value: () => getIsoDateOnly( new Date()) },
  LAST_WEEK: { label: <FormattedMessage id="LAST_WEEK" defaultMessage="Last Week" />, value: () => getIsoDateOnly(dateUtils.addDays(new Date(), -7)) },
  LAST_MONTH: { label: <FormattedMessage id="LAST_MONTH" defaultMessage="Last Month" />, value: () => getIsoDateOnly(dateUtils.addDays(new Date(), -30)) },
  CUSTOM: { label: <FormattedMessage id="CUSTOM" defaultMessage="Custom" />, value: undefined },
} as const;
type DtOption = (typeof selectOptions)[keyof typeof selectOptions];

const options = Array.from(Object.values(selectOptions));

type DateTimeSelectFilterProps<paramName extends string> = {
  context : React.Context<IQueryParamsContext<any, any>>,
  paramName: paramName;
  width?: string;
  containerRef?: Ref<HTMLDivElement>;
  includeInUrl?: boolean;
  urlFromParamName?: string;
  urlToParamName?: string;
};

function DateTimeSelectFilter<ParamName extends string>({
  context,
  paramName,
  width,
  containerRef,
  includeInUrl,
  urlFromParamName,
  urlToParamName,
}: DateTimeSelectFilterProps<ParamName>) {
  const onForcedClear = useCallback(() => {
    setSelectedOption(selectOptions.CUSTOM);
  }, []);

  const { upsertOrRemoveFilter: upsertOrRemoveFromFilter, contextValues: valuesFrom } = useFilter<ParamName>(
    context,
    paramName,
    ">=",
    onForcedClear,
    includeInUrl,
    urlFromParamName
  );

  const { upsertOrRemoveFilter: upsertOrRemoveToFilter, contextValues: valuesTo } = useFilter<ParamName>(
    context,
    paramName,
    "<=",
    onForcedClear,
    includeInUrl,
    urlToParamName
  );

  const [selectedOption, setSelectedOption] = useState<DtOption>(
    valuesFrom[0] ? getOptionByDate(valuesFrom[0], valuesTo[0]) : selectOptions.TODAY
  );

  const { formatMessage } = useIntl();

  const optionChangedHandler = useCallback(
    (option: DtOption) => {
      setSelectedOption(option);

      if (option !== selectOptions.CUSTOM) {
        const currentFrom = valuesFrom[0];
        const newFrom = option?.value?.();

        currentFrom !== newFrom && upsertOrRemoveFromFilter(newFrom);

        upsertOrRemoveToFilter(undefined);
        return;
      }

      // If we got here, Custom range selected
      upsertOrRemoveFromFilter(undefined);
      upsertOrRemoveToFilter(undefined);
    },
    [upsertOrRemoveFromFilter, upsertOrRemoveToFilter, valuesFrom]
  );

  const showCustomRange = selectedOption === selectOptions.CUSTOM;

  return (
    <div ref={containerRef} className={`d-flex align-items-center gap-2`}>
      <SingleSelectInput
        isSearchable={false}
        inputLabel={formatMessage({
          id: "TIME_RANGE",
          defaultMessage: "Time range",
        })}
        style={{ minWidth: "7rem", flexShrink: 9999 }}
        controlWidth={showCustomRange ? undefined : width}
        value={selectedOption}
        options={options}
        onChange={(option) => optionChangedHandler(option!)}
      />

      {showCustomRange && (
        <DateTimeRangeFilter
          fromLabel={<FormattedMessage id="Time.FROM" defaultMessage="From" />}
          toLabel={<FormattedMessage id="Time.TO" defaultMessage="To" />}
          fromDate={valuesFrom[0] ? dayjs(valuesFrom[0]) : null}
          toDate={valuesTo[0] ? dayjs(valuesTo[0]) : null}
          onChangeFrom={(dt) => {
            // setCustomFromDate(dt);
            upsertOrRemoveFromFilter(dt ? [dt.toISOString()] : undefined);
          }}
          onChangeTo={(dt) => {
            // setCustomToDate(dt);
            upsertOrRemoveToFilter(dt ? [dt.toISOString()] : undefined);
          }}
        />
      )}
    </div>
  );
}

export default DateTimeSelectFilter;

function isDateSame(dateStr1: string, dateStr2: string) {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  return (
    date1.getDate() === date2.getDate() &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
}

let getOptionByDate = (valueFrom?: any, valueTo?: any) => {
  let selectedOption: DtOption | undefined = undefined;
  if (valueFrom && !valueTo) {
    if (isDateSame(valueFrom, selectOptions.TODAY.value())) {
      selectedOption = selectOptions.TODAY;
    } else if (isDateSame(valueFrom, selectOptions.LAST_WEEK.value())) {
      selectedOption = selectOptions.LAST_WEEK;
    } else if (isDateSame(valueFrom, selectOptions.LAST_MONTH.value())) {
      selectedOption = selectOptions.LAST_MONTH;
    }
  }
  if (!selectedOption) selectedOption = selectOptions.CUSTOM;
  return selectedOption;
}

let getIsoDateOnly = (date: Date) => {

 return dateUtils.getFormattedDate(date,"YYYY-MM-DD")
}
