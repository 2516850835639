import ReactPlayer from "react-player";
import { MediaType } from "../../api/data-contracts";
import { CSSProperties, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as OpenIcon } from "../../assets/icons/Open.svg";
import DualVideoPlayer from "./DualVideoPlayer";
import { MediaDetails } from "../EventsTable/EventsTable";
import { getEventColorBySeverity, getEventMetadata } from "../../util/events.util";
import { Color } from "../../constants";
import MediaModal from "./MediaModal";

interface MediaProps {
  urlMain: string;
  urlSecondary?: string;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  eventType?: string;
  subReason?: string;
  mediaDetails: MediaDetails;
  style?: CSSProperties;
}

const Media = ({ urlMain, urlSecondary, width, height, eventType, mediaDetails, style, subReason }: MediaProps) => {
  const [isError, setIsError] = useState(false);
  const [videoModalOpened, setVideoModalOpened] = useState(false);

  useEffect(() => {
    return () => setIsError(false);
  }, [urlMain, urlSecondary]);

  function onError() {
    setIsError(true);
  }

  if (isError) {
    return (
      <div
        className="d-flex align-items-center justify-content-center px-4"
        style={{ backgroundColor: Color.LIGHT_GRAY_6, height, width, ...style }}
      >
        <FormattedMessage id="MEDIA_IS_UNAVAILABLE" defaultMessage="Media is Unavailable" />
      </div>
    );
  }

  const eventMetadata = getEventMetadata(eventType);
  const eventColor = getEventColorBySeverity(eventMetadata?.severity);

  return mediaDetails.type === MediaType.Image ? (
    <>
      {
        <span style={{ position: "relative", ...style }}>
          <img
            style={style}
            width="100%"
            height={height}
            src={urlMain}
            alt=""
            onError={onError}
            onClick={() => setVideoModalOpened(true)}
          />
          <MediaModal
            headerStr={`${eventMetadata?.displayName} ${subReason ? "- " + subReason : ""}`}
            headerColor={eventColor}
            show={videoModalOpened}
            setVideoModalOpened={setVideoModalOpened}
          >
            <img width="100%" height="fit-content" src={urlMain} alt="" onError={onError} />
          </MediaModal>
        </span>
      }
    </>
  ) : (
    <>
      {!urlSecondary ? (
        <span style={{ position: "relative", ...style }}>
          <ReactPlayer
            width={width}
            height={height}
            controls
            url={urlMain}
            onError={onError}
            style={{ display: "flex" }}
          />
          <OpenIcon
            fill={"white"}
            style={{ position: "absolute", bottom: "35px", right: "33px" }}
            onClick={() => setVideoModalOpened(true)}
          />
        </span>
      ) : (
        <DualVideoPlayer
          mainVideoUrl={urlMain}
          secondaryVideoUrl={urlSecondary}
          videoModalOpened={false}
          setVideoModalOpened={setVideoModalOpened}
          onError={onError}
          style={style}
          secondaryVideoBorderColor={Color.CIPIA_BLUE_2}
        />
      )}
      <MediaModal
        headerStr={`${eventMetadata?.displayName} ${subReason ? "- " + subReason : ""}`}
        headerColor={eventColor}
        show={videoModalOpened}
        setVideoModalOpened={setVideoModalOpened}
      >
        {!urlSecondary ? (
          <div>
            <ReactPlayer width="100%" height="fit-content" controls url={urlMain} onError={onError} />
          </div>
        ) : (
          <DualVideoPlayer
            mainVideoUrl={urlMain}
            secondaryVideoUrl={urlSecondary}
            videoModalOpened={true}
            setVideoModalOpened={setVideoModalOpened}
            onError={onError}
            secondaryVideoBorderColor={eventColor}
          />
        )}
      </MediaModal>
    </>
  );
};

export default Media;
