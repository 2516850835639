import styles from "./RefreshButton.module.scss";

interface RefreshButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

function RefreshButton({ disabled, onClick }: RefreshButtonProps) {
  return (
    <svg
      onClick={onClick}
      className={`${styles.svg} ${disabled ? styles["disabled"] : ""}`}
      width="24"
      height="24"
      viewBox="-3.5 -3.5 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.05538 6.78947H4.32045C4.46474 6.78947 4.60312 6.73215 4.70514 6.63013C4.80717 6.5281 4.86448 6.38973 4.86448 6.24544C4.86448 6.10115 4.80717 5.96278 4.70514 5.86075C4.60312 5.75873 4.46474 5.70141 4.32045 5.70141H2.48124C3.16106 4.49675 4.20591 3.53911 5.46511 2.96659C6.72432 2.39407 8.13281 2.23625 9.4875 2.51589C10.8422 2.79553 12.0731 3.49818 13.0026 4.52251C13.9322 5.54685 14.5125 6.83994 14.6597 8.21533C14.6778 8.34717 14.7427 8.46811 14.8426 8.55607C14.9425 8.64402 15.0706 8.69315 15.2037 8.69447C15.2811 8.69503 15.3576 8.67911 15.4283 8.64775C15.499 8.61639 15.5622 8.57032 15.6137 8.51262C15.6652 8.45491 15.7037 8.38689 15.7269 8.31309C15.75 8.2393 15.7571 8.16142 15.7478 8.08465C15.5789 6.4894 14.9108 4.98817 13.8386 3.79498C12.7663 2.6018 11.3448 1.77757 9.77659 1.43981C8.20839 1.10205 6.57359 1.268 5.10525 1.914C3.63691 2.55999 2.40999 3.65307 1.59941 5.03737V2.98037C1.59941 2.83608 1.5421 2.69771 1.44007 2.59568C1.33804 2.49366 1.19967 2.43634 1.05538 2.43634C0.911097 2.43634 0.772721 2.49366 0.670695 2.59568C0.56867 2.69771 0.511353 2.83608 0.511353 2.98037V6.24544C0.511353 6.38973 0.56867 6.5281 0.670695 6.63013C0.772721 6.73215 0.911097 6.78947 1.05538 6.78947Z"
        fill="current"
      />
      <path
        d="M15.1993 11.1399H11.9378C11.7935 11.1399 11.6551 11.1973 11.5531 11.2993C11.4511 11.4013 11.3937 11.5397 11.3937 11.684C11.3937 11.8282 11.4511 11.9666 11.5531 12.0687C11.6551 12.1707 11.7935 12.228 11.9378 12.228H13.7663C13.0956 13.3825 12.0871 14.3037 10.8767 14.8673C9.66627 15.4309 8.31215 15.6099 6.99687 15.3801C5.68158 15.1503 4.46837 14.5228 3.52076 13.5822C2.57314 12.6415 1.93669 11.433 1.6972 10.1194C1.68548 10.0488 1.65968 9.98134 1.62133 9.92093C1.58298 9.86053 1.53288 9.80845 1.47399 9.76781C1.4151 9.72717 1.34863 9.69879 1.27855 9.68437C1.20847 9.66994 1.1362 9.66977 1.06605 9.68385C0.92482 9.71022 0.799754 9.79139 0.718148 9.90963C0.636543 10.0279 0.605024 10.1736 0.63047 10.315C0.9087 11.8385 1.64471 13.241 2.74037 14.3356C3.83604 15.4301 5.23931 16.1647 6.7631 16.4413C8.2869 16.718 9.85883 16.5236 11.2693 15.8841C12.6798 15.2445 13.8619 14.1903 14.6579 12.8618V14.9508C14.6579 15.0951 14.7152 15.2335 14.8173 15.3355C14.9193 15.4375 15.0577 15.4948 15.2019 15.4948C15.3462 15.4948 15.4846 15.4375 15.5866 15.3355C15.6887 15.2335 15.746 15.0951 15.746 14.9508V11.6866C15.7463 11.6147 15.7324 11.5435 15.7051 11.477C15.6777 11.4105 15.6375 11.3501 15.5866 11.2993C15.5358 11.2484 15.4754 11.2082 15.4089 11.1808C15.3424 11.1535 15.2712 11.1396 15.1993 11.1399Z"
        fill="current"
      />
    </svg>
  );
}

export default RefreshButton;
