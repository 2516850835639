import { SortParam } from "../models/requests/shared.request";

/* 
Receives SortParam[],
Returns a sorting string as required by sieve on the backend 
*/
export function sortParamsToString(params: SortParam[]) {
  let result: string = "";

  for (const sortParam of params) {
    if (sortParam.descending) {
      result += "-";
    }
    result += sortParam.param + ",";
  }

  if (result.length) {
    result = result.slice(0, -1);
  }

  return result;
}

export type SortStatus = "INACTIVE" | "ASCENDING" | "DESCENDING";
