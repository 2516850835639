import ReactSelect, { BaseOption, ReactSelectRef } from "../../ReactSelect/ReactSelect";
import { Props } from "react-select";
import { Color, InputUi } from "../../../constants";
import Arrow, { Direction } from "../../../icons/Arrow";
import { CSSProperties } from "react";

export type SingleSelectInputProps<Option extends BaseOption> = {
  inputLabel?: string;
  controlWidth?: string;
  className?: string;
  isInvalid?: boolean;
  style?: CSSProperties;
} & Omit<Props<Option, false>, "components" | "isMulti" | "styles" | "className"> & {
    selectRef?: ReactSelectRef<Option, false>;
  };

const SingleSelectInput = <Option extends BaseOption>({
  inputLabel,
  controlWidth,
  className,
  isInvalid,
  selectRef,
  style,
  ...props
}: SingleSelectInputProps<Option>) => {
  return (
    <div className={`input-with-label-container ${className}`} style={{ ...style, width: controlWidth }}>
      {inputLabel && <div className="input-label">{inputLabel}</div>}
      <ReactSelect
        selectRef={selectRef}
        {...props}
        components={{
          IndicatorSeparator: undefined,
          DropdownIndicator: (state) => (
            <div className="px-2">
              <Arrow color={state.isFocused ? Color.CIPIA_BLUE : Color.BLUE_GRAY_2} direction={Direction.Down} />
            </div>
          ),
        }}
        classNames={{
          control: (state) => `${isInvalid ? "app-form-input invalid" : ""}`,
        }}
        styles={{
          menu: (base) => ({ ...base, zIndex: 2, marginTop: "0.1rem" }),
          container: (base) => ({ ...base }),
          control: (base) => ({
            ...base,
            minHeight: InputUi.HEIGHT,
            width: controlWidth,
            ":hover": { backgroundColor: "#EFF6FF", borderColor: "#3587EB" },
          }),
          valueContainer: (base) => ({
            ...base,
            paddingTop: "0",
            paddingBottom: "0",
          }),
          input: (base) => ({
            ...base,
            margin: "0.0625rem",
          }),
        }}
        isMulti={false}
      />
    </div>
  );
};

export default SingleSelectInput;
