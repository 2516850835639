import { useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useOnClickOutside } from "usehooks-ts";
import styles from "./SideBar.module.scss";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../api/data-contracts";

export type RightClickMenuParamsType = {
  show: boolean;
  isTsp?: boolean;
  location?: { x: number; y: number };
};
type RightClickMenuProps = RightClickMenuParamsType & {
  onClickEdit: () => void;
  onClickDelete: () => void;
  setRightClickMenuShow: (action: RightClickMenuParamsType) => void;
};

const RightClickMenu = ({
  show,
  isTsp,
  location,
  onClickEdit,
  onClickDelete,
  setRightClickMenuShow,
}: RightClickMenuProps) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const contextMenuWrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(
    contextMenuWrapperRef,
    (e) => !contextMenuRef.current?.contains(e.target as Node) && setRightClickMenuShow({ show: false })
  );
  const userRole = useAuthContext().loggedUser?.role;

  if (!show) return null;
  if (isTsp) {
    if (userRole !== Role.CipiaAdministrator) return null;
  } else {
    if (!(userRole === Role.CipiaAdministrator || userRole === Role.SpManager || userRole === Role.SpUser)) return null;
  }

  return (
    <div
      ref={contextMenuWrapperRef}
      onClick={() => setRightClickMenuShow({ show: false })}
      className={styles["right-click-menu"]}
      style={{
        position: "fixed",
        top: location?.y || 0,
        left: location?.x || 0,
      }}
    >
      <div ref={contextMenuRef}>
        <Dropdown.Item as="button" className="p-1" onClick={onClickEdit}>
          {isTsp ? (
            <FormattedMessage id="EDIT_TSP" defaultMessage="Edit TSP" />
          ) : (
            <FormattedMessage id="EDIT_FLEET" defaultMessage="Edit Fleet" />
          )}
        </Dropdown.Item>
        <Dropdown.Item as="button" className="p-1" onClick={onClickDelete}>
          {isTsp ? (
            <FormattedMessage id="DELETE_TSP" defaultMessage="Delete TSP" />
          ) : (
            <FormattedMessage id="DELETE_FLEET" defaultMessage="Delete Fleet" />
          )}
        </Dropdown.Item>
      </div>
    </div>
  );
};

export default RightClickMenu;
