import { Color } from "../../../constants";
import styles from "./CircleButton.module.scss";
import DoubleArrow from "../../../icons/DoubleArrow";

interface CircleButtonProps {
  opened: boolean;
  onClick: () => void;
  top: string;
}

const CircleButton = ({ opened, onClick, top }: CircleButtonProps) => {
  return (
    <span style={{ visibility: "visible", position: "absolute", top: top, right: -27, zIndex: 10 }}>
      <button onClick={onClick} className={styles.button}>
        <div className={styles["circle-button"]}>
          <DoubleArrow direction={opened ? "LEFT" : "RIGHT"} color={Color.CIPIA_BLUE} />
        </div>
      </button>
    </span>
  );
};

export default CircleButton;
